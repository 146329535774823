import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {OfferAccessRequestCreateConfiguration} from "@core/shared/models/offer/offer-access-request/offer-access-request-create";
import {Offer} from "@core/shared/models/offer";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {Society} from "@core/shared/models/society";
import {DEFAULT_MARKUP} from "@core/shared/models/data";
import {MIN_MARKUP} from "@core/shared/models/data";
import {REGEX_PRICE} from "@core/shared/models/regex";
import {parseMarkup} from "@core/shared/utils/markup";
import {Observable} from "rxjs";

@Component({
    selector: 'app-core-offer-access-request-create',
    templateUrl: './offer-access-request-create.component.html',
    styleUrls: ['./offer-access-request-create.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferAccessRequestCreateComponent implements OnInit {

    @Input() configuration: OfferAccessRequestCreateConfiguration;

    @Input() createItemSourceCallback: (data: object) => Observable<OfferCatalog>;

    @Input() offer: Offer;
    @Input() society: Society;

    @Output() create: EventEmitter<{item: OfferCatalog}> = new EventEmitter<{item: OfferCatalog}>();

    public minMarkupPercent: number = MIN_MARKUP;
    public defaultMarkupPercent: number = DEFAULT_MARKUP;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _offerCatalogService: OfferCatalogService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {
        this._initForm();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            offer: [this.offer.id, [Validators.required]],
            markup : [this.society.enableMarkup ? this.parsedMarkup(this.society.markup) : this.defaultMarkupPercent, [
                Validators.required,
                Validators.pattern(REGEX_PRICE),
                Validators.min(MIN_MARKUP)]
            ]
        });

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign(this.form.value, {
                markup: this.form.get('markup').value / 100,
                offer: {
                    id: this.form.get('offer').value
                }
            });

            this.createItemSourceCallback(data).subscribe((item: OfferCatalog): void => {

                this._loadSelfOfferCatalogs();

                this.create.emit({item : item});
            });
        };
    }

    private _loadSelfOfferCatalogs(): void {

        this._offerCatalogService.getItemsAPI().subscribe((items: OfferCatalog[]): void => {

            this._offerCatalogService.selfItems.next(items);
        });
    }

    public parsedMarkup(markup: number): string {

        return parseMarkup(markup * 100);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
