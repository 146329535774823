import {Injectable} from '@angular/core';
import {Offer} from "@core/shared/models/offer";
import {OfferCardCustomAction} from "@core/shared/models/offer/offer-card";

@Injectable()
export class OfferCardService {

    private _isOfferHighlightedCallback: (offer: Offer) => boolean = () => { return true };

    public customActions: OfferCardCustomAction[] = [];

    public goToOfferCallback: (offer: Offer) => void = () => {};

    public resetCustomActions(): void {

        this.customActions = [];
    }

    public isOfferHighlighted(offer: Offer): boolean {

        return this._isOfferHighlightedCallback(offer);
    }

    set isOfferHighlightedCallback(value: (offer: Offer) => boolean) {

        this._isOfferHighlightedCallback = value;
    }
}
