import {Component, Input, OnInit} from '@angular/core';
import {OfferSearchService} from "@core/shared/services/offer/offer-search.service";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {User} from "@core/shared/models/user";
import {Society} from "@core/shared/models/society";
import {ModeType} from "@core/shared/models/offer/offer-list";
import {TranslationService} from "@core/shared/services/translation.service";
import {Offer} from "@core/shared/models/offer";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {map} from "rxjs/operators";
import {OfferSearchSessionFilter} from "@core/shared/models/offer/offer-search";
import {OfferCardService} from "@core/shared/services/offer/offer-card.service";

@Component({
    selector: 'app-core-offer-permanent-option-offer-search',
    templateUrl: './offer-permanent-option-offer-search.component.html',
    styleUrls: ['./offer-permanent-option-offer-search.component.scss'],
    providers: [
        OfferSearchService,
        OfferCardService
    ]
})
export class OfferPermanentOptionOfferSearchComponent implements OnInit {

    @Input() user: User;

    @Input() society: Society;

    @Input() form: UntypedFormGroup;

    @Input() controlName: string;

    @Input() mode: ModeType;

    @Input() defaultSelection: (Offer|OfferCatalog)[] = [];

    constructor(
        private _offerCatalogService: OfferCatalogService,
        public translationService: TranslationService,
        public offerSearchService: OfferSearchService,
    ) {}

    ngOnInit(): void {

        this._clearSession();

        this._configureSearchService();

        this._initOfferSelection();

        this._hydrateSelection();
    }

    private _clearSession(): void {

        if(!this.offerSearchService.hasSessionFilters(this.mode)){

            return;
        }

        const filters = this.offerSearchService.getSessionFilters(this.mode);

        const permanentOptionFilterIndex = filters.findIndex((sessionFilter: OfferSearchSessionFilter): boolean => {

            return sessionFilter.key === 'permanentOption';
        });

        if(permanentOptionFilterIndex < 0){

            return;
        }

        filters[permanentOptionFilterIndex].value = null;

        this.offerSearchService.setSessionFilters(this.mode, filters);
    }

    private _configureSearchService(): void {

        this.offerSearchService.selectOfferAllowed = true;
    }

    private _initOfferSelection(): void {

        this.offerSearchService.offerSelected$.subscribe((selectedOffer: Offer): void => {

            // Mes offres

            if(this.isMode('offer-permanent-option-personnal-offers')){

                const value: { id : number}[] = [...this.control.value];

                value.push({ id: selectedOffer.id });

                this.control.patchValue([...new Map(value.map((item: { id: number }) => [item.id, item])).values()]);
            }

            // Offres de mon catalogue

            if(this.isMode('offer-permanent-option-catalog')){

                const params: string[] = [
                    `offer.id[eq]=${selectedOffer.id}`
                ];

                this._offerCatalogService.getItemsAPI(params)
                    .pipe(
                        map((items: OfferCatalog[]): OfferCatalog => { return items.find((): boolean => true); })
                    )
                    .subscribe((offerCatalog: OfferCatalog): void => {

                        const value: { id : number}[] = [...this.control.value];

                        value.push({ id: offerCatalog.id });

                        this.control.patchValue([...new Map(value.map((item: { id: number }) => [item.id, item])).values()]);
                    })
                ;
            }
        });

        this.offerSearchService.offerUnselected$.subscribe((unselectedOffer: Offer): void => {

            // Mes offres

            if(this.isMode('offer-permanent-option-personnal-offers')){

                const value: { id : number}[] = [...this.control.value];

                const index: number = value.findIndex((item: { id : number}): boolean => {

                    return unselectedOffer.id === item.id;
                });

                value.splice(index, 1);

                this.control.patchValue([...new Map(value.map((item: { id: number }) => [item.id, item])).values()]);
            }

            // Offres de mon catalogue

            if(this.isMode('offer-permanent-option-catalog')){

                const params: string[] = [
                    `offer.id[eq]=${unselectedOffer.id}`
                ];

                this._offerCatalogService.getItemsAPI(params)
                    .pipe(
                        map((items: OfferCatalog[]): OfferCatalog => { return items.find((): boolean => true); })
                    )
                    .subscribe((offerCatalog: OfferCatalog): void => {

                        const value: { id : number}[] = [...this.control.value];

                        const index: number = value.findIndex((item: { id : number}): boolean => {

                            return offerCatalog.id === item.id;
                        });

                        value.splice(index, 1);

                        this.control.patchValue([...new Map(value.map((item: { id: number }) => [item.id, item])).values()]);
                    })
                ;
            }
        });
    }

    private _hydrateSelection(): void {

        this.control.patchValue(this.defaultSelection.map((item: Offer|OfferCatalog): { id: number } => {

            return {
                id: item.id
            };
        }));

        // Mes offres

        if(this.isMode('offer-permanent-option-personnal-offers')){

            this.offerSearchService.selectedOffers.next(this.defaultSelection as Offer[]);
        }

        // Offres de mon catalogue

        if(this.isMode('offer-permanent-option-catalog')){

            this.offerSearchService.selectedOffers.next((this.defaultSelection as OfferCatalog[]).map((item: OfferCatalog): Offer => {

                return item.offer;
            }));
        }
    }

    public resetOfferSelection(): void {

        this.offerSearchService.selectedOffers.next([]);

        this.control.patchValue([]);

        this.offerSearchService.updateFilter$.next({ key: 'selectedOffers', value: [] });
    }

    public isMode(value: ModeType): boolean {

        return this.mode === value;
    }

    get control(): UntypedFormControl {

        return this.form.get(this.controlName) as UntypedFormControl;
    }
}
