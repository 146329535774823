import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {User} from "@core/shared/models/user";
import {Society} from "@core/shared/models/society";
import {ActivatedRoute, Router} from "@angular/router";
import {Channel, ChannelIntegrationMode, ChannelMode} from "@core/shared/models/channel";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {BehaviorSubject, Observable, of} from "rxjs";
import {Currency} from "@core/shared/models/currency";
import {AbstractControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {CurrencyService} from "@core/shared/services/currency.service";
import {ChannelService} from "@core/shared/services/channel.service";
import {FormService} from "@core/shared/services/form.service";
import {OfferSearchService} from "@core/shared/services/offer/offer-search.service";
import {Offer} from "@core/shared/models/offer";
import {ChannelTranslation} from "@core/shared/models/channel-translation";
import {Role} from "@core/shared/models/role";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {Access, AccessType} from "@core/shared/models/access";
import {FieldCollection} from "@lib/form/field";
import {environment} from "../../../../../environments/environment";
import {MatLegacyTab as MatTab, MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {ClipboardService, IClipboardResponse} from "ngx-clipboard";
import {TranslationService} from "@core/shared/services/translation.service";
import {map, tap} from "rxjs/operators";
import {ChannelFont} from "@core/shared/models/channel/channel-font";
import {CHANNEL_FONTS} from "@core/shared/data/channel/channel-font";
import {SelectSearchConfig} from "@lib/form/fields/select-search/select-search.component";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {SocietyService} from "@core/shared/services/society.service";
import {SocietyWebsite} from "@core/shared/models/society/society-website";
import {WebsiteService} from "@core/shared/services/website.service";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import {ApiService} from "@core/shared/services/api.service";
import {REGEX_WEBSITE} from "@core/shared/models/regex";
import {
    CHANNEL_SORT_OPERATORS,
    CHANNEL_SORT_ORDER_BY,
    ChannelSortOperator,
    ChannelSortOrderBy
} from "@core/shared/models/channel-sort";
import {SocietyIp} from "@core/shared/models/society/society-ip";
import {SocietyIpService} from "@core/shared/services/society/society-ip.service";
import {FileConfig} from '@lib/form/fields/file/file.component';
import {OfferCardService} from "@core/shared/services/offer/offer-card.service";

@Component({
    selector: 'app-core-page-channel-update',
    templateUrl: './page-channel-update.component.html',
    styleUrls: ['./page-channel-update.component.scss'],
    providers: [
        FormService,
        OfferSearchService,
        OfferCardService
    ]
})
export class PageChannelUpdateComponent implements OnInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild('offerCardPreviewTab', {static: false}) offerCardPreviewTabRef: MatTab;

    @ViewChild('offerViewPreviewTab', {static: false}) offerViewPreviewTabRef: MatTab;

    @ViewChild('showcaseReference', { static: false }) showcaseReference: ElementRef<HTMLDivElement>;

    private _societiesSubscription: Observable<Society[]>;

    public user: User;

    public society: Society;

    public channel: Channel;

    public translationBuilder: TranslationBuilder;

    public locales$: Observable<LocaleItem[]>;

    public sortsOrderBy$: Observable<ChannelSortOrderBy[]>;

    public sortsOperators$: Observable<ChannelSortOperator[]>;

    public currencies$: Observable<Currency[]>;

    public societyWebsites$: Observable<SocietyWebsite[]>;

    public societies: Society[] = [];

    public fieldCollection = new FieldCollection();

    public fonts: ChannelFont[] = CHANNEL_FONTS;

    public societyIps: SocietyIp[] = [];

    public selfOfferCatalogs$: BehaviorSubject<OfferCatalog[]> = new BehaviorSubject<OfferCatalog[]>([]);

    public saveAndContinueConfiguration: { active: boolean, fragment: ('JSIntegration'|'APIIntegration') } = {
        active: false,
        fragment: null
    };

    public colorTitle: string;

    public colorSubTitle: string;

    public colorBackgroundButton: string;

    public colorTextButton: string;

    public colorHighlighting: string;

    public colorDescription: string;

    public faviconConfig: FileConfig;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _clipboardService: ClipboardService,
        private _currencyService: CurrencyService,
        private _channelService: ChannelService,
        private _offerCatalogService: OfferCatalogService,
        private _apiService: ApiService,
        private _societyService: SocietyService,
        private _websiteService: WebsiteService,
        private _societyIpService: SocietyIpService,
        public formService: FormService,
        public offerSearchService: OfferSearchService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: {channel: Channel, user: User, society?: Society}): void => {

            this.user = data.user;

            this.channel = data.channel;

            this._initForm();

            this._initSocietiesSubscription();

            this._societiesSubscription.subscribe((societies: Society[]): void => {

                this.societies = societies;

                this.society = this.societies.find((item: Society): boolean => {

                    return item.id === this.channel.society.id;
                });

                this._initLocales();

                this._initSorts();

                this._initCurrencies();

                this._hydrateForm();

                this._loadSocietyWebsites();

                if(this.society.hasAccessAPI){

                    this._loadSocietyIps();
                }

                this._initEvents();

                if(this.isClassicMode){

                    this._loadSelfOfferCatalogs();

                    this._configureSearchService();

                    const allConfigurationFieldsChecked: boolean = [!this.channel.allowBooking, !this.channel.allowRequest, !this.channel.allowOnlineSale, this.channel.allowGiftVoucher].every((value: boolean): boolean => {

                        return value;
                    });

                    if(!allConfigurationFieldsChecked){

                        this.updateGiftVoucher();
                    }
                }
                this._initFaviconConfigs();

                this.colorTitle = data.channel?.style?.colorTitle;

                this.colorSubTitle = data.channel?.style?.colorSubTitle;

                this.colorBackgroundButton = data.channel?.style?.colorBackgroundButton;

                this.colorTextButton = data.channel?.style?.colorTextButton;

                this.colorHighlighting = data.channel?.style?.colorHighlighting;

                this.colorDescription = data.channel?.style?.colorDescription;

                this.offerSearchService.selectedOffers.next(data.channel.offerCatalogs.map((item: OfferCatalog): Offer => {

                    return item.offer;
                }));

                this.offerSearchService.displayReferencedOffer = this.form.get('referenced').value;
            });

            // Redirection automatique sur un onglet

            this._activatedRoute.fragment.subscribe((fragment: ('JSIntegration'|'APIIntegration')): void => {

                switch (fragment) {

                    case "JSIntegration":

                        this.tabGroup.selectedIndex = 2;

                        break;

                    case "APIIntegration":

                        this.tabGroup.selectedIndex = 2;

                        break;
                }
            });

            this._handleClipboardResponse();
        });
    }

    private _loadSelfOfferCatalogs(): void {

        this._offerCatalogService.getItemsAPI().subscribe((items: OfferCatalog[]): void => {

            this.selfOfferCatalogs$.next(items);

            this.offerSearchService.selectedOffers.subscribe((selectedItems: Offer[]): void => {

                this.form.get('offerCatalogs').patchValue(selectedItems.map((selectedItem: Offer): Partial<OfferCatalog> => {

                    const offerCatalog: OfferCatalog = this.selfOfferCatalogs$.value.find((selfOfferCatalog: OfferCatalog): boolean => {

                        return selfOfferCatalog.offer.id === selectedItem.id;
                    });

                    return {
                        id: offerCatalog.id
                    };
                }));
            });
        });
    }

    private _loadSocietyIps(): void {

        this._societyIpService.getItemsAPI(this.society.id).subscribe((items: SocietyIp[]): void => {

            this.societyIps = items;
        });
    }

    private _configureSearchService(): void {

        this.offerSearchService.selectOfferAllowed = true;
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            owner: [null, Validators.required],
            ownerName: ['', Validators.required],
            marketplace: [null, [Validators.required]],
            locale: [null, [Validators.required]],
            currency: [null, [Validators.required]],
            searchEngine: [false, [Validators.required]],
            referenced: [false, [Validators.required]],
            enable: [false, [Validators.required]],
            displayCo: [false],
            integrationMode: ['subDomain'],
            canonicalUrl: ['', [(control: UntypedFormControl) => {

                if(!this.form){

                    return null;
                }

                if(!this.channel.enableJS) {

                    return null;
                }

                if(!control.value || (control.value.length < 1)) {

                    return {
                        'isRequired': {
                            valid: false
                        }
                    }
                }

                if(/^\//g.test(control.value)){

                    return null;
                }

                return {
                    'isPatternInvalid': {
                        valid: false
                    }
                }
            }]],
            offerCatalogs: [[]],
            isDistributor: [false, Validators.required],
            comment: [null],
            translations: new UntypedFormArray([]),
            style: this._formBuilder.group({
                id: [''],
                font: ['', Validators.required],
                colorTitle: [null],
                colorDescription: [null],
                colorSubTitle: [null],
                colorBackgroundButton: [null],
                colorTextButton: [null],
                colorHighlighting: [null]
            }),
            sort: this._formBuilder.group({
                id: [''],
                orderBy: [null, Validators.required],
                operator: [null, Validators.required]
            }),
            allowBooking: [false],
            allowGiftVoucher: [false],
            allowRequest: [false],
            allowOnlineSale: [false],
            allowShowcase: [false],
            displayStartFromPrice: [true, [Validators.required]],
            allowMap: [true, [Validators.required]],
            picture: this._formBuilder.group({
                copyright: [''],
                image: this._formBuilder.group({
                    image: ['']
                })
            }),
            pictureUrl: [null, [Validators.pattern(REGEX_WEBSITE)]],
            logo: this._formBuilder.group({
                copyright: [''],
                image: this._formBuilder.group({
                    image: ['']
                })
            }),
            logoUrl: [null, [Validators.pattern(REGEX_WEBSITE)]],
            favicon: [null],
            allowedIps: [[], [(control: UntypedFormControl) => {

                if(!this.channel.enableApi){

                    return null;
                }

                if(Boolean(control.value.length)){

                    return null;
                }

                return {
                    'isRequired': {
                        valid: false
                    }
                };
            }]]
        }, { validators: [(form: UntypedFormGroup) => {

                if(form.get('allowBooking').value || form.get('allowGiftVoucher').value || form.get('allowRequest').value || form.get('allowOnlineSale').value || form.get('allowShowcase').value){

                    return null;
                }

                return {
                    'isMarketingConfigurationRequired': {
                        valid: false
                    }
                };
            }]
        });

        if(this.isRestrictedMode){

            this.form.addControl('groupQuota', this._formBuilder.control(0, [Validators.required]));

            this.form.addControl('individualQuota', this._formBuilder.control(0, [Validators.required]));
        }

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'font',
                attrs: {
                    label: this._translateService.instant('channel.fields.font.value'),
                    required: true,
                    choices: this.fonts,
                    multiple: false,
                }
            }
        });

        this._initTranslationsForm();

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign({...this.form.getRawValue()}, {
                picture: (!this.form.get('picture').value.image.image) ? null : this.form.get('picture').value,
                pictureUrl: this.hasImage(this.form.get('picture')) ? this.form.get('pictureUrl').value : null,
                logo: (!this.form.get('logo').value.image.image) ? null :this.form.get('logo').value,
                logoUrl: this.hasImage(this.form.get('logo')) ? this.form.get('logoUrl').value : null,
                favicon:(!this.form.get('favicon').value) ? null : this.form.get('favicon').value,
                currency: {id: parseInt(this.form.get('currency').value)},
                marketplace: this.form.get('marketplace').value,
                enable: ('enable' in this.form.value) ? this.form.get('enable').value : false
            });

            this._channelService.updateItemAPI(this.channel.id, data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('channel.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                if (!this.saveAndContinueConfiguration.active) {

                    this.redirectToList();
                }
            });
        };
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                id: [null],
                name: ['', [Validators.required]],
                title: [null],
                description: [null]
            });
        };
    }

    private _initEvents(): void {

        [this.form.get('allowBooking'), this.form.get('allowGiftVoucher'), this.form.get('allowRequest'), this.form.get('allowOnlineSale')].forEach((control: AbstractControl): void => {

            control.valueChanges.subscribe((): void => {

                if(this.hasAccessOnlineSale && this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && !this.form.get('isDistributor').value && this.form.get('allowBooking').value){

                    this.form.get('allowOnlineSale').patchValue(true, {emitEvent: false, onlySelf: true});
                }

                if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) && !this.form.get('isDistributor').value && this.form.get('allowBooking').value){

                    this.form.get('allowOnlineSale').patchValue(true, {emitEvent: false, onlySelf: true});
                }

                const isOnlyOnlineSale: boolean = [
                    this.form.get('allowOnlineSale').value,
                    [this.form.get('allowBooking'), this.form.get('allowGiftVoucher'), this.form.get('allowRequest')].every((control: AbstractControl): boolean => { return !control.value; })
                ].every((condition: boolean): boolean => {

                    return Boolean(condition);
                });

                this.offerSearchService.updateFilter$.next({
                    key: 'permanentOptions.offerPermanentOption.locales',
                    value: isOnlyOnlineSale
                });

                this.offerSearchService.updateFilter$.next({
                    key: 'onlineSale.enable',
                    value: isOnlyOnlineSale
                });
            });

            control.valueChanges.subscribe((): void => {

                this.updateGiftVoucher();

            });
        });

        this.form.get('allowBooking').valueChanges.subscribe((): void => {

            this._handleFormMarketingOptionStates();
        });

        this.form.get('allowShowcase').valueChanges.subscribe((allowShowcase: boolean): void => {

            if(allowShowcase){

                this.form.get('allowOnlineSale').disable({emitEvent: false, onlySelf: true});

                this.form.get('allowBooking').disable({emitEvent: false, onlySelf: true});

                this.form.get('allowRequest').disable({emitEvent: false, onlySelf: true});

                this.form.get('allowGiftVoucher').disable({emitEvent: false, onlySelf: true});
            }
            else{

                this.form.get('allowBooking').enable({emitEvent: false, onlySelf: true});

                this.form.get('allowRequest').enable({emitEvent: false, onlySelf: true});

                this._handleFormMarketingOptionStates();
            }

            this.form.get('allowOnlineSale').patchValue(false, {emitEvent: false, onlySelf: true});

            this.form.get('allowBooking').patchValue(false, {emitEvent: false, onlySelf: true});

            this.form.get('allowRequest').patchValue(false, {emitEvent: false, onlySelf: true});

            this.form.get('allowGiftVoucher').patchValue(false, {emitEvent: false, onlySelf: true});

            this.form.get('displayStartFromPrice').patchValue(true);

            this.offerSearchService.updateExtraData('allowShowcase', allowShowcase);

            if(allowShowcase){

                setTimeout((): void => {

                    this.scrollToBottomOfElement(this.showcaseReference);
                });
            }
        });

        this.form.get('isDistributor').valueChanges.subscribe((value: boolean): void => {

            if(value && (!this.isAllowGiftVoucher || !this.isAvailableGiftVoucher)) {

                this.form.get('allowBooking').patchValue(true);

                this.form.get('allowGiftVoucher').patchValue(false);
            }

            if(this.hasAccessOnlineSale && this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && !this.form.get('isDistributor').value && this.form.get('allowBooking').value){

                this.form.get('allowOnlineSale').patchValue(true);
            }

            if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) && !this.form.get('isDistributor').value && this.form.get('allowBooking').value){

                this.form.get('allowOnlineSale').patchValue(true);
            }

            this._handleFormMarketingOptionStates();

            value ? this.form.get('currency').enable() : this.form.get('currency').disable();

            this.offerSearchService.updateExtraData('isDistributor', value);

            this.offerSearchService.updateFilter$.next({
                key: 'isDistributor',
                value: value
            });
        });
    }

    private updateGiftVoucher(): void {

        this.offerSearchService.updateFilter$.next({
            key: 'giftVoucher.enable',
            value: this.form.get('allowGiftVoucher').value && !this.form.get('allowBooking').value && !this.form.get('allowRequest').value && !this.form.get('allowOnlineSale').value
        });
    }

    private _initSocietiesSubscription(): void {

        if(this.isClassicMode){

            this._societiesSubscription = of([this._activatedRoute.snapshot.data['society'] as Society]);
        }

        if(this.isRestrictedMode){

            const customFilter : ArrayFilterField = new TextFilterField('channelRestricted', 'eq', '1');

            const roleFilters: ArrayFilterField[] = [
                new ArrayFilterField('admin.roles', 'lkin', 'ROLE_OFFER_DISTRIBUTOR'),
                new ArrayFilterField('admin.roles', 'lkin', 'ROLE_INSTITUTIONAL'),
                new ArrayFilterField('admin.roles', 'lkin', 'ROLE_FEDERATION')
            ];

            const params: string[] = [
                customFilter.serialize
            ];

            roleFilters.forEach((roleFilter: ArrayFilterField): void => {

                params.push(roleFilter.serialize);
            });

            this._societiesSubscription = this._societyService.getItemsAPI(params);
        }
    }

    private _initLocales(): void {

        this.locales$ = of(LOCALE_ITEMS);
    }

    private _initSorts(): void {

        this.sortsOrderBy$ = of(CHANNEL_SORT_ORDER_BY);

        this.sortsOperators$ = of(CHANNEL_SORT_OPERATORS);
    }

    private _initCurrencies(): void {

        this.currencies$ = this._currencyService.getItemsAPI().pipe(
            map((currencies: Currency[]): Currency[] => {

                return currencies.filter((currency: Currency): boolean => {

                    return !['TWD','AED'].includes(currency.code);
                });
            })
        );
    }

    private _loadSocietyWebsites(): void {

        this.societyWebsites$ = this._websiteService.getSocietyItemsAPI(this.society.id).pipe(
            tap((societyWebsites: SocietyWebsite[]): void => {

                const societyWebsite: SocietyWebsite = societyWebsites.find((item: SocietyWebsite): boolean => {

                    return item.website === this.society.mainWebsite;
                });

                this.form.get('marketplace').patchValue(this.hasAccessAdditionalWebsite ? this.channel.marketplace : societyWebsite);
            })
        );
    }

    private _hydrateForm(): void {

        this.form.patchValue(Object.assign({...this.channel}, {
            owner: this.society.id,
            currency: this.channel.currency.id,
            allowGiftVoucher: this.isAllowGiftVoucher ? this.channel.allowGiftVoucher : false,
            allowOnlineSale: this.isAllowOnlineSale ? this.channel.allowOnlineSale : false,
            integrationMode: this.integrationMode,
            picture: this.channel.picture || {
                copyright: '',
                image: {
                    image:''
                }
            },
            logo: this.channel.logo || {
                copyright: '',
                image: {
                    image:''
                }
            }
        }));

        this._handleFormMarketingOptionStates();

        if(this.channel.allowShowcase){

            this.form.get('allowOnlineSale').disable({emitEvent: false, onlySelf: true});

            this.form.get('allowBooking').disable({emitEvent: false, onlySelf: true});

            this.form.get('allowRequest').disable({emitEvent: false, onlySelf: true});

            this.form.get('allowGiftVoucher').disable({emitEvent: false, onlySelf: true});

            this.offerSearchService.updateExtraData('allowShowcase', true);
        }

        this.form.get('canonicalUrl').disable();

        this.form.get('owner').disable();

        this.form.get('integrationMode').disable();

        this.form.get('referenced').disable();

        if(this.isDisabledField){

            this.form.get('locale').disable();

            this.form.get('isDistributor').disable();
        }

        this.isDistributor ? this.form.get('currency').enable() : this.form.get('currency').disable();

        this.isActivationAllowed ? this.form.get('enable').enable() : this.form.get('enable').disable();

        this.hasAccessAdditionalWebsite ? this.form.get('marketplace').enable() : this.form.get('marketplace').disable();

        this.channel.translations.forEach((translation: ChannelTranslation): void => {

            const control: FormGroup = this.translationBuilder.addItemControl(this.translationBuilder.getLocaleItem(translation.locale), translation);

            if(this.isDisabledField){

                control.get('name').disable();

                control.get('title').disable();
            }
        });

        this.offerSearchService.updateExtraData('isDistributor', this.form.get('isDistributor').value);
    }

    private _handleFormMarketingOptionStates(): void {

        this.isAllowOnlineSaleEnabled ? this.form.get('allowOnlineSale').enable({emitEvent: false, onlySelf: true}) : this.form.get('allowOnlineSale').disable({emitEvent: false, onlySelf: true});

        this.isAllowGiftVoucher ? this.form.get('allowGiftVoucher').enable({emitEvent: false, onlySelf: true}) : this.form.get('allowGiftVoucher').disable({emitEvent: false, onlySelf: true});
    }

    private _handleClipboardResponse(): void {

        this._clipboardService.copyResponse$.subscribe((response: IClipboardResponse): void => {

            if (response.isSuccess) {

                this._snackBar.open(this._translateService.instant('content.copied.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });
            }
        });
    }

    public redirectToList(): void {

        this._router.navigate(this.isClassicMode ? ['account/channel/list'] : ['account/channel/restricted/list']);
    }

    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    public hasRole(role: Role): boolean {

        if(!this.society){

            return false;
        }

        return this.society.admin.roles.includes(role);
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public hasAllOfThisRoles(roles: Role[]): boolean {

        return roles.every((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public submitEnter($event): void {

        if($event.key == 'Enter'){

            $event.preventDefault();

            return;
        }
    }

    public changeFont(event): void {

        this.form.controls['style'].get('font').patchValue(event.value);
    }

    public updateColorField(value, field): void{

        this.form.controls['style'].get(field).patchValue(value);
    }

    public changeLocale(): void {

        this.translationBuilder.changeLocale([this.form.get('locale').value]);

        if(this.form.get('locale').invalid){

            return;
        }

        const offers: Offer[] = [...this.offerSearchService.selectedOffers.getValue()];

        this.offerSearchService.selectedOffers.next(offers.filter((offer: Offer): boolean => {

            return offer.locales.includes(this.form.get('locale').value);
        }));
    }

    public deselectOffer(): void {

        this.offerSearchService.selectedOffers.next([]);
    }

    public copyJSIntegrationWCContent(): void {

        this._clipboardService.copy(`<tywin-channel token="${this.channel.tokenJs}" base-url="${this.canonicalUrl}"></tywin-channel>`);
    }

    public copyJSIntegrationSourceContent(): void {

        this._clipboardService.copy(this.JSIntegrationSourceTag);
    }

    public submit(saveAndContinue: boolean, fragment?: ('JSIntegration'|'APIIntegration')): void {

        this.saveAndContinueConfiguration = {
            active: saveAndContinue,
            fragment: fragment
        };

        this.formService.submit();
    }

    public userHasAccess(access: AccessType): boolean {

        if(!this.society){

            return false;
        }

        const tags: AccessType[] = this.society.admin.accesses.map((access: Access): AccessType => {

            return access.tag;
        });

        return tags.includes(access);
    }

    public compareSocietyWebsite(a: SocietyWebsite, b: SocietyWebsite): boolean {

        if(!a || !b){

            return false;
        }

        return a.id === b.id;
    }

    public compareSocietyIp(a: SocietyIp, b: SocietyIp): boolean {

        if(!a || !b){

            return false;
        }

        return a.id === b.id;
    }

    public editCanonicalUrl(): void {

        if (this.channel.enableJS) {

            this.form.get('canonicalUrl').enable();
        }
    }

    public hasImage(formControl: AbstractControl): boolean {

        if(!this.form) {

            return false;
        }

        const imageControl: { image: object } = formControl.get('image').value;

        return Boolean(!!imageControl.image && imageControl.image.hasOwnProperty('id'));
    }

    public orderByLabel(orderBy: ChannelSortOrderBy): string {

        return this._translateService.instant(`channel.sort.orderBy.${orderBy}`);
    }

    public operatorLabel(operator: ChannelSortOperator): string {

        return this._translateService.instant(`channel.sort.operator.${operator.toLowerCase()}`);
    }

    public isOneOfTheseIntegrationModes(items: ChannelIntegrationMode[]): boolean {

        const integrationMode: ChannelIntegrationMode = this.form.get('integrationMode').value;

        return items.includes(integrationMode);
    }

    public scrollToBottomOfElement(element: ElementRef): void {

        if(!element || !element.nativeElement){

            return;
        }

        element.nativeElement.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    get hasAccessGiftVoucher(): boolean {

        if(!this.society){

            return false;
        }

        const accesses = this.society.admin.accesses.filter((access: Access): boolean => {

            return access.tag === 'ONLINE_SALE';
        });

        return (accesses.length > 0);
    }

    get hasAccessOnlineSale(): boolean {

        if(!this.society){

            return false;
        }

        return this.society.admin.accesses.some((access: Access): boolean => {

            return access.tag === 'ONLINE_SALE';
        });
    }

    get hasAccessUnlimitedChannel(): boolean {

        if(!this.society){

            return false;
        }

        return this.society.admin.accesses.some((access: Access): boolean => {

            return access.tag === 'UNLIMITED_CHANNEL';
        });
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get isDistributor(): boolean {

        return this.form.get('isDistributor').value;
    }

    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get isDisabledField(): boolean {

        if(this.isRestrictedMode){

            return false;
        }

        return !this.userHasAccess('CHANNEL_CREATE_IS_MINE') && !this.userHasAccess('CHANNEL_DELETE_IS_MINE');
    }

    get JSIntegrationSourceTag(): string {

        return `<script src="${environment.channelBaseUrl}/build/channel/channel.js" defer></script>`;
    }

    get subDomainIntegrationLink(): string {

        return `https://${this.channel.subDomain}${environment.channelSubDomainSuffix}`;
    }

    get sitemapUrl(): string {

        return `${environment.channelBaseUrl}/${this.channel.id}/sitemap.xml`;
    }

    get styleForm(): UntypedFormGroup {

        return this.form.get('style') as UntypedFormGroup;
    }

    get sortForm(): UntypedFormGroup {

        return this.form.get('sort') as UntypedFormGroup;
    }

    get isAllowGiftVoucher(): boolean {

        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_FEDERATION', 'ROLE_INSTITUTIONAL'])){

            return true;
        }

        if(!this.hasAccessGiftVoucher){

            return false;
        }

        if(this.isDistributor){

            return this.society.isValidMangoPayAccount;
        }

        return true;
    }

    get isAvailableGiftVoucher(): boolean {

        if(!this.form){

            return false;
        }

        if(!this.society){

            return false;
        }

        if(this.hasRole('ROLE_OFFER_CREATOR')){

            return this.society.isValidMangoPayAccount;
        }

        if(this.hasRole('ROLE_OFFER_DISTRIBUTOR')){

            return this.society.isValidMangoPayAccount || !this.isDistributor;
        }

        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            return true;
        }

        return false;
    }

    get isAllowOnlineSale(): boolean {

        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_FEDERATION', 'ROLE_INSTITUTIONAL'])){

            return true;
        }

        if(!this.hasAccessOnlineSale){

            return false;
        }

        if(this.isDistributor){

            return this.society.isValidMangoPayAccount;
        }

        return true;
    }

    get isActivationAllowed(): boolean {

        if(!this.form || !this.form.get('enable')){

            return false;
        }

        if(!this.society){

            return false;
        }

        if(this.hasAccessUnlimitedChannel){

            return true;
        }

        if(this.channel.enable){

            return true;
        }

        return this.society.countAllowedChannels > this.society.countEnableChannels;
    }

    get hasAccessAdditionalWebsite(): boolean {

        if(!this.society){

            return false;
        }

        return this.society.admin.accesses.some((access: Access): boolean => {

            return access.tag === 'ADDITIONAL_WEBSITE';
        });
    }

    get isAllowOnlineSaleEnabled(): boolean {

        if(this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])){

            if(!this.hasAccessOnlineSale){

                return false;
            }

            if (this.hasRole('ROLE_OFFER_DISTRIBUTOR') && !(this.society.isValidMangoPayAccount)) {

                return false;
            }

            if(!this.form.get('isDistributor').value && this.form.get('allowBooking').value){

                return false;
            }
        }

        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            if(!this.form.get('isDistributor').value && this.form.get('allowBooking').value){

                return false;
            }
        }

        return true;
    }

    get mode(): ChannelMode {

        return this._activatedRoute.snapshot.data['mode'];
    }

    get isClassicMode(): boolean {

        return this.mode === 'classic';
    }

    get isRestrictedMode(): boolean {

        return this.mode === 'restricted';
    }

    get fontFieldConfig(): SelectSearchConfig {

        return this.fieldCollection.getFieldConfig('font') as SelectSearchConfig;
    }

    get marketplace(): string {

        const societyService: SocietyWebsite = (this.form.get('marketplace').value as SocietyWebsite);

        return societyService ? societyService.website : null;
    }

    get canonicalUrl(): string {

        const canonicalUrl: string = (this.form.get('canonicalUrl').value as string);

        return `${this.marketplace}${canonicalUrl}`;
    }

    get imageConfig(): ImageConfig {
        return {
            id: 'image',
            gallery_context: 'channel_landscape',
            required: false,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false
            }
        }
    }

    get logoConfig(): ImageConfig {
        return {
            id: 'image',
            gallery_context: 'channel_logo',
            required: false,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false
            }
        }
    }

    get integrationMode(): ChannelIntegrationMode {

        if(!this.channel){

            return null;
        }

        const items: { [p in ChannelIntegrationMode]: boolean } = {
            subDomain: this.channel.enableSubDomain,
            JS: this.channel.enableJS,
            API: this.channel.enableApi
        };

        return Object.keys(items).find((key: ChannelIntegrationMode): boolean => {

            return Boolean(items[key]);

        }) as ChannelIntegrationMode;
    }

    get allowedIps(): string[] {

        return (this.form.get('allowedIps').value as SocietyIp[]).map((item: SocietyIp): string => {

            return item.address;
        });
    }

    private _initFaviconConfigs(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.ico'}).subscribe((help: string): void => {

            this.faviconConfig = {
                id: 'favicon',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'channel_favicon'
                },
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                help: help,
                upload: {
                    maxSize : 100000,
                    allowedTypes: ['image/x-icon'].join(',')
                }
            };
        });
    }
}
