import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {ActivatedRoute, Router} from "@angular/router";
import {CurrencyService} from "@core/shared/services/currency.service";
import {BehaviorSubject, Observable, of} from "rxjs";
import {Currency} from "@core/shared/models/currency";
import {ChannelService} from "@core/shared/services/channel.service";
import {Society} from "@core/shared/models/society";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {User} from "@core/shared/models/user";
import {OfferSearchService} from "@core/shared/services/offer/offer-search.service";
import {Offer} from "@core/shared/models/offer";
import {Role} from "@core/shared/models/role";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {SubDomaineService} from "@core/shared/services/sub-domain.service";
import {SubdomainResponse} from "@core/shared/models/subdomain";
import {map, tap} from "rxjs/operators";
import {FieldCollection} from "@lib/form/field";
import {environment} from "../../../../../environments/environment";
import {TranslationService} from "@core/shared/services/translation.service";
import {MatLegacyTab as MatTab, MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {Access} from "@core/shared/models/access";
import {ChannelIntegrationMode, ChannelMode} from "@core/shared/models/channel";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {SocietyService} from "@core/shared/services/society.service";
import {ChannelFont} from "@core/shared/models/channel/channel-font";
import {CHANNEL_FONTS} from "@core/shared/data/channel/channel-font";
import {SelectSearchConfig} from "@lib/form/fields/select-search/select-search.component";
import {SocietyWebsite} from "@core/shared/models/society/society-website";
import {WebsiteService} from "@core/shared/services/website.service";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import {ApiService} from "@core/shared/services/api.service";
import {REGEX_WEBSITE} from "@core/shared/models/regex";
import {CHANNEL_SORT_OPERATORS, CHANNEL_SORT_ORDER_BY, ChannelSortOperator, ChannelSortOrderBy} from "@core/shared/models/channel-sort";
import {SocietyIp} from "@core/shared/models/society/society-ip";
import {SocietyIpService} from "@core/shared/services/society/society-ip.service";
import {FileConfig} from '@lib/form/fields/file/file.component';
import {OfferCardService} from "@core/shared/services/offer/offer-card.service";

@Component({
    selector: 'app-core-page-channel-create',
    templateUrl: './page-channel-create.component.html',
    styleUrls: ['./page-channel-create.component.scss'],
    providers: [
        FormService,
        OfferSearchService,
        OfferCardService
    ]
})
export class PageChannelCreateComponent implements OnInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild('offerCardPreviewTab', {static: false}) offerCardPreviewTabRef: MatTab;

    @ViewChild('offerViewPreviewTab', {static: false}) offerViewPreviewTabRef: MatTab;

    @ViewChild('showcaseReference', { static: false }) showcaseReference: ElementRef<HTMLDivElement>;

    private _societiesSubscription: Observable<Society[]>;

    public user: User;

    public society: Society;

    public societies: Society[] = [];

    public translationBuilder: TranslationBuilder;

    public locales$: Observable<LocaleItem[]>;

    public sortsOrderBy$: Observable<ChannelSortOrderBy[]>;

    public sortsOperators$: Observable<ChannelSortOperator[]>;

    public currencies$: Observable<Currency[]>;

    public societyWebsites$: Observable<SocietyWebsite[]>;

    public fieldCollection = new FieldCollection();

    public colorTitle: string;

    public colorSubTitle: string;

    public colorBackgroundButton: string;

    public colorTextButton: string;

    public colorHighlighting: string;

    public colorDescription: string;

    public fonts: ChannelFont[] = CHANNEL_FONTS;

    public societyIps: SocietyIp[] = [];

    public selfOfferCatalogs$: BehaviorSubject<OfferCatalog[]> = new BehaviorSubject<OfferCatalog[]>([]);

    public saveAndContinueConfiguration: { active: boolean, fragment: ('JSIntegration'|'APIIntegration') } = {
        active: false,
        fragment: null
    };

    public faviconConfig: FileConfig;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _currencyService: CurrencyService,
        private _channelService: ChannelService,
        private _offerCatalogService: OfferCatalogService,
        private _apiService: ApiService,
        private _societyService: SocietyService,
        private _websiteService: WebsiteService,
        private _societyIpService: SocietyIpService,
        public formService: FormService,
        public offerSearchService: OfferSearchService,
        public subDomaineService: SubDomaineService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { user: User, society?: Society }): void => {

            this.user = data.user;

            this._initForm();

            this._initSocietiesSubscription();

            this._societiesSubscription.subscribe((societies: Society[]): void => {

                this.societies = societies;

                if(this.isClassicMode){

                    this.society = this.societies.find((item: Society): boolean => {

                        return item.id === (this._activatedRoute.snapshot.data['society'] as Society).id;
                    });
                }

                this._initLocales();

                this._initSorts();

                this._initCurrencies();

                if(this.isClassicMode) {

                    this._hydrateForm();

                    this._loadSelfOfferCatalogs();

                    this._configureSearchService();

                    this._loadSocietyWebsites();

                    if(this.society.hasAccessAPI){

                        this._loadSocietyIps();
                    }
                }

                this._initEvents();

                this._initFaviconConfigs();
            });
        });
    }

    private updateGiftVoucher(): void {

        this.offerSearchService.updateFilter$.next({
            key: 'giftVoucher.enable',
            value: this.form.get('allowGiftVoucher').value && !this.form.get('allowBooking').value && !this.form.get('allowRequest').value && !this.form.get('allowOnlineSale').value
        });
    }

    private _initSocietiesSubscription(): void {

        if(this.isClassicMode){

            this._societiesSubscription = of([this._activatedRoute.snapshot.data['society'] as Society]);
        }

        if(this.isRestrictedMode){

            const customFilter : ArrayFilterField = new TextFilterField('channelRestricted', 'eq', '1');

            const roleFilters: ArrayFilterField[] = [
                new ArrayFilterField('admin.roles', 'lkin', 'ROLE_OFFER_DISTRIBUTOR'),
                new ArrayFilterField('admin.roles', 'lkin', 'ROLE_INSTITUTIONAL'),
                new ArrayFilterField('admin.roles', 'lkin', 'ROLE_FEDERATION')
            ];

            const params: string[] = [
                customFilter.serialize
            ];

            roleFilters.forEach((roleFilter: ArrayFilterField): void => {

                params.push(roleFilter.serialize);
            });

            this._societiesSubscription = this._societyService.getItemsAPI(params);
        }
    }

    private _loadSelfOfferCatalogs(): void {

        this._offerCatalogService.getItemsAPI().subscribe((items: OfferCatalog[]): void => {

            this.selfOfferCatalogs$.next(items);
        });
    }

    private _loadSocietyIps(): void {

        this._societyIpService.getItemsAPI(this.society.id).subscribe((items: SocietyIp[]): void => {

            this.societyIps = items;
        });
    }

    private _configureSearchService(): void {

        this.offerSearchService.selectOfferAllowed = true;
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            owner: [null, Validators.required],
            ownerName: [null, Validators.required],
            marketplace: [null, [Validators.required]],
            locale: [null, [Validators.required]],
            currency: [null, [Validators.required]],
            searchEngine: [false, [Validators.required]],
            offerCatalogs: [[]],
            isDistributor: [false, Validators.required],
            comment: [null],
            enableSubDomain: [true],
            enableJS: [false],
            enableApi: [false],
            integrationMode: ['subDomain'],
            referenced: [false],
            enable: [false, [Validators.required]],
            displayCo: [false],
            canonicalUrl: ['', [(control: UntypedFormControl) => {

                if (!this.form){

                    return null;
                }

                if (!this.form.get('enableJS').value) {

                    return null;
                }

                if (control.value.length < 1) {

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }

                if (/^\//g.test(control.value)){

                    return null;
                }

                return {
                    'isPatternInvalid': {
                        valid: false
                    }
                };
            }]],
            subDomain: [null, [], [(control: UntypedFormControl) => {

                if (control.value) {

                    if (!/^[a-z0-9-]*$/.test(control.value)) {
                        return of({isPatternInvalid: {valid: false}});
                    }

                    return this.subDomaineService.isValidDomain({'subDomain': control.value})
                        .pipe(
                            map((data: SubdomainResponse) => {
                                return !control.value ? null : (data.isValid ? null : {isNotValid: {valid: false}});
                            })
                        );
                } else {

                    return of(null);
                }
            }]],
            translations: new UntypedFormArray([]),
            style: this._formBuilder.group({
                font: ['font-lato', Validators.required],
                colorTitle: [null],
                colorSubTitle: [null],
                colorDescription: [null],
                colorBackgroundButton: [null],
                colorTextButton: [null],
                colorHighlighting: [null]
            }),
            sort: this._formBuilder.group({
                orderBy: ['updatedAt', Validators.required],
                operator: ['ASC', Validators.required]
            }),
            allowBooking: [false],
            allowGiftVoucher: [false],
            allowRequest: [false],
            allowOnlineSale: [false],
            allowShowcase: [false],
            displayStartFromPrice: [true, [Validators.required]],
            picture: this._formBuilder.group({
                copyright: [''],
                image: this._formBuilder.group({
                    image: ['']
                })
            }),
            pictureUrl: [null, [Validators.pattern(REGEX_WEBSITE)]],
            logo: this._formBuilder.group({
                copyright: [''],
                image: this._formBuilder.group({
                    image: ['']
                })
            }),
            logoUrl: [null, [Validators.pattern(REGEX_WEBSITE)]],
            favicon: [null],
            allowMap: [true, [Validators.required]],
            allowedIps: [[], [(control: UntypedFormControl) => {

                if (!this.form){

                    return null;
                }

                if (!this.form.get('enableApi').value) {

                    return null;
                }

                if(Boolean(control.value.length)){

                    return null;
                }

                return {
                    'isRequired': {
                        valid: false
                    }
                };
            }]]
        }, { validators: [(form: UntypedFormGroup) => {

                if(form.get('allowBooking').value || form.get('allowGiftVoucher').value || form.get('allowRequest').value || form.get('allowOnlineSale').value || form.get('allowShowcase').value){

                    return null;
                }

                return {
                    'isMarketingConfigurationRequired': {
                        valid: false
                    }
                };
            }]
        });

        if(this.isRestrictedMode){

            this.form.addControl('groupQuota', this._formBuilder.control(0, [Validators.required]));

            this.form.addControl('individualQuota', this._formBuilder.control(0, [Validators.required]));
        }

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'font',
                attrs: {
                    label: this._translateService.instant('channel.fields.font.value'),
                    required: true,
                    choices: this.fonts,
                    multiple: false,
                }
            }
        });

        this.form.controls["subDomain"].setValidators([(control: UntypedFormControl) => {

            if (this.form.get('enableSubDomain').value == false) {

                return null;
            }

            return (!control.value?.length) ? {required: {valid: false}} : null;
        }]);

        this._initTranslationsForm();

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign(this.form.getRawValue(), {
                picture: (!this.form.get('picture').value.image.image) ? null : this.form.get('picture').value,
                pictureUrl: this.hasImage(this.form.get('picture')) ? this.form.get('pictureUrl').value : null,
                favicon:(!this.form.get('favicon').value) ? null : this.form.get('favicon').value,
                logo: (!this.form.get('logo').value.image.image) ? null :this.form.get('logo').value,
                logoUrl: this.hasImage(this.form.get('logo')) ? this.form.get('logoUrl').value : null,
                currency: {id: parseInt(this.form.get('currency').value)},
                marketplace: this.form.get('marketplace').value,
                enable: ('enable' in this.form.value) ? this.form.get('enable').value : false
            });

            this._channelService.createSocietyItemAPI(this.society.id, data).subscribe((channel: { id: number }): void => {

                this._snackBar.open(this._translateService.instant('channel.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                if (this.saveAndContinueConfiguration.active) {

                    if(this.isClassicMode){

                        this._router.navigate(['account/channel/update', channel.id], {fragment: this.saveAndContinueConfiguration.fragment});
                    }

                    if(this.isRestrictedMode){

                        this._router.navigate(['account/channel/restricted/update', channel.id]);
                    }

                } else {

                    this.redirectToList();
                }
            }, (): void => {

                this.saveAndContinueConfiguration = {
                    active: false,
                    fragment: null
                };
            });
        };

        this.offerSearchService.selectedOffers.subscribe((selectedItems: Offer[]): void => {

            this.form.get('offerCatalogs').patchValue(selectedItems.map((selectedItem: Offer): Partial<OfferCatalog> => {

                const offerCatalog: OfferCatalog = this.selfOfferCatalogs$.value.find((selfOfferCatalog: OfferCatalog): boolean => {

                    return selfOfferCatalog.offer.id === selectedItem.id;
                });

                return {
                    id: offerCatalog.id
                };
            }));
        });
    }

    private _initEvents(): void {

        this.form.get('isDistributor').valueChanges.subscribe((value: boolean): void => {

            if(value && (!this.isAllowGiftVoucher || !this.isAvailableGiftVoucher)) {

                this.form.get('allowBooking').patchValue(true);

                this.form.get('allowGiftVoucher').patchValue(false);
            }

            if(this.hasAccessOnlineSale && this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && !this.form.get('isDistributor').value && this.form.get('allowBooking').value){

                this.form.get('allowOnlineSale').patchValue(true);
            }

            if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) && !this.form.get('isDistributor').value && this.form.get('allowBooking').value){

                this.form.get('allowOnlineSale').patchValue(true);
            }

            this._handleFormMarketingOptionStates();

            this.offerSearchService.updateExtraData('isDistributor', value);

            this.offerSearchService.updateFilter$.next({
                key: 'isDistributor',
                value: value
            });
        });

        this.form.get('owner').valueChanges.subscribe((value: number): void => {

            if(value){

                this._societyService.getItemAPI(this.form.get('owner').value).subscribe((society: Society): void => {

                    this.society = society;

                    this._hydrateForm();

                    this._loadSocietyWebsites();

                    if(this.society.hasAccessAPI){

                        this._loadSocietyIps();
                    }
                });
            }
            else{

                this.society = null;
            }
        });

        this.form.get('referenced').valueChanges.subscribe((value: boolean): void => {

            this.offerSearchService.selectedOffers.next([]);

            this.offerSearchService.displayReferencedOffer = value;
        });

        this.form.get('enableJS').valueChanges.subscribe((value: boolean): void => {

            if (!value) {

                this.form.get('canonicalUrl').patchValue('');
            }

            this.form.get('canonicalUrl').updateValueAndValidity();
        });

        [this.form.get('allowBooking'), this.form.get('allowGiftVoucher'), this.form.get('allowRequest'), this.form.get('allowOnlineSale')].forEach((control: AbstractControl): void => {

            control.valueChanges.subscribe((): void => {

                if(this.hasAccessOnlineSale && this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && !this.form.get('isDistributor').value && this.form.get('allowBooking').value){

                    this.form.get('allowOnlineSale').patchValue(true, {emitEvent: false, onlySelf: true});
                }

                if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) && !this.form.get('isDistributor').value && this.form.get('allowBooking').value){

                    this.form.get('allowOnlineSale').patchValue(true, {emitEvent: false, onlySelf: true});
                }

                const isOnlyOnlineSale: boolean = [
                    this.form.get('allowOnlineSale').value,
                    [this.form.get('allowBooking'), this.form.get('allowGiftVoucher'), this.form.get('allowRequest')].every((control: AbstractControl): boolean => { return !control.value; })
                ].every((condition: boolean): boolean => {

                    return Boolean(condition);
                });

                this.offerSearchService.updateFilter$.next({
                    key: 'permanentOptions.offerPermanentOption.locales',
                    value: isOnlyOnlineSale
                });

                this.offerSearchService.updateFilter$.next({
                    key: 'onlineSale.enable',
                    value: isOnlyOnlineSale
                });
            });

            control.valueChanges.subscribe((): void => {

                this.updateGiftVoucher();

            });
        });

        this.form.get('allowBooking').valueChanges.subscribe((): void => {

            this._handleFormMarketingOptionStates();
        });

        this.form.get('allowShowcase').valueChanges.subscribe((allowShowcase: boolean): void => {

            if(allowShowcase){

                this.form.get('allowOnlineSale').disable({emitEvent: false, onlySelf: true});

                this.form.get('allowBooking').disable({emitEvent: false, onlySelf: true});

                this.form.get('allowRequest').disable({emitEvent: false, onlySelf: true});

                this.form.get('allowGiftVoucher').disable({emitEvent: false, onlySelf: true});
            }
            else{

                this.form.get('allowBooking').enable({emitEvent: false, onlySelf: true});

                this.form.get('allowRequest').enable({emitEvent: false, onlySelf: true});

                this._handleFormMarketingOptionStates();
            }

            this.form.get('allowOnlineSale').patchValue(false, {emitEvent: false, onlySelf: true});

            this.form.get('allowBooking').patchValue(false, {emitEvent: false, onlySelf: true});

            this.form.get('allowRequest').patchValue(false, {emitEvent: false, onlySelf: true});

            this.form.get('allowGiftVoucher').patchValue(false, {emitEvent: false, onlySelf: true});

            this.form.get('displayStartFromPrice').patchValue(true);

            this.offerSearchService.updateExtraData('allowShowcase', allowShowcase);

            if(allowShowcase){

                setTimeout((): void => {

                    this.scrollToBottomOfElement(this.showcaseReference);
                });
            }
        });
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                name: ['', [Validators.required]],
                title: [null],
                description: [null]
            });
        };

        this.translationBuilder.addDefaultItem();
    }

    private _initSorts(): void {

        this.sortsOrderBy$ = of(CHANNEL_SORT_ORDER_BY);

        this.sortsOperators$ = of(CHANNEL_SORT_OPERATORS);
    }

    private _initLocales(): void {

        this.locales$ = of(LOCALE_ITEMS);
    }

    private _initCurrencies(): void {

        this.currencies$ = this._currencyService.getItemsAPI().pipe(
            map((currencies: Currency[]): Currency[] => {

                return currencies.filter((currency: Currency): boolean => {

                    return !['TWD','AED'].includes(currency.code);
                });
            })
        );
    }

    private _loadSocietyWebsites(): void {

        this.societyWebsites$ = this._websiteService.getSocietyItemsAPI(this.society.id).pipe(
            tap((societyWebsites: SocietyWebsite[]): void => {

                const societyWebsite: SocietyWebsite = societyWebsites.find((item: SocietyWebsite): boolean => {

                    return item.website === this.society.mainWebsite;
                });

                this.form.get('marketplace').patchValue(societyWebsite);
            })
        );
    }

    private _hydrateForm(): void {

        if(this.isClassicMode){

            this.form.patchValue({
                owner: this.society.id
            });
        }

        this.form.patchValue({
            ownerName: this.society.name,
            enable: this.isActivationAllowed,
            isDistributor: !this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])
        });

        this.form.get('allowBooking').patchValue(true);

        this.form.get('allowRequest').patchValue(true);

        this.form.get('allowGiftVoucher').patchValue(this.isAllowGiftVoucher);

        this.form.get('allowOnlineSale').patchValue(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']));

        this._handleFormMarketingOptionStates();

        if(this.isClassicMode){

            this.form.get('owner').disable();
        }

        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            this.form.get('isDistributor').disable();
        }

        this.isActivationAllowed ? this.form.get('enable').enable() : this.form.get('enable').disable();

        this.hasAccessAdditionalWebsite ? this.form.get('marketplace').enable() : this.form.get('marketplace').disable();

        this.offerSearchService.updateExtraData('isDistributor', this.form.get('isDistributor').value);
    }

    private _handleFormMarketingOptionStates(): void {

        this.isAllowOnlineSaleEnabled ? this.form.get('allowOnlineSale').enable({emitEvent: false, onlySelf: true}) : this.form.get('allowOnlineSale').disable({emitEvent: false, onlySelf: true});

        this.isAllowGiftVoucher ? this.form.get('allowGiftVoucher').enable({emitEvent: false, onlySelf: true}) : this.form.get('allowGiftVoucher').disable({emitEvent: false, onlySelf: true});
    }

    public redirectToList(): void {

        this._router.navigate(this.isClassicMode ? ['account/channel/list'] : ['account/channel/restricted/list']);
    }

    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    public hasRole(role: Role): boolean {

        if(!this.society){

            return false;
        }

        return this.society.admin.roles.includes(role);
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public hasAllOfThisRoles(roles: Role[]): boolean {

        return roles.every((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public updateIntegrationMode(): void {

        const integrationMode: ChannelIntegrationMode = this.form.get('integrationMode').value;

        switch (integrationMode){

            case 'subDomain':

                this.form.get('enableSubDomain').patchValue(true);

                this.form.get('enableJS').patchValue(false);

                this.form.get('enableApi').patchValue(false);

                this.form.get('subDomain').updateValueAndValidity();

                break;

            case 'JS':

                this.form.get('enableSubDomain').patchValue(false);

                this.form.get('enableJS').patchValue(true);

                this.form.get('enableApi').patchValue(false);

                this.form.get('subDomain').patchValue(null);

                this.form.get('subDomain').updateValueAndValidity();

                break;

            case 'API':

                this.form.get('enableSubDomain').patchValue(false);

                this.form.get('enableJS').patchValue(false);

                this.form.get('enableApi').patchValue(true);

                this.form.get('subDomain').patchValue(null);

                this.form.get('subDomain').updateValueAndValidity();

                this.form.get('referenced').patchValue(false);

                break;
        }

        this.form.get('allowedIps').patchValue([]);

        this.form.get('favicon').patchValue(null);
    }

    public submitEnter($event): void {

        if ($event.key == 'Enter') {

            $event.preventDefault();

            return;
        }
    }

    public submit(saveAndContinue: boolean, fragment?: ('JSIntegration'|'APIIntegration')): void {

        this.saveAndContinueConfiguration = {
            active: saveAndContinue,
            fragment: fragment
        };

        this.formService.submit();
    }

    public changeFont(event): void {

        this.form.controls['style'].get('font').patchValue(event.value);
    }

    public updateColorField(value, field): void {

        this.form.controls['style'].get(field).patchValue(value);
    }

    public changeLocale(): void {

        this.translationBuilder.changeLocale([this.form.get('locale').value]);

        if(this.form.get('locale').invalid){

            return;
        }

        const offers: Offer[] = [...this.offerSearchService.selectedOffers.getValue()];

        this.offerSearchService.selectedOffers.next(offers.filter((offer: Offer): boolean => {

            return offer.locales.includes(this.form.get('locale').value);
        }));
    }

    public deselectOffer(): void {

        this.offerSearchService.selectedOffers.next([]);
    }

    public compareSocietyWebsite(a: SocietyWebsite, b: SocietyWebsite): boolean {

        if(!a || !b){

            return false;
        }

        return a.id === b.id;
    }

    public compareSocietyIp(a: SocietyIp, b: SocietyIp): boolean {

        if(!a || !b){

            return false;
        }

        return a.id === b.id;
    }

    public hasImage(formControl: AbstractControl): boolean {

        if(!this.form) {

            return false;
        }

        const imageControl: { image: object } = formControl.get('image').value;

        return Boolean(!!imageControl.image && imageControl.image.hasOwnProperty('id'));
    }

    public orderByLabel(orderBy: ChannelSortOrderBy): string {

        return this._translateService.instant(`channel.sort.orderBy.${orderBy}`);
    }

    public operatorLabel(operator: ChannelSortOperator): string {

        return this._translateService.instant(`channel.sort.operator.${operator.toLowerCase()}`);
    }

    public isOneOfTheseIntegrationModes(items: ChannelIntegrationMode[]): boolean {

        const integrationMode: ChannelIntegrationMode = this.form.get('integrationMode').value;

        return items.includes(integrationMode);
    }

    public scrollToBottomOfElement(element: ElementRef): void {

        if(!element || !element.nativeElement){

            return;
        }

        element.nativeElement.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
        });
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get styleForm(): UntypedFormGroup {

        return this.form.get('style') as UntypedFormGroup;
    }

    get sortForm(): UntypedFormGroup {

        return this.form.get('sort') as UntypedFormGroup;
    }

    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    get hasAccessGiftVoucher(): boolean {

        if(!this.society){

            return false;
        }

        const accesses = this.society.admin.accesses.filter((access: Access): boolean => {

            return access.tag === 'ONLINE_SALE';
        });

        return (accesses.length > 0);
    }

    get hasAccessOnlineSale(): boolean {

        if(!this.society){

            return false;
        }

        return this.society.admin.accesses.some((access: Access): boolean => {

            return access.tag === 'ONLINE_SALE';
        });
    }

    get hasAccessUnlimitedChannel(): boolean {

        if(!this.society){

            return false;
        }

        return this.society.admin.accesses.some((access: Access): boolean => {

            return access.tag === 'UNLIMITED_CHANNEL';
        });
    }

    get hasAccessAdditionalWebsite(): boolean {

        if(!this.society){

            return false;
        }

        return this.society.admin.accesses.some((access: Access): boolean => {

            return access.tag === 'ADDITIONAL_WEBSITE';
        });
    }

    get isDistributor(): boolean {

        return this.form.get('isDistributor').value;
    }

    get isAllowGiftVoucher(): boolean {

        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_FEDERATION', 'ROLE_INSTITUTIONAL'])){

            return true;
        }

        if(!this.hasAccessGiftVoucher){

            return false;
        }

        if(this.isDistributor){

            return this.society.isValidMangoPayAccount;
        }

        return true;
    }

    get isAvailableGiftVoucher(): boolean {

        if (!this.form) {

            return false;
        }

        if(!this.society){

            return false;
        }

        if (this.hasRole('ROLE_OFFER_CREATOR')) {

            return this.society.isValidMangoPayAccount;
        }

        if (this.hasRole('ROLE_OFFER_DISTRIBUTOR')) {

            return this.society.isValidMangoPayAccount || !this.isDistributor;
        }

        if (this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])) {

            return true;
        }

        return false;
    }

    get isAllowOnlineSaleEnabled(): boolean {

        if(this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])){

            if(!this.hasAccessOnlineSale){

                return false;
            }

            if (this.hasRole('ROLE_OFFER_DISTRIBUTOR') && !(this.society.isValidMangoPayAccount)) {

                return false;
            }

            if(!this.form.get('isDistributor').value && this.form.get('allowBooking').value){

                return false;
            }
        }

        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            if(!this.form.get('isDistributor').value && this.form.get('allowBooking').value){

                return false;
            }
        }

        return true;
    }

    get subDomainSuffix(): string {

        return environment.channelSubDomainSuffix;
    }

    get isActivationAllowed(): boolean {

        if(!this.society){

            return false;
        }

        if(this.hasAccessUnlimitedChannel){

            return true;
        }

        return this.society.countAllowedChannels > this.society.countEnableChannels;
    }

    get mode(): ChannelMode {

        return this._activatedRoute.snapshot.data['mode'];
    }

    get isClassicMode(): boolean {

        return this.mode === 'classic';
    }

    get isRestrictedMode(): boolean {

        return this.mode === 'restricted';
    }

    get fontFieldConfig(): SelectSearchConfig {

        return this.fieldCollection.getFieldConfig('font') as SelectSearchConfig;
    }

    get marketplace(): string {

        const societyService: SocietyWebsite = (this.form.get('marketplace').value as SocietyWebsite);

        return societyService ? societyService.website : null;
    }

    get imageConfig(): ImageConfig {
        return {
            id: 'image',
            gallery_context: 'channel_landscape',
            required: false,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false
            }
        }
    }

    get logoConfig(): ImageConfig {
        return {
            id: 'image',
            gallery_context: 'channel_logo',
            required: false,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false
            }
        }
    }

    private _initFaviconConfigs(): void {
        this._translateService.get('file.extension.list.allowed.value', {list: '.ico'}).subscribe((help: string): void => {

            this.faviconConfig = {
                id: 'favicon',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'channel_favicon'
                },
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                help: help,
                upload: {
                    maxSize : 100000,
                    allowedTypes: ['image/x-icon'].join(',')
                }
            };
        });
    }
}
