<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'form.user.title.modification.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'user.list.back.value' | translate }}
                </button>

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>

    <!-- Gestion des onglets (une fois le rôle choisi) -->

    <mat-tab-group #tabGroup>

        <ng-container *ngFor="let tabItem of tabItems">

            <mat-tab>
                <ng-template mat-tab-label>
                    <span>{{ tabItem.label | translate }}</span>
                    <span [ngClass]="offerFormTabValidationService.getTabLabelClasses(tabItem.tag)"></span>
                </ng-template>
                <ng-container *ngTemplateOutlet="tabItem.template"></ng-container>
            </mat-tab>

        </ng-container>

    </mat-tab-group>

</form>

<!-- Saisie des informations entreprise associées au prestataire -->

<ng-template #societyInformations>

    <ng-container [formGroup]="form">

        <div formGroupName="society">

            <p>
                <strong>{{'form.user.createdAt.value' | translate}} : </strong>
                {{createdAt | date : 'dd/MM/yyyy' : null: localeId }}
            </p>

            <!--    Dernière connexion de tous les comptes confondus  -->
            <p>
                <ng-container *ngIf="society.lastLoginAt; else noLastLoginAt">
                    <strong>{{ 'user.lastLoginAt.value' | translate }} : </strong>
                    {{society.lastLoginAt | date: 'dd/MM/yyyy - HH:mm:ss'}}
                </ng-container>
                <ng-template #noLastLoginAt>
                    <strong>{{ 'user.lastLoginAt.value' | translate }} : </strong>
                    {{'none' | translate}}
                </ng-template>
            </p>

            <!--    Nombre de connexions des users de la société  -->

            <p>
                <strong>{{ 'user.nbrOfConnection.value' | translate }} : </strong>
                {{society.totalNbrOfConnections}}
            </p>

            <ng-container *ngIf="userHasOneOfRole(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) && hasRole('ROLE_PROVIDER')">

                <p>
                    <strong>{{ 'provider.reference.value' | translate }} :</strong>
                    {{ societyProvider }}
                </p>

            </ng-container>

            <p> <!--    Nombre de licences  -->
                <strong>{{ 'user.list.licences.value' | translate }} : </strong>
                {{ society.countPublishedOffers }}
            </p>

            <p>   <!--Nombres offres dans le catalogue -->
                <strong>{{ 'user.list.countOffers.value' | translate }} : </strong>
                {{ society.countCatalogOffers }}
            </p>

            <p> <!--Nombres d'offres restreintes -->
                <strong>{{ 'user.list.countOffersRestricted.value' | translate }} : </strong>
                {{ society.countRestrictedOffers }}
            </p>

            <p> <!--Nombres d'offres en brouillon -->
                <strong>{{ 'user.list.countOffersUnpublished.value' | translate }} : </strong>
                {{ society.countDraftOffers }}
            </p>

            <p> <!--Nombres de canaux crées  -->
                <strong>{{ 'user.list.countChannels.value' | translate }} : </strong>
                {{ society.countEnableChannels }}
            </p>

            <p> <!--Nombres de collaborateurs -->
                <strong>{{ 'user.list.countCollaborators.value' | translate }} : </strong>
                {{ society.countCollaborators }}
            </p>

            <p> <!-- Nombre d'offres dans la marketplace  -->
                <strong>{{ 'user.list.marketplace.value' | translate }} : </strong>
                {{ society.totalMarketplaceOffers }}
            </p>

            <div class="form_part">
                <p class="title_part">{{ 'form.user.title.societyInformations.society.value' | translate }}</p>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12 col_md_6">
                    <!-- Nom société -->
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="name" id="nameSociety" required="required">
                        <label for="nameSociety">{{'form.user.fields.name.value' | translate}}</label>
                        <mat-error *ngIf="societyForm.get('name').errors && (societyForm.get('name').dirty || societyForm.get('name').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
                <div class="col_12 col_md_6">
                    <!-- Site web -->
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="mainWebsite" id="mainWebsite" required="required">
                        <label for="mainWebsite">{{'form.user.fields.website.value' | translate}}</label>
                        <mat-error *ngIf="societyForm.get('mainWebsite').errors && (societyForm.get('mainWebsite').dirty || societyForm.get('mainWebsite').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('mainWebsite').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="societyForm.get('mainWebsite').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">

                <div class="col_12 col_md_6">

                    <!-- Locale -->

                    <div class="form-group">
                        <select class="form-control" id="locale" formControlName="locale" required="required">
                            <option value=""></option>
                            <option *ngFor="let locale of locales$ | async" [value]="locale.id">
                                {{ locale.label | translate }}
                            </option>
                        </select>
                        <label class="required" for="locale">{{ 'form.user.fields.society.locale.value' | translate }}</label>
                        <mat-error *ngIf="societyForm.get('locale').errors && (societyForm.get('locale').dirty || societyForm.get('locale').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('locale').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

                <div class="col_12 col_md_6">

                    <!-- Type -->

                    <div class="form-group">
                        <select class="form-control" id="type" formControlName="type" required="required">
                            <option value=""></option>
                            <option *ngFor="let type of types$ | async" [value]="type.id">
                                {{ type.label | translate }}
                            </option>
                        </select>
                        <label class="required" for="type">{{ 'form.user.fields.society.type.value' | translate }}</label>
                        <mat-error *ngIf="societyForm.get('type').errors && (societyForm.get('type').dirty || societyForm.get('type').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('type').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12 col_md_6">

                    <!-- Raison sociale -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="corporateName" id="corporateName" required="required">
                        <label for="corporateName">{{'form.user.fields.corporateName.value' | translate}}</label>
                        <mat-error
                                *ngIf="societyForm.get('corporateName').errors && (societyForm.get('corporateName').dirty || societyForm.get('corporateName').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('corporateName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

                <div class="col_12 col_md_6">

                    <!-- Immatriculation -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="registration" id="immatriculation" [required]="hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])">
                        <label for="immatriculation">{{'form.user.fields.immatriculation.value' | translate}}</label>
                        <mat-error *ngIf="societyForm.get('registration').errors && (societyForm.get('registration').dirty || societyForm.get('registration').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('registration').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                        <span *ngIf="hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])" class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon> {{ 'form.user.fields.immatriculation.help.value' | translate }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12 col_md_6">

                    <!-- Code APE -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="businessActivityCode" id="businessActivityCode" >
                        <label for="businessActivityCode">{{'form.user.fields.businessActivityCode.value' | translate}}</label>
                        <mat-error
                                *ngIf="societyForm.get('businessActivityCode').errors && (societyForm.get('businessActivityCode').dirty || societyForm.get('businessActivityCode').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('businessActivityCode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

                <div class="col_12 col_md_6">

                    <!-- Capital -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="capital" id="capital">
                        <label for="capital">{{'form.user.fields.capital.value' | translate}}</label>
                        <mat-error
                                *ngIf="societyForm.get('capital').errors && (societyForm.get('capital').dirty || societyForm.get('capital').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('capital').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">

                <div class="col_12 col_md_6">

                    <!-- Siren -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="identificationNumber" id="siren" required="required">
                        <label for="siren">{{'form.user.fields.siren.value' | translate}}</label>
                        <mat-error *ngIf="societyForm.get('identificationNumber').errors && (societyForm.get('identificationNumber').dirty || societyForm.get('identificationNumber').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('identificationNumber').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

                <div class="col_12 col_md_6">

                    <!-- immatriculation RCS  -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="rcsRegistration" id="rcsRegistration">
                        <label for="rcsRegistration">{{'form.user.fields.rcsRegistration.value' | translate}}</label>
                        <mat-error
                                *ngIf="societyForm.get('rcsRegistration').errors && (societyForm.get('rcsRegistration').dirty || societyForm.get('rcsRegistration').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('rcsRegistration').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form" *ngIf="hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])">

                <div class="col_12 col_md_6">

                    <!-- Garantie financière  -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="financialGuarantee" id="financialGuarantee" required="required">
                        <label for="financialGuarantee">{{'form.user.fields.financialGuarantee.value' | translate}}</label>
                        <mat-error *ngIf="societyForm.get('financialGuarantee').errors && (societyForm.get('financialGuarantee').dirty || societyForm.get('financialGuarantee').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('financialGuarantee').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon> {{ 'form.user.fields.financialGuarantee.help.value' | translate }}
                        </span>
                    </div>
                </div>

                <div class="col_12 col_md_6">

                    <!-- Assurance responsabilité civile professionnelle -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="insurancePolicy" id="insurancePolicy" required="required">
                        <label for="insurancePolicy">{{'form.user.fields.insurancePolicy.value' | translate}}</label>
                        <mat-error *ngIf="societyForm.get('insurancePolicy').errors && (societyForm.get('insurancePolicy').dirty || societyForm.get('insurancePolicy').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('insurancePolicy').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon> {{ 'form.user.fields.insurancePolicy.help.value' | translate }}
                        </span>
                    </div>
                </div>

            </div>

            <div class="row marges ligne_form">

                <div class="col_12 col_md_6">

                    <!-- Numéro de TVA -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="vatNumber" id="vatNumber">
                        <label for="vatNumber">{{'form.user.fields.vatNumber.value' | translate}}</label>
                        <mat-error *ngIf="societyForm.get('vatNumber').errors && (societyForm.get('vatNumber').dirty || societyForm.get('vatNumber').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('vatNumber').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon>
                            {{ 'form.user.fields.vatNumber.help.value' | translate }}
                        </span>
                    </div>
                </div>

            </div>

            <!-- Adresses -->

            <ng-container formArrayName="addresses">

                <div class="row marges ligne_form" *ngIf="isAddressToDisplay('mailing')">

                    <ng-container *ngTemplateOutlet="formAddress;context:{controlIndex: getAddressControlIndexByType('mailing')}"></ng-container>

                </div>

                <ng-container *ngIf="hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" id="differentBillingAddress" [(ngModel)]="differentBillingAddress" [ngModelOptions]="{standalone: true}" (ngModelChange)="resetAddresses()">
                                    <label for="differentBillingAddress">{{ 'bill.address.different.question.value' | translate }}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <div class="row marges ligne_form" *ngIf="isAddressToDisplay('billing')">

                    <ng-container *ngTemplateOutlet="formAddress;context:{controlIndex: getAddressControlIndexByType('billing')}"></ng-container>

                </div>

            </ng-container>


            <div class="row marges ligne_form">
                <div class="col_12 col_md_6">
                    <!-- Email générique (pour les échanges clients) -->
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="email" id="email" required="required">
                        <label for="email">{{'form.user.fields.email.society.value' | translate}}</label>
                        <mat-error *ngIf="societyForm.get('email').errors && (societyForm.get('email').dirty || societyForm.get('email').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="societyForm.get('email').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
                <div class="col_12 col_md_6">
                    <!-- Téléphone générique (pour les échanges clients) -->
                    <div class="form-group">

                        <app-core-intl-tel-input [formGroup]="societyForm" [controlName]="'phone'" customPlaceholder="{{'form.user.fields.phone.value' | translate}}"></app-core-intl-tel-input>

                        <mat-error *ngIf="societyForm.get('phone').errors && (societyForm.get('phone').dirty || societyForm.get('phone').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('phone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="societyForm.get('phone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <!-- Email de facturation différent -->

            <ng-container >

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" id="differentBillingEmail" [(ngModel)]="differentBillingEmail" [ngModelOptions]="{standalone: true}" (ngModelChange)="societyForm.get('billingEmail').patchValue(differentBillingEmail ? '' : societyForm.get('email').value)">
                                <label for="differentBillingEmail">{{ 'form.user.fields.emailFacturation.title.value' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="differentBillingEmail">

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Email de facturation -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="billingEmail" id="billingEmail" >
                            <label for="billingEmail">{{'form.user.fields.emailFacturation.value' | translate}}</label>
                            <mat-error *ngIf="societyForm.get('billingEmail').errors && (societyForm.get('billingEmail').dirty || societyForm.get('billingEmail').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('billingEmail').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="societyForm.get('billingEmail').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

            </ng-container>

            <ng-template #formAddress let-i="controlIndex">

                <ng-container [formGroup]="form">

                    <ng-container formGroupName="society">

                        <ng-container formArrayName="addresses">

                            <ng-container [formGroupName]="indexAsString(i)">

                                <div class="col_12">

                                    <div class="row marges ligne_form">
                                        <div class="col_12">
                                            <div class="form-group">
                                                <label>{{ getAddressTypeLabel(getAddressControl(i).get('type').value) }}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row marges ligne_form">
                                        <div class="col_6 y_items_center">
                                            <app-core-google-places-location [fields]="getAddressLocationFields(getAddressControl(i))" (onSelection)="selectAddress(i)"></app-core-google-places-location>
                                        </div>
                                    </div>

                                    <div class="row marges ligne_form">
                                        <div class="col_12 col_md_6">

                                            <!-- Adresse -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="address" required="required">
                                                <label for="address">{{'form.user.fields.address.value' | translate}}</label>
                                                <mat-error
                                                        *ngIf="getAddressControl(i).get('address').errors && (getAddressControl(i).get('address').dirty || getAddressControl(i).get('address').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="getAddressControl(i).get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div class="col_12 col_md_6">

                                            <!-- Complément d'adresse -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalAddress" id="additionalAddress">
                                                <label for="additionalAddress">{{'form.user.fields.additionalAddress.value' | translate}}</label>
                                                <mat-error
                                                        *ngIf="getAddressControl(i).get('additionalAddress').errors && (getAddressControl(i).get('additionalAddress').dirty || getAddressControl(i).get('additionalAddress').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="getAddressControl(i).get('additionalAddress').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row marges ligne_form">
                                        <div class="col_12 col_md_6">

                                            <!-- Code postal -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="zipcode" required="required">
                                                <label for="zipcode">{{'form.user.fields.zipcode.value' | translate}}</label>
                                                <mat-error
                                                        *ngIf="getAddressControl(i).get('zipcode').errors && (getAddressControl(i).get('zipcode').dirty || getAddressControl(i).get('zipcode').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="getAddressControl(i).get('zipcode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        <div *ngIf="getAddressControl(i).get('zipcode').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col_12 col_md_6">

                                            <!-- Ville -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="city" required="required">
                                                <label for="city">{{'form.user.fields.city.value' | translate}}</label>
                                                <mat-error
                                                        *ngIf="getAddressControl(i).get('city').errors && (getAddressControl(i).get('city').dirty || getAddressControl(i).get('city').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="getAddressControl(i).get('city').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row marges ligne_form">
                                        <div class="col_12 col_md_6">

                                            <!-- Région -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="region" id="region" required="required">
                                                <label for="region">{{'form.user.fields.region.value' | translate}}</label>
                                                <mat-error
                                                        *ngIf="getAddressControl(i).get('region').errors && (getAddressControl(i).get('region').dirty || getAddressControl(i).get('city').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="getAddressControl(i).get('region').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div class="col_12 col_md_6">

                                            <!-- Pays -->

                                            <div class="form-group">
                                                <select class="form-control" id="country" formControlName="country" required="required">
                                                    <option *ngFor="let country of countries$ | async" [value]="country.code">{{ translationService.getFallbackTranslation(country.translations).name }}</option>
                                                </select>
                                                <label class="required"
                                                       for="country">{{ 'form.user.fields.country.value' | translate }}</label>
                                                <mat-error
                                                        *ngIf="getAddressControl(i).get('country').invalid && (getAddressControl(i).get('country').dirty || getAddressControl(i).get('country').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="getAddressControl(i).get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </ng-container>

                        </ng-container>

                    </ng-container>

                </ng-container>

            </ng-template>

            <div class="row marges ligne_form">

                <div class="form_part col_12">
                    <p class="title_part">{{ 'form.user.title.societyInformations.logo.value' | translate }}</p>
                </div>

                <div class="col_6">
                    <!-- Logo -->
                    <div class="form-group">
                        <label class="required">{{ 'file.add.action.logo.value' | translate }}</label>
                        <form-field-file [config]="societyLogoConfig" [form]="societyForm"></form-field-file>
                        <mat-error *ngIf="societyForm.get('logo').invalid">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('logo').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
                <div class="col_6">
                    <!-- Logo PIM -->
                    <div class="form-group">
                        <label class="required">{{ 'file.add.action.logo.administration.value' | translate }}</label>
                        <form-field-file [config]="societyAdministrationLogoConfig" [form]="societyForm"></form-field-file>
                        <mat-error *ngIf="societyForm.get('administrationLogo').invalid">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('administrationLogo').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="hasRole('ROLE_OFFER_CREATOR')">

                <div class="row marges ligne_form">
                    <div class="form_part col_12">
                        <p class="title_part">{{ 'form.user.title.societyInformations.information.value' | translate }}</p>
                    </div>
                </div>

                <ng-container formGroupName="information">

                    <div class="row marges ligne_form">

                        <div class="col_12">

                            <div class="mat-tab-translations">

                                <ng-container formArrayName="translations">

                                    <mat-tab-group [@.disabled]="true">

                                        <div *ngFor="let _ of societyInformationTranslationBuilder.itemsControl.controls; let it = index">

                                            <mat-tab label="{{ societyInformationTranslationBuilder.getLocaleLabel(societyInformationTranslationBuilder.getItemControl(it).value.locale) | translate }}">

                                                <ng-container [formGroupName]="societyInformationTranslationBuilder.indexAsString(it)">

                                                    <div class="row marges ligne_form">
                                                        <div class="col_12">
                                                            <div class="form-group">
                                                                <label>{{ 'presentation.value' | translate }}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row marges ligne_form">
                                                        <div class="col_12">

                                                            <!-- Présentation -->

                                                            <div class="form-group">
                                                                <app-ckeditor [form]="societyInformationTranslationBuilder.getItemControlByLocale(societyInformationTranslationBuilder.getItemControl(it).value.locale)" [config]="presentationEditorConfig"></app-ckeditor>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </ng-container>

                                            </mat-tab>

                                        </div>

                                    </mat-tab-group>

                                </ng-container>

                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_6">

                            <!-- Langues parlées -->

                            <div class="form-group">
                                <mat-select formControlName="spokenLanguages" multiple>
                                    <mat-option *ngFor="let language of societySpokenLanguages;" [value]="language">{{ ('locale.' + language + '.value') | translate }}</mat-option>
                                    <mat-option [value]="otherSpokenLanguagesIdentifier">{{ 'other.value' | translate }}</mat-option>
                                </mat-select>
                                <mat-label>{{ 'language.plural.spoken.value' | translate }}</mat-label>
                                <mat-error *ngIf="societyInformationForm.get('spokenLanguages').errors && (societyInformationForm.get('spokenLanguages').dirty || societyInformationForm.get('spokenLanguages').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="societyInformationForm.get('spokenLanguages').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form" *ngIf="societyInformationForm.get('spokenLanguages').value.includes(otherSpokenLanguagesIdentifier)">

                        <div class="col_12">

                            <div class="mat-tab-translations">

                                <ng-container formArrayName="translations">

                                    <mat-tab-group [@.disabled]="true">

                                        <div *ngFor="let _ of societyInformationTranslationBuilder.itemsControl.controls; let it = index">

                                            <mat-tab label="{{ societyInformationTranslationBuilder.getLocaleLabel(societyInformationTranslationBuilder.getItemControl(it).value.locale) | translate }}">

                                                <ng-container [formGroupName]="societyInformationTranslationBuilder.indexAsString(it)">

                                                    <div class="row marges ligne_form">

                                                            <div class="col_12">

                                                                <!-- Langues parlées -->

                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="spokenLanguages" id="translationSpokenLanguages">
                                                                    <label for="translationSpokenLanguages">{{ 'language.plural.other.value' | translate }}</label>
                                                                    <mat-error *ngIf="societyInformationTranslationBuilder.getItemControl(it).get('spokenLanguages').invalid && (societyInformationTranslationBuilder.getItemControl(it).get('spokenLanguages').dirty || societyInformationTranslationBuilder.getItemControl(it).get('spokenLanguages').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="societyInformationTranslationBuilder.getItemControl(it).get('spokenLanguages').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                        </div>

                                                </ng-container>

                                            </mat-tab>

                                        </div>

                                    </mat-tab-group>

                                </ng-container>

                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_12">

                            <!-- Labellisé RSE -->

                            <div class="form-group">
                                <label>{{'rse.labelled.value' | translate}}</label>
                                <div class="radio">
                                    <input type="radio" [value]="false" formControlName="hasRseLabel" class="form-control" id="hasRseLabelNo">
                                    <label for="hasRseLabelNo">{{'form.user.fields.no.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" [value]="true" formControlName="hasRseLabel" class="form-control" id="hasRseLabelYes">
                                    <label for="hasRseLabelYes">{{'form.user.fields.yes.value' | translate}}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form" *ngIf="societyInformationForm.get('hasRseLabel').value">

                        <div class="col_12">

                            <div class="mat-tab-translations">

                                <ng-container formArrayName="translations">

                                    <mat-tab-group [@.disabled]="true">

                                        <div *ngFor="let _ of societyInformationTranslationBuilder.itemsControl.controls; let it = index">

                                            <mat-tab label="{{ societyInformationTranslationBuilder.getLocaleLabel(societyInformationTranslationBuilder.getItemControl(it).value.locale) | translate }}">

                                                <ng-container [formGroupName]="societyInformationTranslationBuilder.indexAsString(it)">

                                                    <div class="row marges ligne_form">

                                                            <div class="col_12">

                                                                <!-- Label RSE -->

                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="rseLabel" id="rseLabel">
                                                                    <label for="rseLabel">{{ 'rseLabel.value' | translate }}</label>
                                                                    <mat-error *ngIf="societyInformationTranslationBuilder.getItemControl(it).get('rseLabel').errors && (societyInformationTranslationBuilder.getItemControl(it).get('rseLabel').dirty || societyInformationTranslationBuilder.getItemControl(it).get('rseLabel').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="societyInformationTranslationBuilder.getItemControl(it).get('rseLabel').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                        </div>

                                                </ng-container>

                                            </mat-tab>

                                        </div>

                                    </mat-tab-group>

                                </ng-container>

                            </div>

                        </div>

                    </div>

                    <ng-container *ngIf="society?.networkOfferCreators.length">

                        <div class="row marges ligne_form">
                            <div class="col_12">

                                <!-- Réseaux de créateurs d'offres -->

                                <div class="form-group">
                                    <label>{{ 'network.offerCreator.list.title.value' | translate }}</label>
                                    <br>
                                    <span>{{ parsedNetworkOfferCreatorNames }}</span>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <div class="row marges ligne_form" style="align-items: center;">

                        <div class="col_6">

                            <!-- Cibles clientèle -->

                            <div class="form-group">
                                <mat-select multiple [compareWith]="compareById" formControlName="customerTypologies">
                                    <mat-option *ngFor="let customerTypology of customerTypologies" [value]="customerTypology">
                                        {{ translationService.getFallbackTranslation(customerTypology.translations).name }}
                                    </mat-option>
                                </mat-select>
                                <mat-label>{{ 'customer.target.plural.value' | translate }}</mat-label>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_6">

                            <!-- Destinations -->

                            <div class="form-group">
                                <mat-select multiple [compareWith]="compareById" formControlName="regions">
                                    <mat-option #allRegionsOption [value]="{ id: null }" (click)="handleRegion(allRegionsOption)"><span style="font-weight: bold">{{ 'france.all.value' | translate }}</span></mat-option>
                                    <mat-option #regionOption *ngFor="let region of regions" [value]="region" (click)="handleRegion(regionOption)">
                                        {{ translationService.getFallbackTranslation(region.translations).label }}
                                    </mat-option>
                                </mat-select>
                                <mat-label>{{ 'destination.plural.value' | translate }}</mat-label>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_6">

                            <!-- Types d’offre -->

                            <div class="form-group">
                                <mat-select multiple formControlName="durationTypes">
                                    <mat-option *ngFor="let type of durationTypes" [value]="type">
                                        {{ ('offer.type.' + type + '.value') | translate }}
                                    </mat-option>
                                </mat-select>
                                <mat-label>{{ 'offer.plural.type.plural.value' | translate }}</mat-label>
                            </div>

                        </div>

                    </div>

                </ng-container>

            </ng-container>

            <!-- Documents CGV -->

            <ng-container *ngIf="hasOneOfThisRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])">
                <ng-container *ngTemplateOutlet="formTermsAndConditions"></ng-container>
            </ng-container>
        </div>

    </ng-container>

</ng-template>

<!-- Données licence créateur d’offres -->

<ng-template #licence>
    <div class="ligne_form">
        <subscription-form *ngIf="subscriptions.length && subscriptionsSociety.length" [subscriptions]="subscriptions" [data]="subscriptionsSociety" [society]="society" [isDisabled]="true" [redirectionToSubscriptionViewEnabled]="false"></subscription-form>
    </div>
</ng-template>

<!-- Données contact -->

<ng-template #contactInformations>

    <ng-container [formGroup]="form">

        <div formGroupName="society">

            <div formGroupName="admin">

                <ng-container *ngIf="hasOneOfThisRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER'])">

                    <!-- Admin du compte -->

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{'form.user.fields.admin.value' | translate}}</label>
                                <div class="radio">
                                    <input type="radio" [value]="true" [(ngModel)]="isAccountAdmin" [ngModelOptions]="{standalone: true}" class="form-control" id="isAdminYes" (click)="patchSocietyAdmin(adminForm.get('society').value)">
                                    <label for="isAdminYes">{{'form.user.fields.yes.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" [value]="false" [(ngModel)]="isAccountAdmin" [ngModelOptions]="{standalone: true}" class="form-control" id="isAdminNo" (click)="patchSocietyAdmin(null)">
                                    <label for="isAdminNo">{{'form.user.fields.no.value' | translate}}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <div class="row marges ligne_form">
                    <div class="col_12">

                        <!-- Dernière connexion et nombre de connexion -->

                        <div class="form-group">
                            <label>{{ 'collaborator.connexion.history.value' | translate }}</label>
                            <ng-container *ngIf="society.admin.lastLoginAt !== null; else noLastLoginAt">
                            <div>{{ 'user.lastLoginAt.value' | translate }} : {{society.admin.lastLoginAt | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
                            </ng-container>
                            <ng-template #noLastLoginAt>
                                <div>{{ 'user.lastLoginAt.value' | translate }} : {{'none' | translate}}</div>
                            </ng-template>
                            <div>{{ 'user.nbrOfConnection.value' | translate }} : {{society.admin.nbrOfConnections}}</div>
                        </div>

                        <!-- Civilité -->

                        <div class="form-group">
                            <label>{{'form.user.fields.civility.title.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" [value]="1" formControlName="civility" class="form-control" id="civility-mister">
                                <label for="civility-mister">{{'form.user.fields.civility.mister.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="2" formControlName="civility" class="form-control" id="civility-madam">
                                <label for="civility-madam">{{'form.user.fields.civility.madam.value' | translate}}</label>
                            </div>

                            <mat-error
                                    *ngIf="adminForm.get('civility').errors && (adminForm.get('civility').dirty || adminForm.get('civility').touched)">
                                <div class="invalid-feedback">
                                    <div
                                            *ngIf="adminForm.get('civility').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row marges ligne_form">
                    <div class="col_12 col_md_4">

                        <!-- Nom -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="lastName" id="lastName" required="required">
                            <label for="lastName">{{'form.user.fields.lastname.value' | translate}}</label>
                            <mat-error *ngIf="adminForm.get('lastName').errors && (adminForm.get('lastName').dirty || adminForm.get('lastName').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('lastName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_4">

                        <!-- Prénom -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="firstName" id="firstName" required="required">
                            <label for="firstName">{{'form.user.fields.firstname.value' | translate}}</label>
                            <mat-error *ngIf="adminForm.get('firstName').errors && (adminForm.get('firstName').dirty || adminForm.get('firstName').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('firstName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_4">

                        <!-- Email -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="email" id="emailContact" required="required">
                            <label for="emailContact">{{'form.user.fields.email.contact.value' | translate}}</label>
                            <mat-error *ngIf="adminForm.get('email').errors && (adminForm.get('email').dirty || adminForm.get('email').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="adminForm.get('email').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row marges ligne_form">
                    <div class="col_12 col_md_4">

                        <!-- Téléphone fixe -->

                        <div class="form-group">

                            <app-core-intl-tel-input [formGroup]="adminForm" [controlName]="'switchboardPhone'" customPlaceholder="{{'form.user.fields.switchboardPhone.value' | translate}}"></app-core-intl-tel-input>

                            <mat-error *ngIf="adminForm.get('switchboardPhone').errors && (adminForm.get('switchboardPhone').dirty || adminForm.get('switchboardPhone').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('switchboardPhone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="adminForm.get('switchboardPhone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_4">

                        <!-- Ligne directe -->

                        <div class="form-group">

                            <app-core-intl-tel-input [formGroup]="adminForm" [controlName]="'directPhone'" customPlaceholder="{{'form.user.fields.directPhone.value' | translate}}"></app-core-intl-tel-input>

                            <mat-error *ngIf="adminForm.get('directPhone').errors && (adminForm.get('directPhone').dirty || adminForm.get('directPhone').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('directPhone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="adminForm.get('directPhone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_4">

                        <!-- Portable -->

                        <div class="form-group">

                            <app-core-intl-tel-input [formGroup]="adminForm" [controlName]="'cellphone'" customPlaceholder="{{'form.user.fields.cellphone.value' | translate}}"></app-core-intl-tel-input>

                            <mat-error *ngIf="adminForm.get('cellphone').errors && (adminForm.get('cellphone').dirty || adminForm.get('cellphone').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('cellphone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="adminForm.get('cellphone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row marges ligne_form">
                    <div class="col_12 col_md_4">
                        <div class="form-group">
                            <app-core-timezone-select [form]="adminForm" [required]="true"></app-core-timezone-select>
                        </div>
                    </div>

                    <div class="col_12 col_md_4">

                        <!-- Service -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="service" id="service">
                            <label for="service">{{'form.user.fields.service.value' | translate}}</label>
                            <mat-error *ngIf="adminForm.get('service').errors &&(adminForm.get('service').dirty || adminForm.get('service').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('service').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_4">

                        <!-- Locale -->

                        <div class="form-group">
                            <select class="form-control" id="adminLocale" formControlName="locale" required="required">
                                <option value=""></option>
                                <option *ngFor="let locale of locales$ | async" [value]="locale.id">
                                    {{ locale.label | translate }}
                                </option>
                            </select>
                            <label class="required" for="locale">{{ 'form.user.fields.locale.value' | translate }}</label>
                            <mat-error *ngIf="adminForm.get('locale').errors && (adminForm.get('locale').dirty || adminForm.get('locale').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('locale').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </div>
                <div class="row marges ligne_form">
                    <div class="col_12">

                        <!-- Commentaire -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="comment" id="comment">
                            <label for="comment">{{'form.user.fields.comment.value' | translate}}</label>
                            <mat-error *ngIf="adminForm.get('comment').errors &&(adminForm.get('comment').dirty || adminForm.get('comment').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('comment').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <ng-container *ngTemplateOutlet="resetPassword"></ng-container>

            </div>

        </div>

    </ng-container>

</ng-template>

<!-- Supression de l'utilisateur -->

<ng-template #deactivateAccount>

    <ng-container [ngSwitch]="true">

        <!-- Rôle Créateur d'offre & Distributeur d'offre -->

        <div *ngSwitchCase="hasRole('ROLE_OFFER_CREATOR') && hasRole('ROLE_OFFER_DISTRIBUTOR')">
            <button type="button" class="btn_cta btn_little" (click)="desactivateAccountDialog()">
                {{'subscription.form.delete.title.value' | translate }}
            </button>
        </div>

        <!-- Rôle Créateur d'offre -->

        <div *ngSwitchCase="hasRole('ROLE_OFFER_CREATOR') && !hasRole('ROLE_OFFER_DISTRIBUTOR')">
            <button type="button" class="btn_cta btn_little" (click)="desactivateAccountDialog()">
                {{'subscription.form.delete.title.value' | translate }}
            </button>
        </div>

        <!-- Rôle Distributeur d'offre -->

        <div *ngSwitchCase="hasRole('ROLE_OFFER_DISTRIBUTOR') && !hasRole('ROLE_OFFER_CREATOR')">
            <button type="button" class="btn_cta btn_little" (click)="desactivateAccountDialog()">
                {{'subscription.form.delete.title.value' | translate }}
            </button>
        </div>

        <!-- Rôle Prestataire -->

        <div *ngSwitchCase="hasRole('ROLE_PROVIDER')">
            <button type="button" class="btn_cta btn_little" (click)="desactivateAccountDialog()">
                {{'subscription.form.delete.title.value' | translate }}
            </button>
        </div>

        <!-- Rôle Institutionnel -->

        <div *ngSwitchCase="hasRole('ROLE_INSTITUTIONAL')">
            <button type="button" class="btn_cta btn_little" (click)="desactivateAccountDialog()">
                {{'subscription.form.delete.title.value' | translate }}
            </button>
        </div>

        <!-- Rôle Fédération -->

        <div *ngSwitchCase="hasRole('ROLE_FEDERATION')">
            <button type="button" class="btn_cta btn_little" (click)="desactivateAccountDialog()">
                {{'subscription.form.delete.title.value' | translate }}
            </button>
        </div>

    </ng-container>

</ng-template>

<!-- Renouvellement du mot de passe -->

<ng-template #resetPassword>

    <div class="row marges ligne_form">
        <div class="col_12">
            <button type="button" class="btn_cta" (click)="resetPasswordDialog()">
                {{ 'user.password.reset.action.value' | translate }}
            </button>
        </div>
    </div>

</ng-template>

<!-- Administration du compte distributeur -->

<ng-template #distributorAdministrationInformations>

    <ng-container [formGroup]="form">

        <!-- Gestion des commissions -->

        <ng-container formGroupName="society" *ngIf="isAvailableCommission">

            <div class="form_part">
                <p class="title_part">{{ 'form.user.administration.title.commission.value' | translate }}</p>
            </div>

            <ng-container formGroupName="commission">

                <div class="row marges ligne_form">

                    <div class="col_12">
                        <div class="form-group">
                            <label>{{ 'form.user.fields.commission.type.title.value' | translate }}</label>
                        </div>
                    </div>

                    <div class="col_12">
                        <div class="form-group">
                            <div class="radio">
                                <input type="radio" [value]="'standard'" formControlName="type" class="form-control" id="commissionTypeStandard">
                                <label for="commissionTypeStandard">{{'form.user.fields.commission.type.standard.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="'custom'" formControlName="type" class="form-control" id="commissionTypeCustom">
                                <label for="commissionTypeCustom">{{'form.user.fields.commission.type.custom.value' | translate}}</label>
                            </div>
                            <div class="form-group col_content y_items_center" *ngIf="societyForm.get('commission').get('type').value === 'custom'">
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>
                                    {{ 'form.user.fields.commission.type.info.value' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col_12 row marges ligne_form">

                    <div class="col_4">
                        <div class="form-group">
                            <input type="text" placeholder=" " class="form-control" formControlName="individualStay" id="commissionIndividualStay" [attr.disabled]="societyForm.get('commission').get('type').value === 'standard' ? '' : null">
                            <label for="commissionIndividualStay">{{ 'form.user.fields.commission.individualStay.value' | translate }} :</label>
                            <mat-error *ngIf="societyForm.get('commission').get('individualStay').errors && (societyForm.get('commission').get('individualStay').dirty || societyForm.get('commission').get('individualStay').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('commission').get('individualStay').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="societyForm.get('commission').get('individualStay').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_4">
                        <div class="form-group">
                            <input type="text" placeholder=" " class="form-control" formControlName="individualDay" id="commissionIndividualDay" [attr.disabled]="societyForm.get('commission').get('type').value === 'standard' ? '' : null">
                            <label for="commissionIndividualDay">{{ 'form.user.fields.commission.individualDay.value' | translate }} :</label>
                            <mat-error *ngIf="societyForm.get('commission').get('individualDay').errors && (societyForm.get('commission').get('individualDay').dirty || societyForm.get('commission').get('individualDay').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('commission').get('individualDay').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="societyForm.get('commission').get('individualDay').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </div>

                <div class="col_12 row marges ligne_form">

                    <div class="col_4">
                        <div class="form-group">
                            <input type="text" placeholder=" " class="form-control" formControlName="groupStay" id="commissionGroupStay" [attr.disabled]="societyForm.get('commission').get('type').value === 'standard' ? '' : null">
                            <label for="commissionGroupStay">{{ 'form.user.fields.commission.groupStay.value' | translate }} :</label>
                            <mat-error *ngIf="societyForm.get('commission').get('groupStay').errors && (societyForm.get('commission').get('groupStay').dirty || societyForm.get('commission').get('groupStay').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('commission').get('groupStay').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="societyForm.get('commission').get('groupStay').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_4">
                        <div class="form-group">
                            <input type="text" placeholder=" " class="form-control" formControlName="groupDay" id="commissionGroupDay" [attr.disabled]="societyForm.get('commission').get('type').value === 'standard' ? '' : null">
                            <label for="commissionGroupDay">{{ 'form.user.fields.commission.groupDay.value' | translate }} :</label>
                            <mat-error *ngIf="societyForm.get('commission').get('groupDay').errors && (societyForm.get('commission').get('groupDay').dirty || societyForm.get('commission').get('groupDay').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('commission').get('groupDay').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="societyForm.get('commission').get('groupDay').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </div>

                <div class="row marges ligne_form">

                    <div class="col_12">
                        <div class="form-group">
                            <label>{{ 'form.user.fields.commission.incomingPayment.value' | translate }}</label>
                            <div class="radio">
                                <input type="radio" [value]="true" formControlName="incomingPayment" class="form-control" id="incomingPaymentYes">
                                <label for="incomingPaymentYes">{{'form.user.fields.yes.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="false" formControlName="incomingPayment" class="form-control" id="incomingPaymentNo">
                                <label for="incomingPaymentNo">{{'form.user.fields.no.value' | translate}}</label>
                            </div>
                            <div class="form-group col_content y_items_center">
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>
                                    {{ ('form.user.fields.commission.incomingPayment.' + (societyForm.get('commission').get('incomingPayment').value ? 'yes' : 'no') + '.info.value') | translate }}
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

            </ng-container>

        </ng-container>

        <div class="row marges ligne_form">

            <div class="form_part col_12">
                <p class="title_part">{{ 'form.user.administration.title.channel.value' | translate }}</p>
            </div>

            <ng-container formGroupName="society">

                <!-- Autoriser l'utilisateur à utiliser l'API -->
                <div class="col_12">
                    <div class="form-group">
                        <label>{{'form.user.fields.hasAccessAPI.value' | translate}}</label>
                        <div class="radio">
                            <input type="radio" [value]="true" formControlName="hasAccessAPI" class="form-control" id="hasAccessAPIYes">
                            <label for="hasAccessAPIYes">{{'form.user.fields.yes.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="false" formControlName="hasAccessAPI" class="form-control" id="hasAccessAPINo">
                            <label for="hasAccessAPINo">{{'form.user.fields.no.value' | translate}}</label>
                        </div>
                    </div>
                </div>

            </ng-container>
        </div>

        <ng-container formGroupName="administration">

            <!-- Création d’iframe autorisée par l’utilisateur -->

            <div class="row marges ligne_form" *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">
                <div class="col_12">
                    <div class="form-group">
                        <label>{{'form.user.fields.iframe.creation.enabled.value' | translate}}</label>
                        <div class="radio">
                            <input type="radio" [value]="true" formControlName="iframeCreation" class="form-control" id="iframeCreationYes">
                            <label for="iframeCreationYes">{{'form.user.fields.yes.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="false" formControlName="iframeCreation" class="form-control" id="iframeCreationNo">
                            <label for="iframeCreationNo">{{'form.user.fields.no.value' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="administrationForm.get('iframeCreation').value">

            <ng-container formGroupName="society">

                <div class="row marges ligne_form">
                    <div class="col_4">
                        <div class="form-group">
                            <label>{{'form.user.fields.channel.showcase.creation.enabled.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" [value]="true" formControlName="hasAccessCreateChannelShowcase" class="form-control" id="hasAccessCreateChannelShowcaseYes">
                                <label for="hasAccessCreateChannelShowcaseYes">{{'form.user.fields.yes.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="false" formControlName="hasAccessCreateChannelShowcase" class="form-control" id="hasAccessCreateChannelShowcaseNo">
                                <label for="hasAccessCreateChannelShowcaseNo">{{'form.user.fields.no.value' | translate}}</label>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="societyForm.get('hasAccessCreateChannelShowcase').value">

                        <ng-container formGroupName="channelConfiguration">

                            <div class="col_8">

                                <div class="form-group">
                                    <label>{{'channel.showcase.modale.enable.value' | translate}}</label>
                                    <div class="radio">
                                        <input type="radio" [value]="true" formControlName="enableModale" class="form-control" id="enableModaleYes">
                                        <label for="enableModaleYes">{{'form.user.fields.yes.value' | translate}}</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" [value]="false" formControlName="enableModale" class="form-control" id="enableModaleNo">
                                        <label for="enableModaleNo">{{'form.user.fields.no.value' | translate}}</label>
                                    </div>
                                </div>

                            </div>

                        </ng-container>

                    </ng-container>
                </div>

                <ng-container formGroupName="channelConfiguration">

                    <ng-container *ngIf="societyForm.get('hasAccessCreateChannelShowcase').value && societyChannelConfigurationControl.get('enableModale').value">

                        <div class="row marges ligne_form" *ngIf="societyChannelConfigurationTranslationBuilder.itemsControl.length">

                            <div class="col_12">

                                <div class="mat-tab-translations">

                                    <ng-container formArrayName="translations">

                                        <mat-tab-group [@.disabled]="true">

                                            <div *ngFor="let _ of societyChannelConfigurationTranslationBuilder.itemsControl.controls; let it = index">

                                                <mat-tab label="{{societyChannelConfigurationTranslationBuilder.getLocaleLabel(societyChannelConfigurationTranslationBuilder.getItemControl(it).value.locale) | translate }}">

                                                    <ng-container [formGroupName]="societyChannelConfigurationTranslationBuilder.indexAsString(it)">

                                                        <div class="row marges ligne_form">

                                                            <div class="col_6">

                                                                <!-- Texte du bouton offre -->

                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="buttonContent" id="{{it}}-buttonContent">
                                                                    <label class="required" for="{{it}}-buttonContent">{{ 'channel.showcase.buttonContent.value' | translate }}</label>
                                                                    <mat-error *ngIf="societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('buttonContent').errors && (societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('buttonContent').dirty || societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('buttonContent').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('buttonContent').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>

                                                            <div class="col_6">

                                                                <!-- Titre modale -->

                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="modaleTitle" id="{{it}}-modaleTitle">
                                                                    <label class="required" for="{{it}}-modaleTitle">{{ 'channel.showcase.modaleTitle.value' | translate }}</label>
                                                                    <mat-error *ngIf="societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('modaleTitle').errors && (societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('modaleTitle').dirty || societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('modaleTitle').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('modaleTitle').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row marges ligne_form">
                                                            <div class="col_12">

                                                                <!-- Contenu modale -->

                                                                <div class="form-group">
                                                                    <label>{{ 'channel.showcase.modaleContent.value' | translate }}</label>
                                                                    <app-ckeditor [form]="societyChannelConfigurationTranslationBuilder.getItemControlByLocale(societyChannelConfigurationTranslationBuilder.getItemControl(it).value.locale)" [config]="societyChannelConfigurationModaleContentEditorConfig"></app-ckeditor>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row marges ligne_form">

                                                            <div class="col_6">

                                                                <!-- Texte du bouton modale -->

                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="redirectionButtonContent" id="{{it}}-redirectionButtonContent">
                                                                    <label for="{{it}}-redirectionButtonContent" [ngClass]="{ required: societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('redirectionButtonLink').value?.length > 0 }">{{ 'channel.showcase.redirectionButtonContent.value' | translate }}</label>
                                                                    <mat-error *ngIf="societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('redirectionButtonContent').errors && (societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('redirectionButtonContent').dirty || societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('redirectionButtonContent').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('redirectionButtonContent').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>

                                                            <div class="col_6">

                                                                <!-- Lien de redirection -->

                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="redirectionButtonLink" id="{{it}}-redirectionButtonLink">
                                                                    <label for="{{it}}-redirectionButtonLink" [ngClass]="{ required: societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('redirectionButtonContent').value?.length > 0 }">{{ 'channel.showcase.redirectionButtonLink.value' | translate }}</label>
                                                                    <mat-error *ngIf="societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('redirectionButtonLink').errors && (societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('redirectionButtonLink').dirty || societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('redirectionButtonLink').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="societyChannelConfigurationTranslationBuilder.itemsControl.at(it).get('redirectionButtonLink').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ng-container>

                                                </mat-tab>

                                            </div>

                                        </mat-tab-group>

                                    </ng-container>

                                </div>

                            </div>

                        </div>

                    </ng-container>

                </ng-container>

            </ng-container>

        </ng-container>

        <ng-container formGroupName="society">

            <!-- Restriction sur les réseaux de prestataires -->

            <div class="row marges ligne_form">

                <div class="form_part col_12">
                    <p class="title_part">{{ 'form.user.administration.title.accessRestriction.value' | translate }}</p>
                </div>

                <div class="col_4">
                    <div class="form-group">
                        <label>{{ 'form.user.fields.networkProvider.restriction.value' | translate }}</label>
                        <div class="radio">
                            <input type="radio" [value]="true" formControlName="hasRestrictedNetworkProvider" class="form-control" id="hasRestrictedNetworkProviderYes">
                            <label for="hasRestrictedNetworkProviderYes">{{'form.user.fields.yes.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="false" formControlName="hasRestrictedNetworkProvider" class="form-control" id="hasRestrictedNetworkProviderNo">
                            <label for="hasRestrictedNetworkProviderNo">{{'form.user.fields.no.value' | translate}}</label>
                        </div>
                    </div>

                </div>

                <div class="col_8" *ngIf="societyForm.get('hasRestrictedNetworkProvider').value">
                    <div class="form-group">

                        <mat-select formControlName="restrictedNetworkProviders" [multiple]="true">

                            <mat-optgroup *ngFor="let group of restrictedNetworkProviders" [label]="group.name" [ngClass]="['canCheckGroup']">

                                <input type="checkbox" class="mat-option-pseudo-checkbox mat-pseudo-checkbox" (click)="handleGroupNetworkProviderSelection(group.choices, $event)" #restrictedGroupNetworkProviderOptionCheckbox />

                                <span class="container_checkbox"></span>

                                <mat-option *ngFor="let choice of group.choices" [value]="choice.id" (onSelectionChange)="handleNetworkProviderSelection(restrictedGroupNetworkProviderOptionCheckbox, group.choices, $event)">

                                    {{choice.name}}

                                </mat-option>

                            </mat-optgroup>

                        </mat-select>
                        <mat-label>{{ 'form.user.fields.networkProvider.restriction.selection.action.value' | translate }}</mat-label>
                    </div>
                </div>
            </div>

        </ng-container>

        <!-- Restriction d’accès aux créateurs d’offres -->

        <div class="row marges ligne_form" *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">

            <ng-container formGroupName="administration">

                <div class="col_4">
                    <div class="form-group">
                        <label>{{'form.user.fields.offer.restriction.value' | translate}}</label>
                        <div class="radio">
                            <input type="radio" [value]="true" formControlName="offerRestriction" class="form-control" id="offerRestrictionYes">
                            <label for="offerRestrictionYes">{{'form.user.fields.yes.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="false" formControlName="offerRestriction" class="form-control" id="offerRestrictionNo">
                            <label for="offerRestrictionNo">{{'form.user.fields.no.value' | translate}}</label>
                        </div>
                    </div>

                </div>

            </ng-container>

            <!-- Liste des créateurs d'offre -->

            <ng-container formGroupName="society" *ngIf="administrationForm.get('offerRestriction').value">
                <div class="col_8">
                    <div class="form-group">
                        <mat-select formControlName="restrictedOfferCreators" multiple>
                            <mat-option *ngFor="let society of offerCreatorSocieties$ | async" [value]="society.id">
                                {{ society.name }}
                            </mat-option>
                        </mat-select>
                        <mat-label>{{ 'offerCreator.select.multiple.action.value' | translate }}</mat-label>
                    </div>
                </div>
            </ng-container>
        </div>


        <ng-container formGroupName="society">

            <!-- Restriction sur les réseaux de créateurs d'offres -->

            <div class="row marges ligne_form" *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">

                <div class="col_4">
                    <div class="form-group">
                        <label>{{ 'form.user.fields.networkOfferCreator.restriction.value' | translate }}</label>
                        <div class="radio">
                            <input type="radio" [value]="true" formControlName="hasRestrictedNetworkOfferCreator" class="form-control" id="hasRestrictedNetworkOfferCreatorYes">
                            <label for="hasRestrictedNetworkOfferCreatorYes">{{'form.user.fields.yes.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="false" formControlName="hasRestrictedNetworkOfferCreator" class="form-control" id="hasRestrictedNetworkOfferCreatorNo">
                            <label for="hasRestrictedNetworkOfferCreatorNo">{{'form.user.fields.no.value' | translate}}</label>
                        </div>
                    </div>

                </div>

                <div class="col_8" *ngIf="societyForm.get('hasRestrictedNetworkOfferCreator').value">
                    <div class="form-group">

                        <mat-select formControlName="restrictedNetworkOfferCreators" [multiple]="true">

                            <mat-option *ngFor="let network of restrictedNetworkOfferCreators$ | async" [value]="network.id">
                                {{ network.name }}
                            </mat-option>

                        </mat-select>

                        <mat-label>{{ 'form.user.fields.networkOfferCreator.restriction.selection.action.value' | translate }}</mat-label>

                    </div>
                </div>
            </div>

            <div class="row marges ligne_form" *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">

                <div class="col_12" *ngIf="networkOfferCreatorMarketplacePreference.length">
                    <div class="form-group">
                        <span>{{ 'form.user.fields.networkOfferCreator.marketplacePreference.value' | translate }} {{formattedNetworkOfferCreatorMarketplacePreference}}</span>
                    </div>
                </div>

            </div>

        </ng-container>

        <ng-container formGroupName="society">

            <!-- Accès aux offres soumises à licence -->

            <div class="row marges ligne_form" *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">
                <div class="col_12">
                    <div class="form-group">
                        <label>{{'form.user.fields.offer.licence.access.value' | translate}}</label>
                        <div class="radio">
                            <input type="radio" [value]="true" formControlName="hasAccessPublishedOffer" class="form-control" id="hasAccessPublishedOfferYes">
                            <label for="hasAccessPublishedOfferYes">{{'form.user.fields.yes.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="false" formControlName="hasAccessPublishedOffer" class="form-control" id="hasAccessPublishedOfferNo">
                            <label for="hasAccessPublishedOfferNo">{{'form.user.fields.no.value' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Accès aux offres en publication restreinte -->

            <div class="row marges ligne_form" *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">

                <div class="col_4">
                    <div class="form-group">
                        <label>{{'form.user.fields.offer.publication.restricted.access.value' | translate}}</label>
                        <div class="radio">
                            <input type="radio" [value]="true" formControlName="hasAccessRestrictedOffer" class="form-control" id="hasAccessRestrictedOfferYes">
                            <label for="hasAccessRestrictedOfferYes">{{'form.user.fields.yes.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="false" formControlName="hasAccessRestrictedOffer" class="form-control" id="hasAccessRestrictedOfferNo">
                            <label for="hasAccessRestrictedOfferNo">{{'form.user.fields.no.value' | translate}}</label>
                        </div>
                    </div>
                </div>

                <!-- Quota d'offre restreinte -->

                <ng-container *ngIf="societyForm.get('hasAccessRestrictedOffer').value && hasOneOfThisRoles(['ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">
                    <div class="col_4">
                        <div class="form-group">
                            <input type="number" placeholder=" " class="form-control" formControlName="restrictedOfferQuota" id="restrictedOfferQuota">
                            <label>{{ 'form.user.fields.offer.publication.restricted.quota.value' | translate }} :</label>
                            <mat-error *ngIf="societyForm.get('restrictedOfferQuota').errors && (societyForm.get('restrictedOfferQuota').dirty || societyForm.get('quota').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('restrictedOfferQuota').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="societyForm.get('restrictedOfferQuota').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </ng-container>

            </div>

        </ng-container>

    </ng-container>

</ng-template>

<!-- Conditions générales de vente -->

<ng-template #formTermsAndConditions>

    <div class="row marges ligne_form">
        <div class="form_part col_12">
            <p class="title_part">{{ 'form.user.title.societyInformations.termsAndConditions.value' | translate }}</p>
        </div>
        <div class="col_12">
            <div class="form-group">
                <label>{{ 'form.user.fields.cgv.title.value' | translate }}</label>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <app-terms-and-conditions [parentTermsAndConditions]="form"></app-terms-and-conditions>
        </div>
    </div>

</ng-template>

<!-- Représentant légal -->

<ng-template #legalRepresentative>

    <ng-container [formGroup]="form">

        <ng-container formGroupName="society">

            <div formGroupName="legalRepresentative">

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <!-- Civilité -->
                        <div class="form-group">
                            <label>{{'form.user.fields.civility.title.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" [value]="1" formControlName="civility" class="form-control" id="legalRepresentative-civility-mister">
                                <label for="legalRepresentative-civility-mister">{{'form.user.fields.civility.mister.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="2" formControlName="civility" class="form-control" id="legalRepresentative-civility-madam">
                                <label for="legalRepresentative-civility-madam">{{'form.user.fields.civility.madam.value' | translate}}</label>
                            </div>

                            <mat-error
                                    *ngIf="societyLegalRepresentativeControl.get('civility').errors && (societyLegalRepresentativeControl.get('civility').dirty || societyLegalRepresentativeControl.get('civility').touched)">
                                <div class="invalid-feedback">
                                    <div
                                            *ngIf="societyLegalRepresentativeControl.get('civility').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_4">

                        <!-- Nom -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="lastName" id="legalRepresentativeLastName" required="required">
                            <label for="legalRepresentativeLastName">{{'form.user.fields.lastname.value' | translate}}</label>
                            <mat-error *ngIf="societyLegalRepresentativeControl.get('lastName').errors && (societyLegalRepresentativeControl.get('lastName').dirty || societyLegalRepresentativeControl.get('lastName').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('lastName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_4">

                        <!-- Prénom -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="firstName" id="legalRepresentativeFirstName" required="required">
                            <label for="legalRepresentativeFirstName">{{'form.user.fields.firstname.value' | translate}}</label>
                            <mat-error *ngIf="societyLegalRepresentativeControl.get('firstName').errors && (societyLegalRepresentativeControl.get('firstName').dirty || societyLegalRepresentativeControl.get('firstName').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('firstName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_4">
                        <!-- Téléphone -->
                        <div class="form-group">

                            <app-core-intl-tel-input [formGroup]="societyLegalRepresentativeControl" [controlName]="'phone'" customPlaceholder="{{'form.user.fields.legalRepresentative.phone.value' | translate}}"></app-core-intl-tel-input>

                            <mat-error
                                    *ngIf="societyLegalRepresentativeControl.get('phone').errors && (societyLegalRepresentativeControl.get('phone').dirty || societyLegalRepresentativeControl.get('phone').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('phone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">

                    <div class="col_12 col_md_4">

                        <!-- Nationalité -->

                        <div class="form-group">
                            <select  class="form-control" id="legalRepresentativeNationality" formControlName="nationality" required="required">
                                <option *ngFor="let country of countries$ | async" [value]="country.code">
                                    {{ translationService.getFallbackTranslation(country.translations).name }}
                                </option>
                            </select>
                            <label class="required" for="legalRepresentativeNationality">{{ 'form.user.fields.nationality.value' | translate }}</label>
                            <mat-error
                                *ngIf="societyLegalRepresentativeControl.get('nationality').invalid && (societyLegalRepresentativeControl.get('nationality').dirty || societyLegalRepresentativeControl.get('nationality').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('nationality').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_4">

                        <!-- Date de naissance -->

                        <app-input-date [form]="societyLegalRepresentativeControl" [config]="birthDayConfig"></app-input-date>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_6 y_items_center">
                        <app-core-google-places-location [fields]="getAddressLocationFields(societyLegalRepresentativeControl)"></app-core-google-places-location>
                    </div>

                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Adresse -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="legalRepresentativeAddress" required="required">
                            <label for="legalRepresentativeAddress">{{'form.user.fields.address.value' | translate}}</label>
                            <mat-error
                                *ngIf="societyLegalRepresentativeControl.get('address').errors && (societyLegalRepresentativeControl.get('address').dirty || societyLegalRepresentativeControl.get('address').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Complément d'adresse -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalAddress" id="legalRepresentativeAdditionalAddress">
                            <label for="legalRepresentativeAdditionalAddress">{{'form.user.fields.additionalAddress.value' | translate}}</label>
                            <mat-error
                                    *ngIf="societyLegalRepresentativeControl.get('additionalAddress').errors && (societyLegalRepresentativeControl.get('additionalAddress').dirty || societyLegalRepresentativeControl.get('additionalAddress').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('additionalAddress').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Code postal -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="legalRepresentativeZipcode" required="required">
                            <label for="legalRepresentativeZipcode">{{'form.user.fields.zipcode.value' | translate}}</label>
                            <mat-error
                                *ngIf="societyLegalRepresentativeControl.get('zipcode').errors && (societyLegalRepresentativeControl.get('zipcode').dirty || societyLegalRepresentativeControl.get('zipcode').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('zipcode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="societyLegalRepresentativeControl.get('zipcode').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_6">

                        <!-- Ville -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="legalRepresentativeCity" required="required">
                            <label for="legalRepresentativeCity">{{'form.user.fields.city.value' | translate}}</label>
                            <mat-error
                                *ngIf="societyLegalRepresentativeControl.get('city').errors && (societyLegalRepresentativeControl.get('city').dirty || societyLegalRepresentativeControl.get('city').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('city').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Région -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="region" id="legalRepresentativeRegion" required="required">
                            <label for="legalRepresentativeRegion">{{'form.user.fields.region.value' | translate}}</label>
                            <mat-error
                                    *ngIf="societyLegalRepresentativeControl.get('region').errors && (societyLegalRepresentativeControl.get('region').dirty || societyLegalRepresentativeControl.get('region').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('region').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Pays -->

                        <div class="form-group">
                            <select  class="form-control" id="legalRepresentativeCountry" formControlName="country" required="required">
                                <option *ngFor="let country of countries$ | async" [value]="country.code">
                                    {{ translationService.getFallbackTranslation(country.translations).name }}
                                </option>
                            </select>
                            <label class="required" for="legalRepresentativeCountry">{{ 'form.user.fields.country.value' | translate }}</label>
                            <mat-error
                                *ngIf="societyLegalRepresentativeControl.get('country').invalid && (societyLegalRepresentativeControl.get('country').dirty || societyLegalRepresentativeControl.get('country').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </ng-container>

</ng-template>

<ng-template #offerPermanentOptions>

    <ng-container *ngIf="(offerPermanentOptions$ | async) as offerPermanentOptions">

        <ng-container *ngIf="offerPermanentOptions.length">

            <div class="ligne_form" *ngIf="hasRole('ROLE_OFFER_CREATOR')">
                <div class="form-group">
                    <div class="row marges">
                        <div class="col y_items_center">
                            <p class="read">
                                <strong>{{ 'form.user.title.permanentOptions.countPersonalOffers.value' | translate: { count: society.countOfferWithPermanentOptions } }}</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ligne_form" *ngIf="hasRole('ROLE_OFFER_DISTRIBUTOR')">
                <div class="form-group">
                    <div class="row marges">
                        <div class="col y_items_center">
                            <p class="read">
                                <strong>{{ 'form.user.title.permanentOptions.countCatalogOffers.value' | translate: { count: society.countOfferCatalogWithPermanentOptions } }}</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ligne_form">
                <div class="form-group">
                    <div class="row marges">
                        <div class="col y_items_center">
                            <p class="read">
                                <strong>{{ 'form.user.title.permanentOptions.value' | translate }}</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ligne_form row marges">

                <div class="col_12">

                    <div class="form-group">

                        <div class="row marges">

                            <div *ngFor="let offerPermanentOption of offerPermanentOptions" class="col_4 y_content_start">

                                <div class="read_composition_option col_12">

                                    <p class="read">
                                        <strong>{{ 'offer.permanentOption.name.value' | translate }} : </strong>
                                        {{ translationService.getFallbackTranslation(offerPermanentOption.translations).title }}
                                    </p>

                                    <p class="read">
                                        <strong>{{ 'offer.permanentOption.reference.value' | translate }} : </strong>
                                        {{ offerPermanentOption.reference }}
                                    </p>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </ng-container>

        <div class="page_title" *ngIf="!offerPermanentOptions.length">
            <div class="subscription_information">
                <p [innerHTML]="'form.user.title.permanentOptions.noResult.value' | translate"></p>
            </div>
        </div>

    </ng-container>

</ng-template>

<ng-template #services>

    <ng-container *ngIf="(documents$ | async) as documents">

        <div class="ligne_form" *ngIf="documents.length">
            <div class="form-group">
                <div class="row marges">
                    <div class="col y_items_center">
                        <p class="read">
                            <strong>{{ 'mangopay.document.plural.state.value' | translate }} :</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="ligne_form row marges">

            <div class="col_12">

                <div class="form-group">

                    <div class="row marges">

                        <div *ngFor="let document of documents" class="col_4 y_content_start">

                            <div class="read_composition_option col_12">

                                <p class="read">
                                    <strong>{{ 'mangopay.document.name.value' | translate }} : </strong>
                                    {{ getDocumentLabel(document.type) }}
                                </p>

                                <p class="read">
                                    <strong>{{ 'mangopay.document.state.value' | translate }} : </strong>
                                    {{ ('mangopay.document.state.' + (document.status ? document.status : 'notSubmitted') + '.value') | translate }}
                                    <ng-container *ngIf="document.errorStatus">
                                        <br/>
                                        <span>
                                            {{ 'services.form.file.' + document.documentType + '.refusalReason.status.' + document.errorStatus + '.value' | translate }}
                                            <ng-container *ngIf="document.errorMessage"> : {{ document.errorMessage }}</ng-container>
                                        </span>
                                    </ng-container>
                                </p>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </ng-container>

    <ng-container *ngIf="(societyServices$ | async) as societyServices">

        <div class="ligne_form" *ngIf="societyServices.length">
            <div class="form-group">
                <div class="row marges">
                    <div class="col y_items_center">
                        <p class="read">
                            <strong>{{ 'societyService.list.subscribed.value' | translate }} :</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="ligne_form row marges">

            <div class="col_12">

                <div class="form-group">

                    <div class="row marges">

                        <div *ngFor="let societyService of societyServices" class="col_4 y_content_start">

                            <div class="read_composition_option col_12">

                                <p class="read">
                                    <strong>{{ 'service.name.value' | translate }} : </strong>
                                    {{ translationService.getFallbackTranslation(societyService.service.translations).name }}
                                </p>

                                <p class="read">
                                    <strong>{{ 'service.subscription.date.value' | translate }} : </strong>
                                    {{ (societyService.createdAt | date : 'fullDate' : null: localeId) | titlecase }}
                                </p>

                                 <ng-container *ngIf="societyService.service.isWithSubscription">

                                     <p class="read">
                                         <strong>{{ 'subscription.form.periodicity.value' | translate }} : </strong>
                                         {{ (societyService.periodicity === 'M' ? 'periodicity.monthly.value' : 'periodicity.yearly.value') | translate }}
                                     </p>

                                 </ng-container>

                                <ng-container *ngIf="(societyService.service.monthlyPriceHT > 0) || (societyService.service.yearlyPriceHT > 0)">

                                    <p class="read">
                                        <strong>{{ 'services.payment.amount.value' | translate }} : </strong> {{ formatPrice(societyService.total + (societyService.total * societyService.vatPercent), 'TTC') }}
                                        (<span class="price_ht">{{ formatPrice(societyService.total, 'HT') }}</span>)
                                    </p>

                                </ng-container>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </ng-container>

</ng-template>
