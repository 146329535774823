<div class="bloc_offers">

    <div class="bloc_offers_filters">

        <div>

            <div class="filters">

                <app-core-offer-filter
                    [mode]="mode"
                    [society]="society"
                    [roles]="roles"
                    [filterBuilder]="filterBuilder"
                    [prefixFilter]="prefixFilter"
                ></app-core-offer-filter>

                <div class="filter_item">
                    <button type="button" class="btn_cta btn_lines btn_small" (click)="!openFilterPanel ? openFilterPanel = true : openFilterPanel = false"><span class="ty-filter">{{ 'offer.list.filter.value' | translate }}</span></button>
                </div>

            </div>

            <div class="filters_checked">

                <ng-container *ngFor="let filter of getSessionsFilters(mode); trackBy: identifySessionFilter">

                    <ng-container *ngIf="isListFilter(filter.value) && filter.value.length > 0">
                        <span class="filter" (click)="removeFilter(filter.key, filter.value, val)" *ngFor="let val of getListFilter(filter)">
                            <span>{{ 'offer.filters.' + filter.key |  translate}}&nbsp;</span>
                            <span> {{ formatFilterValue(filter.key, val) }}</span>
                            <span class="close"><mat-icon>close</mat-icon></span>
                        </span>
                    </ng-container>

                    <ng-container *ngIf="!isListFilter(filter.value) && filter.value != null">
                        <span class="filter" (click)="removeFilter(filter.key, filter.value, null)">
                            <span>{{ 'offer.filters.' + filter.key |  translate}}&nbsp;</span>
                            <span> {{  formatFilterValue(filter.key, filter.value) }}</span>
                            <span class="close"><mat-icon>close</mat-icon></span>
                        </span>
                    </ng-container>
                </ng-container>
            </div>

        </div>

        <app-core-offer-filter-panel
            [hidden]="!openFilterPanel"
            [filterBuilder]="filterBuilder"
            [prefixFilter]="prefixFilter"
            [providerNetwork]="providerNetwork"
            [offerCreatorNetwork]="offerCreatorNetwork"
            [provider]="provider"
            [restrictedSocietyProviders]="restrictedSocietyProviders"
            [mode]="mode"
            [offerCreator]="offerCreator"
            [offerAttributeTouristsDestinations]="offerAttributeTouristsDestinations"
            [offerAttributeThemes]="offerAttributeThemes"
            [offerAttributeTypes]="offerAttributeTypes"
            [offerAttributeActivities]="offerAttributeActivities"
            [offerAttributeAccomodations]="offerAttributeAccomodations"
            [offerAttributeRestoration]="offerAttributeRestoration"
            [offerAttributeRegions]="offerAttributeRegions"
            [offerDuration]="offerDuration"
            [offerCustomerTypology]="offerCustomerTypology"
            (closeFilterPanelEvent)="closePanel($event)" [roles]="roles">
        </app-core-offer-filter-panel>

    </div>


    <div class="offers_list">
        <div class="list_links">
            <div class="row x_end">

                <div class="col_content">

                    <!-- Avec nuitée / Sans nuitée -->

                    <div class="night_filter_container">

                        <div class="night_filter_toggle d_flex">

                            <div class="night_filter_tile_wrapper">

                                <div class="d_flex y_items_center x_center night_filter_tile" [ngClass]="getNightFilterFieldClasses(false)" (click)="handleNightFilter(false)">
                                    <span>{{ 'offer.filters.withoutNight.value' | translate }}</span>
                                </div>

                            </div>

                            <div class="night_filter_tile_wrapper">

                                <div class="d_flex y_items_center x_center night_filter_tile" [ngClass]="getNightFilterFieldClasses(true)" (click)="handleNightFilter(true)">
                                    <span>{{ 'offer.filters.withNight.value' | translate }}</span>
                                </div>

                            </div>

                        </div>
                    </div>

                    <!-- Prix Net / Public -->

                    <mat-radio-group *ngIf="hasOneOfThisRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER']) && isView('list') && hasOneOfThisMode(['catalog', 'channel', 'offer-permanent-option-catalog'])" [(ngModel)]="publicPrice">
                        <mat-radio-button [value]="0">{{ 'offer.dateEngine.publicPrice.net.value' | translate }}</mat-radio-button>
                        <mat-radio-button [value]="1">{{ 'offer.dateEngine.publicPrice.public.value' | translate }}</mat-radio-button>
                    </mat-radio-group>

                    <span class="ty-map" *ngIf="isView('list')" (click)="setView('map')"></span>
                    <span class="ty-list" *ngIf="isView('map')" (click)="setView('list')"></span>
                </div>
            </div>
        </div>

        <div class="content_offers">

            <ng-container *ngIf="isView('list')">

                <app-core-offer-list
                        [layoutView]="view"
                        [mode]="mode"
                        [roles]="roles"
                        [publicPrice]="publicPrice"
                        [identificationNumber]="identificationNumber"
                        [filterBuilder]="filterBuilder"
                        [locale]="locale"
                        [referenced]="referenced"
                        [allowBooking]="allowBooking"
                        [allowGiftVoucher]="allowGiftVoucher"
                        [allowRequest]="allowRequest"
                        [allowOnlineSale]="allowOnlineSale"
                        [loadItemsSourceCallback]="loadItemsSourceCallback"
                >
                </app-core-offer-list>

            </ng-container>

            <ng-container *ngIf="isView('map')">

                <app-core-offer-map
                        [layoutView]="view"
                        [mode]="mode"
                        [roles]="roles"
                        [publicPrice]="publicPrice"
                        [identificationNumber]="identificationNumber"
                        [filterBuilder]="filterBuilder"
                        [locale]="locale"
                        [referenced]="referenced"
                        [allowBooking]="allowBooking"
                        [allowGiftVoucher]="allowGiftVoucher"
                        [allowRequest]="allowRequest"
                        [allowOnlineSale]="allowOnlineSale"
                        [loadItemsSourceCallback]="loadItemsSourceCallback"
                >
                </app-core-offer-map>

            </ng-container>
        </div>
    </div>
</div>
