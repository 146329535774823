<!-- Formulaire de gestion du compte -->

<form [formGroup]="form" (ngSubmit)="formService.submit()" class="form_account">

    <div class="infos_user">
        <div class="row h_full">
            <div class="col y_items_center">
                <span class="name"><span>{{ user.value.firstName.charAt(0) }}{{ user.value.lastName.charAt(0) }}</span>{{ user.value.firstName }} {{ user.value.lastName }}</span>
            </div>
        </div>
    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group #tabGroup>
        <ng-container *ngFor="let tabItem of tabItems">
            <mat-tab >
                <ng-template  mat-tab-label>
                    <span>{{ tabItem.label | translate }}</span>
                    <span  [ngClass]="offerFormTabValidationService.getTabLabelClass(tabItem.tag)"></span>
                </ng-template>
                <ng-container *ngTemplateOutlet="tabItem.template"></ng-container>
            </mat-tab>
        </ng-container>
    </mat-tab-group>

</form>

<!-- Données personnelles -->

<ng-template #personnalInformations>

    <div class="title_tab">
        <div class="row marges h_full x_between">
            <div class="col_content y_items_center">
                <span class="title">{{ 'user.account.personnalInformations.value' | translate }}</span>
            </div>
            <div class="col_content y_items_center">
                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="content_tab">

        <ng-container [formGroup]="form">

            <div class="row marges ligne_form">
                <div class="col_12">

                    <!-- Civilité -->

                    <div class="form-group">
                        <label>{{'form.user.fields.civility.title.value' | translate}}</label>
                        <div class="radio">
                            <input type="radio" [value]="1" formControlName="civility" class="form-control" id="civility-mister">
                            <label for="civility-mister">{{'form.user.fields.civility.mister.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="2" formControlName="civility" class="form-control" id="civility-madam">
                            <label for="civility-madam">{{'form.user.fields.civility.madam.value' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row marges ligne_form">
                <div class="col_12 col_md_4">

                    <!-- Nom -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="lastName" id="lastName" required="required">
                        <label for="lastName">{{'form.user.fields.lastname.value' | translate}}</label>
                        <mat-error *ngIf="form.get('lastName').errors && (form.get('lastName').dirty || form.get('lastName').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('lastName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
                <div class="col_12 col_md_4">

                    <!-- Prénom -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="firstName" id="firstName" required="required">
                        <label for="firstName">{{'form.user.fields.firstname.value' | translate}}</label>
                        <mat-error *ngIf="form.get('firstName').errors && (form.get('firstName').dirty || form.get('firstName').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('firstName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
                <div class="col_12 col_md_4">

                    <!-- Email -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="email" id="userEmail" required="required">
                        <label for="userEmail">{{'form.user.fields.email.contact.value' | translate}}</label>
                        <mat-error *ngIf="form.get('email').errors && (form.get('email').dirty || form.get('email').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="form.get('email').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="row marges ligne_form">
                <div class="col_12 col_md_4">

                    <!-- Téléphone fixe -->

                    <div class="form-group">

                        <app-core-intl-tel-input [formGroup]="form" [controlName]="'switchboardPhone'" customPlaceholder="{{'form.user.fields.switchboardPhone.value' | translate}}"></app-core-intl-tel-input>

                        <mat-error *ngIf="form.get('switchboardPhone').errors && (form.get('switchboardPhone').dirty || form.get('switchboardPhone').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('switchboardPhone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="form.get('switchboardPhone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
                <div class="col_12 col_md_4">

                    <!-- Ligne directe -->

                    <div class="form-group">

                        <app-core-intl-tel-input [formGroup]="form" [controlName]="'directPhone'" customPlaceholder="{{'form.user.fields.directPhone.value' | translate}}"></app-core-intl-tel-input>

                        <mat-error *ngIf="form.get('directPhone').errors && (form.get('directPhone').dirty || form.get('directPhone').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('directPhone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="form.get('directPhone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
                <div class="col_12 col_md_4">

                    <!-- Portable -->

                    <div class="form-group">

                        <app-core-intl-tel-input [formGroup]="form" [controlName]="'cellphone'" customPlaceholder="{{'form.user.fields.cellphone.value' | translate}}"></app-core-intl-tel-input>

                        <mat-error *ngIf="form.get('cellphone').errors && (form.get('cellphone').dirty || form.get('cellphone').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('cellphone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="form.get('cellphone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">

                <div class="col_12 col_md_4">
                    <div class="form-group">
                        <app-core-timezone-select [form]="form" [required]="true"></app-core-timezone-select>
                    </div>
                </div>

                <div class="col_12 col_md_4">

                    <!-- Service -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="service" id="service">
                        <label for="service">{{ (isAccountAdmin() ? 'form.user.fields.service.value' : 'collaborator.list.fields.service.value') | translate}}</label>
                        <mat-error *ngIf="form.get('service').errors &&(form.get('service').dirty || form.get('service').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('service').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

                <div class="col_12 col_md_4">

                    <!-- Locale -->

                    <div class="form-group">
                        <select class="form-control" id="adminLocale" formControlName="locale" required="required">
                            <option value=""></option>
                            <option *ngFor="let locale of locales$ | async" [value]="locale.id">
                                {{ locale.label | translate }}
                            </option>
                        </select>
                        <label class="required" for="locale">{{ 'form.user.fields.locale.value' | translate }}</label>
                        <mat-error *ngIf="form.get('locale').errors && (form.get('locale').dirty || form.get('locale').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('locale').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </ng-container>

    </div>

</ng-template>

<!-- Saisie des informations entreprise -->

<ng-template #societyInformations>

    <div class="title_tab">
        <div class="row marges h_full x_between">
            <div class="col_content y_items_center">
                <span class="title">{{ 'society.informations.value' | translate }}</span>
            </div>
            <div class="col_content y_items_center">
                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="content_tab">

        <ng-container [formGroup]="form">

            <p>{{ 'services.form.file.infos.society.value' | translate}} </p>

            <br/>

            <div formGroupName="society">

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Nom société -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="name" id="nameSociety" required="required">
                            <label for="nameSociety">{{'form.user.fields.name.value' | translate}}</label>
                            <mat-error
                                *ngIf="societyForm.get('name').errors && (societyForm.get('name').dirty || societyForm.get('name').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_6">

                        <!-- Site web -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="mainWebsite" id="mainWebsite" required="required">
                            <label for="mainWebsite">{{'form.user.fields.website.value' | translate}}</label>
                            <mat-error
                                *ngIf="societyForm.get('mainWebsite').errors && (societyForm.get('mainWebsite').dirty || societyForm.get('mainWebsite').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('mainWebsite').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="societyForm.get('mainWebsite').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Locale -->

                        <div class="form-group">
                            <select class="form-control" id="locale" formControlName="locale" required="required">
                                <option value=""></option>
                                <option *ngFor="let locale of locales$ | async" [value]="locale.id">
                                    {{ locale.label | translate }}
                                </option>
                            </select>
                            <label class="required" for="locale">{{ 'form.user.fields.society.locale.value' | translate }}</label>
                            <mat-error *ngIf="societyForm.get('locale').errors && (societyForm.get('locale').dirty || societyForm.get('locale').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('locale').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Type -->

                        <div class="form-group">
                            <select class="form-control" id="type" formControlName="type" required="required">
                                <option value=""></option>
                                <option *ngFor="let type of types$ | async" [value]="type.id">
                                    {{ type.label | translate }}
                                </option>
                            </select>
                            <label class="required" for="type">{{ 'form.user.fields.society.type.value' | translate }}</label>
                            <mat-error *ngIf="societyForm.get('type').errors && (societyForm.get('type').dirty || societyForm.get('type').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('type').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Raison sociale -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="corporateName" id="corporateName" required="required">
                            <label for="corporateName">{{'form.user.fields.corporateName.value' | translate}}</label>
                            <mat-error
                                *ngIf="societyForm.get('corporateName').errors && (societyForm.get('corporateName').dirty || societyForm.get('corporateName').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('corporateName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Immatriculation -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="registration" id="immatriculation" [required]="hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])">
                            <label for="immatriculation">{{'form.user.fields.immatriculation.value' | translate}}</label>
                            <mat-error
                                    *ngIf="societyForm.get('registration').errors && (societyForm.get('registration').dirty || societyForm.get('registration').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('registration').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                            <span *ngIf="hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])" class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon> {{ 'form.user.fields.immatriculation.help.value' | translate }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Code APE -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="businessActivityCode" id="businessActivityCode" >
                            <label for="businessActivityCode">{{'form.user.fields.businessActivityCode.value' | translate}}</label>
                            <mat-error
                                *ngIf="societyForm.get('businessActivityCode').errors && (societyForm.get('businessActivityCode').dirty || societyForm.get('businessActivityCode').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('businessActivityCode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Capital -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="capital" id="capital">
                            <label for="capital">{{'form.user.fields.capital.value' | translate}}</label>
                            <mat-error
                                    *ngIf="societyForm.get('capital').errors && (societyForm.get('capital').dirty || societyForm.get('capital').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('capital').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Siren -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="identificationNumber" id="siren" required="required">
                            <label for="siren">{{'form.user.fields.siren.value' | translate}}</label>
                            <mat-error
                                *ngIf="societyForm.get('identificationNumber').errors && (societyForm.get('identificationNumber').dirty || societyForm.get('identificationNumber').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('identificationNumber').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_6">

                        <!-- immatriculation RCS  -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="rcsRegistration" id="rcsRegistration">
                            <label for="rcsRegistration">{{'form.user.fields.rcsRegistration.value' | translate}}</label>
                            <mat-error
                                    *ngIf="societyForm.get('rcsRegistration').errors && (societyForm.get('rcsRegistration').dirty || societyForm.get('rcsRegistration').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('rcsRegistration').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form" *ngIf="hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])">

                    <div class="col_12 col_md_6">

                        <!-- Garantie financière  -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="financialGuarantee" id="financialGuarantee" required="required">
                            <label for="financialGuarantee">{{'form.user.fields.financialGuarantee.value' | translate}}</label>
                            <mat-error
                                *ngIf="societyForm.get('financialGuarantee').errors && (societyForm.get('financialGuarantee').dirty || societyForm.get('financialGuarantee').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('financialGuarantee').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon> {{ 'form.user.fields.financialGuarantee.help.value' | translate }}
                            </span>
                        </div>
                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Assurance responsabilité civile professionnelle -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="insurancePolicy" id="insurancePolicy" required="required">
                            <label for="insurancePolicy">{{'form.user.fields.insurancePolicy.value' | translate}}</label>
                            <mat-error *ngIf="societyForm.get('insurancePolicy').errors && (societyForm.get('insurancePolicy').dirty || societyForm.get('insurancePolicy').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('insurancePolicy').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon> {{ 'form.user.fields.insurancePolicy.help.value' | translate }}
                            </span>
                        </div>
                    </div>

                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Numéro de TVA -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="vatNumber" id="vatNumber">
                            <label for="vatNumber">{{'form.user.fields.vatNumber.value' | translate}}</label>
                            <mat-error
                                    *ngIf="societyForm.get('vatNumber').errors && (societyForm.get('vatNumber').dirty || societyForm.get('vatNumber').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('vatNumber').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="societyForm.get('vatNumber').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                            <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>
                                {{ 'form.user.fields.vatNumber.help.value' | translate }}
                                </span>
                        </div>
                    </div>
                </div>

                <div *ngIf="!hasRole('ROLE_OFFER_CREATOR')" class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Logo -->

                        <div class="form-group">
                            <label class="required">{{ 'file.add.action.logo.value' | translate }}</label>
                            <form-field-file [config]="societyLogoConfig" [form]="societyForm" [file]="societyForm.get('logo').value"></form-field-file>
                            <mat-error *ngIf="societyForm.get('logo').invalid">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('logo').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                            <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.user.fields.logo.help.' + (hasRole('ROLE_OFFER_CREATOR') ? 'offerCreator' : 'distributor') + '.value' | translate }}</span>
                        </div>
                    </div>
                </div>

                <!-- Adresses -->

                <ng-container formArrayName="addresses">

                    <div class="row marges ligne_form" *ngIf="isAddressToDisplay('mailing')">

                        <ng-container *ngTemplateOutlet="formAddress;context:{controlIndex: getAddressControlIndexByType('mailing')}"></ng-container>

                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" id="differentBillingAddress" [(ngModel)]="differentBillingAddress" [ngModelOptions]="{standalone: true}" (ngModelChange)="resetAddresses()">
                                    <label for="differentBillingAddress">{{ 'bill.address.different.question.value' | translate }}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form" *ngIf="isAddressToDisplay('billing')">

                        <ng-container *ngTemplateOutlet="formAddress;context:{controlIndex: getAddressControlIndexByType('billing')}"></ng-container>

                    </div>

                </ng-container>

                <!-- Email générique (pour les échanges clients) -->

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="email"
                                   id="email" required="required">
                            <label for="email">{{'form.user.fields.email.society.value' | translate}}</label>
                            <mat-error
                                *ngIf="societyForm.get('email').errors && (societyForm.get('email').dirty || societyForm.get('email').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="societyForm.get('email').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </div>
                    <div class="col_12 col_md_6">

                        <!-- Téléphone générique (pour les échanges clients) -->

                        <div class="form-group">

                            <app-core-intl-tel-input [formGroup]="societyForm" [controlName]="'phone'" customPlaceholder="{{'form.user.fields.phone.value' | translate}}"></app-core-intl-tel-input>

                            <mat-error
                                *ngIf="societyForm.get('phone').errors && (societyForm.get('phone').dirty || societyForm.get('phone').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('phone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="societyForm.get('phone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <!-- Email de facturation différent -->

                <ng-container *ngIf="hasRole('ROLE_OFFER_CREATOR')">

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" id="differentBillingEmail" [(ngModel)]="differentBillingEmail" [ngModelOptions]="{standalone: true}" (ngModelChange)="resetBillingAddress()">
                                    <label for="differentBillingEmail">{{ 'form.user.fields.emailFacturation.title.value' | translate }}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <ng-container *ngIf="hasRole('ROLE_OFFER_CREATOR') && differentBillingEmail">

                    <div class="row marges ligne_form">
                        <div class="col_12 col_md_6">

                            <!-- Email de facturation -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="billingEmail" id="billingEmail">
                                <label for="billingEmail">{{'form.user.fields.emailFacturation.value' | translate}}</label>
                                <mat-error *ngIf="societyForm.get('billingEmail').errors && (societyForm.get('billingEmail').dirty || societyForm.get('billingEmail').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="societyForm.get('billingEmail').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        <div *ngIf="societyForm.get('billingEmail').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <ng-container *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_OFFER_CREATOR'])">

                    <div class="row marges ligne_form account_owner">
                        <div class="col_12">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" id="accountOwner" checked disabled>
                                    <label for="accountOwner">{{ 'user.account.owner.value' | translate }}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <ng-container *ngIf="hasAccessGiftVoucher && user.value.society.customInvoiceLegalInformation">

                    <div class="row marges ligne_form">
                        <div class="col_12">

                            <!-- Informations juridiques personnalisées -->

                            <div class="form-group">
                                <label>{{ 'user.account.invoices.custom.legalInformation.value' | translate }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_12 col_md_6">
                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="customInvoiceLegalInformation" id="customInvoiceLegalInformation">
                                <label for="customInvoiceLegalInformation">{{ 'user.account.invoices.custom.legalInformation.value' | translate }}</label>
                                <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>
                                    {{ 'services.form.gift_voucher.add.customInvoiceLegalInformation.value' | translate }}
                             </span>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <ng-container *ngIf="commission">

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{ ('form.user.fields.commission.title.' + (hasRole('ROLE_OFFER_DISTRIBUTOR') ? 'distributor' : 'default') + '.value') | translate }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_6">

                            <!-- Commissions séjour FIT %HT -->

                            <div class="form-group">
                                <input type="text" value="{{ formatCommission(commission.individualStay) }}" class="form-control" disabled>
                                <label>{{'form.user.fields.commission.individualStay.ht.value' | translate}}</label>
                            </div>

                        </div>

                        <div class="col_12 col_md_6">

                            <!-- Commissions séjour FIT %TTC -->

                            <div class="form-group">
                                <input type="text" value="{{ formatCommission(commission.individualStayTTC) }}" class="form-control" disabled>
                                <label>{{'form.user.fields.commission.individualStay.ttc.value' | translate}}</label>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_6">

                            <!-- Commission offre journée FIT %HT -->

                            <div class="form-group">
                                <input type="text" value="{{ formatCommission(commission.individualDay) }}" class="form-control" disabled>
                                <label>{{'form.user.fields.commission.individualDay.ht.value' | translate}}</label>
                            </div>

                        </div>

                        <div class="col_12 col_md_6">

                            <!-- Commission offre journée FIT %TTC -->

                            <div class="form-group">
                                <input type="text" value="{{ formatCommission(commission.individualDayTTC) }}" class="form-control" disabled>
                                <label>{{'form.user.fields.commission.individualDay.ttc.value' | translate}}</label>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_6">

                            <!-- Commission séjour Groupe / MICE %HT -->

                            <div class="form-group">
                                <input type="text" value="{{ formatCommission(commission.groupStay) }}" class="form-control" disabled>
                                <label>{{'form.user.fields.commission.groupStay.ht.value' | translate}}</label>
                            </div>

                        </div>

                        <div class="col_12 col_md_6">

                            <!-- Commission séjour Groupe / MICE %TTC -->

                            <div class="form-group">
                                <input type="text" value="{{ formatCommission(commission.groupStayTTC) }}" class="form-control" disabled>
                                <label>{{'form.user.fields.commission.groupStay.ttc.value' | translate}}</label>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_6">

                            <!-- Commission offre journée Groupe / MICE %HT -->

                            <div class="form-group">
                                <input type="text" value="{{ formatCommission(commission.groupDay) }}" class="form-control" disabled>
                                <label>{{'form.user.fields.commission.groupDay.ht.value' | translate}}</label>
                            </div>

                        </div>

                        <div class="col_12 col_md_6">

                            <!-- Commission offre journée Groupe / MICE %TTC -->

                            <div class="form-group">
                                <input type="text" value="{{ formatCommission(commission.groupDayTTC) }}" class="form-control" disabled>
                                <label>{{'form.user.fields.commission.groupDay.ttc.value' | translate}}</label>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_12">

                            <!-- Encaissement automatique des commissions -->

                            <div class="form-group">
                                <label>{{'form.user.fields.commission.incomingPayment.description.value' | translate}} {{((commission.incomingPayment ? 'yes' : 'no') + '.value') | translate }}</label>
                            </div>

                        </div>

                    </div>

                    <br>

                </ng-container>

            </div>

        </ng-container>

    </div>

</ng-template>

<!-- Saisie d'une adresse -->

<ng-template #formAddress let-i="controlIndex">

    <ng-container [formGroup]="form" *ngIf="getAddressControl(i)">

        <ng-container formGroupName="society">

            <ng-container formArrayName="addresses">

                <ng-container [formGroupName]="indexAsString(i)">
                    <div class="col_12">

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <label>{{ getAddressTypeLabel(getAddressControl(i).get('type').value) }}</label>
                                </div>
                            </div>
                        </div>

                        <div class="row marges ligne_form">
                            <div class="col_6 y_items_center">
                                <app-core-google-places-location [fields]="getAddressLocationFields(getAddressControl(i))" (onSelection)="selectAddress(i)"></app-core-google-places-location>
                            </div>
                        </div>

                        <div class="row marges ligne_form">
                            <div class="col_12 col_md_6">

                                <!-- Adresse -->

                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="address" required="required">
                                    <label for="address">{{'form.user.fields.address.value' | translate}}</label>
                                    <mat-error
                                        *ngIf="getAddressControl(i).get('address').errors && (getAddressControl(i).get('address').dirty || getAddressControl(i).get('address').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="getAddressControl(i).get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                            <div class="col_12 col_md_6">

                                <!-- Complément d'adresse -->

                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalAddress" id="additionalAddress">
                                    <label for="additionalAddress">{{'form.user.fields.additionalAddress.value' | translate}}</label>
                                    <mat-error
                                            *ngIf="getAddressControl(i).get('additionalAddress').errors && (getAddressControl(i).get('additionalAddress').dirty || getAddressControl(i).get('additionalAddress').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="getAddressControl(i).get('additionalAddress').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row marges ligne_form">
                            <div class="col_12 col_md_6">

                                <!-- Code postal -->

                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="zipcode" required="required">
                                    <label for="zipcode">{{'form.user.fields.zipcode.value' | translate}}</label>
                                    <mat-error
                                        *ngIf="getAddressControl(i).get('zipcode').errors && (getAddressControl(i).get('zipcode').dirty || getAddressControl(i).get('zipcode').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="getAddressControl(i).get('zipcode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="getAddressControl(i).get('zipcode').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col_12 col_md_6">

                                <!-- Ville -->

                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="city" required="required">
                                    <label for="city">{{'form.user.fields.city.value' | translate}}</label>
                                    <mat-error
                                        *ngIf="getAddressControl(i).get('city').errors && (getAddressControl(i).get('city').dirty || getAddressControl(i).get('city').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="getAddressControl(i).get('city').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row marges ligne_form">

                            <div class="col_12 col_md_6">

                                <!-- Région -->

                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="region" id="region" required="required">
                                    <label for="region">{{'form.user.fields.region.value' | translate}}</label>
                                    <mat-error
                                            *ngIf="getAddressControl(i).get('region').errors && (getAddressControl(i).get('region').dirty || getAddressControl(i).get('region').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="getAddressControl(i).get('region').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                            <div class="col_12 col_md_6">

                                <!-- Pays -->

                                <div class="form-group">
                                    <select class="form-control" id="country" formControlName="country" required="required">
                                        <option *ngFor="let country of countries$ | async"
                                                [value]="country.code">{{ translationService.getFallbackTranslation(country.translations).name }}</option>
                                    </select>
                                    <label class="required"
                                           for="country">{{ 'form.user.fields.country.value' | translate }}</label>
                                    <mat-error
                                            *ngIf="getAddressControl(i).get('country').invalid && (getAddressControl(i).get('country').dirty || getAddressControl(i).get('country').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="getAddressControl(i).get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-container>

            </ng-container>

        </ng-container>

    </ng-container>

</ng-template>

<!-- Saisie des documents CGV -->

<ng-template #formTermsAndConditions>

    <div class="title_tab">
        <div class="row marges h_full x_between">
            <div class="col_content y_items_center">
                <span class="title">{{ 'termsAndConditions.value' | translate }}</span>
            </div>
            <div class="col_content y_items_center">
                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>

    <app-terms-and-conditions [parentTermsAndConditions]="form"></app-terms-and-conditions>

</ng-template>

<!-- Gestion des rôles -->

<ng-template #roleManagement>

    <div class="title_tab">
        <div class="row marges h_full x_between">
            <div class="col_content y_items_center">
                <span class="title">{{ 'role.management.plural.value' | translate }}</span>
            </div>
        </div>
    </div>

    <div class="content_tab">

        <ng-container [ngSwitch]="true">

            <!-- ETQ Créateur d'offre -->
            <ng-container *ngSwitchCase="hasRole('ROLE_OFFER_CREATOR') && !hasRole('ROLE_OFFER_DISTRIBUTOR')">

                <div class="add_role">

                    <!-- Ajout du rôle distributeur -->

                    <p>{{ 'role.add.distributor.description.value' | translate }}</p>

                    <p>{{ 'role.add.distributor.description.action.value' | translate }}</p>

                    <button type="button" class="btn_cta btn_little" (click)="addRoleDialog('ROLE_OFFER_DISTRIBUTOR')">
                        {{ 'role.add.distributor.action.value'|translate }}
                    </button>

                </div>

                <!-- Avantages du rôle Distributeur d'offre -->
                <app-role-advantages [advantages]="'role.synthesis.distributor' | translate"></app-role-advantages>
            </ng-container>

            <!-- ETQ Distributeur d'offre -->
            <ng-container *ngSwitchCase="hasRole('ROLE_OFFER_DISTRIBUTOR') && !hasRole('ROLE_OFFER_CREATOR')">

                <div class="add_role">

                    <!-- Ajout du rôle Créateur d'offre -->

                    <div class="col_12">

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <p [innerHTML]="'user.account.management.beCreator.infos.value' | translate"></p>
                            </div>
                        </div>

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <button type="button" class="btn_cta btn_little" (click)="subscriptionDialog()">
                                    {{'user.account.management.beCreator.button.value' | translate}}
                                </button>
                            </div>
                        </div>

                        <ng-container *ngIf="displayMangopayDocumentsRequirements">

                            <div class="row marges ligne_form">
                                <div class="col_12">
                                    <p [innerHTML]="'user.account.management.beCreator.mangopay.documents.description.value' | translate"></p>
                                </div>
                            </div>

                            <div class="row marges ligne_form">
                                <div class="col_12">
                                    <button class="btn_cta btn_little" type="button" (click)="goToMangoPayDocuments()">{{ 'service.mangopay.documents.add.action.value' | translate }}</button>
                                </div>
                            </div>

                        </ng-container>
                    </div>

                </div>

                <!-- Avantages du rôle Créateur d'offre -->
                <app-role-advantages [advantages]="'role.synthesis.offerCreator' | translate"></app-role-advantages>
            </ng-container>

        </ng-container>
    </div>

</ng-template>

<!-- Gestion du compte -->

<ng-template #accountManagement>

    <div class="title_tab">
        <div class="row marges h_full x_between">
            <div class="col_content y_items_center">
                <span class="title" *ngIf="hasRole('ROLE_OFFER_CREATOR') && !hasRole('ROLE_OFFER_DISTRIBUTOR')">{{ 'user.distributor.settings.value' | translate }}</span>
                <span class="title" *ngIf="!(hasRole('ROLE_OFFER_CREATOR') && !hasRole('ROLE_OFFER_DISTRIBUTOR'))">{{ 'user.account.settings.value' | translate }}</span>
            </div>
            <div class="col_content y_items_center">
                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="content_tab">

        <!-- ETQ Admin du compte -->

        <ng-container *ngIf="isAccountAdmin()">

            <ng-container *ngIf="(societyAdminRequests$ | async) as societyAdminRequests">

                <ng-container *ngIf="societyAdminRequests.length">

                    <div class="row marges">
                        <div class="form_part">
                            <p class="title_part">{{ 'form.user.fields.admin.value' | translate}}</p>
                        </div>
                    </div>

                    <div *ngFor="let societyAdminRequest of societyAdminRequests" class="accept_admin">

                        <p>{{ 'user.account.admin.request.user.value' | translate:{
                            lastname: societyAdminRequest.user.lastName,
                            firstname: societyAdminRequest.user.firstName
                        } }}</p>

                        <div class="row marges">
                            <div class="col_content">
                                <button type="button" class="btn_cta btn_little" (click)="acceptAdminAccountRequestDialog(societyAdminRequest)">
                                    {{ 'user.account.admin.request.accept.action.value'|translate }}
                                </button>
                            </div>
                            <div class="col_content">
                                <button type="button" class="btn_cta btn_little btn_red" (click)="cancelAdminAccountRequestDialog(societyAdminRequest)">
                                    {{ 'user.account.admin.request.cancel.action.value'|translate }}
                                </button>
                            </div>
                        </div>

                    </div>

                    <br>

                </ng-container>

            </ng-container>

        </ng-container>

        <!-- ETQ Collaborateur -->

        <ng-container *ngIf="isCollaborator()">

            <ng-container *ngIf="userSocietyAdminRequests$ | async as requests">

                <!-- Requête déjà envoyée -->

                <ng-container *ngIf="requests.length > 0; else sendRequest">
                    <p>
                        <span>{{ 'user.account.admin.request.done.description.value' | translate }}</span>
                    </p>
                    <div *ngFor="let userSocietyAdminRequest of requests">

                        <button type="button" class="btn_cta btn_little" (click)="cancelAdminAccountRequestDialog(userSocietyAdminRequest)">
                            {{ 'user.account.admin.request.cancel.action.value'|translate }}
                        </button>

                    </div>

                    <br/>

                </ng-container>

                <!-- Aucune requête envoyée -->

                <ng-template #sendRequest>

                    <div class="row marges ligne_form">
                        <div class="form_part">
                            <p class="title_part">{{ 'user.account.admin.request.value.value' | translate}}</p>
                        </div>
                    </div>

                    <div>
                        <p>
                            <span>{{ 'user.account.admin.request.description.value' | translate }}</span>
                        </p>
                        <button type="button" class="btn_cta btn_little" (click)="adminAccountRequestDialog()">
                            {{ 'user.account.admin.request.action.value'|translate }}
                        </button>
                    </div>

                    <br>

                </ng-template>

            </ng-container>

        </ng-container>

        <!-- Préférence d'affichage -->

        <ng-container *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_FEDERATION', 'ROLE_INSTITUTIONAL'])">

            <div class="row marges">
                <div class="form_part">
                    <p class="title_part">{{ 'display.preference.value' | translate}}</p>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <div class="app-toggle">
                            <mat-slide-toggle [(ngModel)]="displayPreferenceNetworkOfferCreatorOffersEnabled" (toggleChange)="marketplacePreferenceForm.get('networkOfferCreators').patchValue([])">{{ 'offer.filter.networkOfferCreatorOffers.action.value' | translate}}</mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="displayPreferenceNetworkOfferCreatorOffersEnabled">

                <div class="ligne_form row marges">
                    <div class="col_12">
                        <app-core-network-offer-creator-collection-select [parentForm]="marketplacePreferenceForm" [formName]="'networkOfferCreators'"></app-core-network-offer-creator-collection-select>
                    </div>

                    <div class="col_12">
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon>
                            {{ 'user.account.marketplacePreferences.networkOfferCreator.help.value' | translate }}
                        </span>
                    </div>
                </div>

            </ng-container>

        </ng-container>

        <!-- Distribution des offres -->

        <ng-container [formGroup]="form" *ngIf="hasRole('ROLE_OFFER_CREATOR') && isAccountAdmin()">

            <div class="row marges ligne_form">
                <div class="form_part">
                    <p class="title_part">{{ 'offer.plural.distribution.value' | translate}}</p>
                </div>
            </div>

            <div formGroupName="society">

                <!-- Acceptation automatique des offres -->

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <div class="app-toggle">
                                <mat-slide-toggle formControlName="automaticCatalogOffersAcceptance">{{'society.automaticCatalogOffersAcceptance.value' | translate}}</mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>

                <br>

            </div>

        </ng-container>

        <!-- Nouvelles offres -->

        <ng-container *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_FEDERATION', 'ROLE_INSTITUTIONAL']) && isAccountAdmin()">

            <div class="row marges ligne_form">
                <div class="form_part">
                    <p class="title_part">{{ 'offer.plural.new.value' | translate}}</p>
                </div>
            </div>

            <div class="row marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="app-toggle">
                            <mat-slide-toggle [ngModel]="notificationCreateEnabled" (change)="notificationEnabledUpdate('createOffer', $event)">{{'society.notification.offerCreate.value' | translate}}</mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>

            <br>

        </ng-container>

        <ng-container *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_FEDERATION', 'ROLE_INSTITUTIONAL', 'ROLE_PROVIDER']) && isAccountAdmin()">

            <div class="row marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="app-toggle">
                            <mat-slide-toggle [checked]="user.getValue().society.autoAddCatalog" (change)="changeAutoAddCatalogToggle('autoAddCatalog', $event)" [disabled]="!isAutoAddCatalogAllowed()">
                                {{ 'society.autoAddCatalog.value' | translate }}
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="!isAutoAddCatalogAllowed()">

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <span class="message-help"><mat-icon>info</mat-icon>
                                {{ 'society.noMarkupWarning.value' | translate }}
                            </span>
                        </div>
                    </div>
                </div>

            </ng-container>

            <br>

        </ng-container>

        <!-- Modifications sur les offres -->

        <ng-container *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_FEDERATION', 'ROLE_INSTITUTIONAL', 'ROLE_PROVIDER']) && isAccountAdmin()">

            <div class="row marges">
                <div class="form_part">
                    <p class="title_part">{{ 'offer.plural.update.value' | translate}}</p>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <div class="app-toggle">
                            <mat-slide-toggle [(ngModel)]="notificationEnabled" (change)="notificationEnabledUpdate('updateOffer', $event)">{{'society.notification.notificationEnabled.value' | translate}}</mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container [formGroup]="form">
                <div formGroupName="society" class="row marges ligne_form">
                    <div class="col_12">
                        <ng-container formArrayName="notification" *ngIf="notificationEnabled">
                            <div class="form-group">
                                <p class="title-form">{{ 'society.notification.TarifModification.value' | translate }}</p>
                            </div>
                            <div class="form-group">
                                <input type="number" value="" placeholder=" " class="form-control" formControlName="offerPriceVariation" id="offerPriceVariation">
                                <label for="offerPriceVariation">{{ 'society.notification.offerPriceVariation.value' | translate }}</label>
                            </div>

                            <div class="form-group">
                                <p class="title-form">{{ 'society.notification.TextModification.value' | translate }}</p>
                            </div>
                            <div class="form-group">
                                <div class="app-toggle">
                                    <mat-slide-toggle formControlName="offerTextUpdate">{{'society.notification.offerTextUpdate.value' | translate}}</mat-slide-toggle>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="app-toggle">
                                    <mat-slide-toggle formControlName="offerContentUpdate">{{'society.notification.offerContentUpdate.value' | translate}}</mat-slide-toggle>
                                </div>
                            </div>

                            <div class="form-group">
                                <p class="title-form">{{ 'society.notification.giftVoucherModification.value' | translate }}</p>
                            </div>

                            <div class="form-group">
                                <div class="app-toggle">
                                    <mat-slide-toggle formControlName="giftVoucherUpdate">{{'society.notification.giftVoucherUpdate.value' | translate}}</mat-slide-toggle>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                </div>

                <br>
            </ng-container>
        </ng-container>

    </div>

</ng-template>

<ng-template #card>

    <div class="title_tab">
        <div class="row marges h_full x_between">
            <div class="col_content y_items_center">
                <span class="title">{{ 'card.myself.value' | translate }}</span>
            </div>
            <div class="col_content y_items_center">
                <button class="btn_cta btn_little" type="button" (click)="submit({ fragment: 'cardPreview' })">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="content_tab my_card">

        <mat-tab-group #tabGroupCard>
            <mat-tab [label]="'data.plural.declarative.value' | translate">
                <ng-container *ngTemplateOutlet="cardData"></ng-container>
            </mat-tab>
            <ng-container>
                <mat-tab [label]="'profile.mine.see.action.value' | translate">
                    <ng-container *ngTemplateOutlet="cardPreview"></ng-container>
                </mat-tab>
            </ng-container>
        </mat-tab-group>

        <ng-template #cardData>

            <ng-container [formGroup]="form">

                <ng-container *ngIf="!hasOfferSubscription">

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <span class="message-help"><mat-icon>info</mat-icon>{{ 'society.card.disabled.description.value' | translate }}</span>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <span class="message-help"><mat-icon>info</mat-icon>{{ 'society.data.warning.value' | translate }}</span>
                        </div>
                    </div>
                </div>

                <ng-container formGroupName="society">

                    <div class="ligne_form">

                        <div class="form_part">

                            <p class="title_part">{{ 'logo.mine.value' | translate }}</p>

                            <div class="row marges ligne_form">
                                <div class="col_12">

                                    <!-- Logo -->

                                    <div class="form-group">
                                        <label class="required">{{ 'file.add.action.logo.value' | translate }}</label>
                                        <form-field-file [config]="societyLogoConfig" [form]="societyForm" [file]="societyForm.get('logo').value"></form-field-file>
                                        <mat-error *ngIf="societyForm.get('logo').invalid">
                                            <div class="invalid-feedback">
                                                <div *ngIf="societyForm.get('logo').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                        <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.user.fields.logo.help.' + (hasRole('ROLE_OFFER_CREATOR') ? 'offerCreator' : 'distributor') + '.value' | translate }}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="ligne_form">

                        <div class="form_part">

                            <p class="title_part">{{ 'visual.plural.mine.value' | translate }}</p>

                            <div class="col_12 added_bottom_margin">
                                <div class="form-group">
                                    <span class="message-help"><mat-icon>info</mat-icon>{{ 'account.visual.tooltip.value' | translate }}</span>
                                </div>
                            </div>

                            <div formArrayName="pictures" class="add_picture collection">
                                <div class="row marges">
                                    <div *ngFor="let picture of sortedPictureControls; let j=index" class="col_6">
                                        <div class="removable_item">
                                            <ng-container [formGroupName]="j">
                                                <div class="ligne_form row marges">
                                                    <div class="col_content">
                                                        <div class="form-group">
                                                            <form-field-image [config]="picturesConfig" [form]="picture.get('image')" (imageAdded)="pictureCopyright.readOnly = false" (imageDeleted)="pictureCopyright.readOnly = true"></form-field-image>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ligne_form row marges">
                                                    <div class="col_12">
                                                        <div class="form-group">
                                                            <input #pictureCopyright type="text" value="" placeholder=" " class="form-control" formControlName="copyright" id="copyright_{{j}}" required="required" [readOnly]="true">
                                                            <label for="copyright_{{j}}">{{ 'offer.form.fields.copyright.value' | translate }}</label>
                                                            <mat-error *ngIf="societyPicturesForm.controls[j].get('copyright').errors && (societyPicturesForm.controls[j].get('copyright').dirty || societyPicturesForm.controls[j].get('copyright').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="societyPicturesForm.controls[j].get('copyright').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="societyPicturesForm.controls.length > 1">
                                                    <button type="button" class="btn_cta btn_lines btn_small move_item up" [disabled]="!isDecreasePicturePositionAllowed(j)" (click)="decreasePicturePosition(j)">
                                                        <mat-icon>arrow_back</mat-icon>
                                                    </button>
                                                    <button type="button" class="btn_cta btn_lines btn_small move_item down" [disabled]="!isIncreasePicturePositionAllowed(j)" (click)="increasePicturePosition(j)">
                                                        <mat-icon>arrow_forward</mat-icon>
                                                    </button>
                                                </ng-container>
                                                <button type="button" class="btn_cta btn_red btn_small remove_item" (click)="removePicture(j)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="ligne_form">
                                    <button class="btn_cta btn_little" type="button" (click)="addPicture()">{{ 'offer.form.fields.picture.value' | translate }}</button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="ligne_form">

                        <div class="form_part">

                            <p class="title_part">{{ 'information.plural.mine.value' | translate }}</p>

                        </div>
                    </div>

                    <ng-container formGroupName="information">

                        <div class="row marges ligne_form">

                            <div class="col_12">

                                <div class="mat-tab-translations">

                                    <ng-container formArrayName="translations">

                                        <mat-tab-group [@.disabled]="true">

                                            <div *ngFor="let _ of societyInformationTranslationBuilder.itemsControl.controls; let it = index">

                                                <mat-tab label="{{ societyInformationTranslationBuilder.getLocaleLabel(societyInformationTranslationBuilder.getItemControl(it).value.locale) | translate }}">

                                                    <ng-container [formGroupName]="societyInformationTranslationBuilder.indexAsString(it)">

                                                        <div class="row marges ligne_form">
                                                            <div class="col_12">
                                                                <div class="form-group">
                                                                    <label>{{ 'presentation.value' | translate }}</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row marges ligne_form">
                                                            <div class="col_12">

                                                                <!-- Présentation -->

                                                                <div class="form-group">
                                                                    <app-ckeditor [form]="societyInformationTranslationBuilder.getItemControlByLocale(societyInformationTranslationBuilder.getItemControl(it).value.locale)" [config]="presentationEditorConfig"></app-ckeditor>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ng-container>
                                                </mat-tab>
                                            </div>
                                        </mat-tab-group>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <div class="row marges ligne_form">
                            <div class="col_12">

                                <!-- Labellisé RSE -->

                                <div class="form-group">
                                    <label>{{'rse.labelled.value' | translate}}</label>
                                    <div class="radio">
                                        <input type="radio" [value]="false" formControlName="hasRseLabel" class="form-control" id="hasRseLabelNo">
                                        <label for="hasRseLabelNo">{{'form.user.fields.no.value' | translate}}</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" [value]="true" formControlName="hasRseLabel" class="form-control" id="hasRseLabelYes">
                                        <label for="hasRseLabelYes">{{'form.user.fields.yes.value' | translate}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="societyInformationForm.get('hasRseLabel').value">

                            <div class="row marges ligne_form">

                                <div class="col_12">

                                    <div class="mat-tab-translations">

                                        <ng-container formArrayName="translations">

                                            <mat-tab-group [@.disabled]="true">

                                                <div *ngFor="let _ of societyInformationTranslationBuilder.itemsControl.controls; let it = index">

                                                    <mat-tab label="{{ societyInformationTranslationBuilder.getLocaleLabel(societyInformationTranslationBuilder.getItemControl(it).value.locale) | translate }}">

                                                        <ng-container [formGroupName]="societyInformationTranslationBuilder.indexAsString(it)">

                                                            <div class="row marges ligne_form">

                                                                <div class="col_12">

                                                                    <!-- Label RSE -->

                                                                    <div class="form-group">
                                                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="rseLabel" id="rseLabel">
                                                                        <label for="rseLabel">{{ 'rseLabel.value' | translate }}</label>
                                                                        <mat-error *ngIf="societyInformationTranslationBuilder.getItemControl(it).get('rseLabel').errors && (societyInformationTranslationBuilder.getItemControl(it).get('rseLabel').dirty || societyInformationTranslationBuilder.getItemControl(it).get('rseLabel').touched)">
                                                                            <div class="invalid-feedback">
                                                                                <div *ngIf="societyInformationTranslationBuilder.getItemControl(it).get('rseLabel').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                            </div>
                                                                        </mat-error>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </ng-container>
                                                    </mat-tab>
                                                </div>
                                            </mat-tab-group>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="row marges ligne_form">

                            <div class="col_6">

                                <!-- Langues parlées -->

                                <div class="form-group">
                                    <mat-select formControlName="spokenLanguages" multiple>
                                        <mat-option *ngFor="let language of societySpokenLanguages;" [value]="language">{{ ('locale.' + language + '.value') | translate }}</mat-option>
                                        <mat-option [value]="otherSpokenLanguagesIdentifier">{{ 'other.value' | translate }}</mat-option>
                                    </mat-select>
                                    <mat-label>{{ 'form.user.fields.spokenLanguages.value' | translate }}</mat-label>
                                    <mat-error *ngIf="societyInformationForm.get('spokenLanguages').errors && (societyInformationForm.get('spokenLanguages').dirty || societyInformationForm.get('spokenLanguages').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="societyInformationForm.get('spokenLanguages').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                        </div>

                        <ng-container *ngIf="societyInformationForm.get('spokenLanguages').value.includes(otherSpokenLanguagesIdentifier)">

                            <div class="row marges ligne_form">

                                <div class="col_12">

                                    <div class="mat-tab-translations">

                                        <ng-container formArrayName="translations">

                                            <mat-tab-group [@.disabled]="true">

                                                <div *ngFor="let _ of societyInformationTranslationBuilder.itemsControl.controls; let it = index">

                                                    <mat-tab label="{{ societyInformationTranslationBuilder.getLocaleLabel(societyInformationTranslationBuilder.getItemControl(it).value.locale) | translate }}">

                                                        <ng-container [formGroupName]="societyInformationTranslationBuilder.indexAsString(it)">

                                                            <div class="row marges ligne_form">

                                                                <div class="col_12">

                                                                    <!-- Langues parlées -->

                                                                    <div class="form-group">
                                                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="spokenLanguages" id="translationSpokenLanguages">
                                                                        <label for="translationSpokenLanguages">{{ 'language.plural.other.value' | translate }}</label>
                                                                        <mat-error *ngIf="societyInformationTranslationBuilder.getItemControl(it).get('spokenLanguages').invalid && (societyInformationTranslationBuilder.getItemControl(it).get('spokenLanguages').dirty || societyInformationTranslationBuilder.getItemControl(it).get('spokenLanguages').touched)">
                                                                            <div class="invalid-feedback">
                                                                                <div *ngIf="societyInformationTranslationBuilder.getItemControl(it).get('spokenLanguages').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                            </div>
                                                                        </mat-error>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </ng-container>

                                                    </mat-tab>

                                                </div>

                                            </mat-tab-group>

                                        </ng-container>

                                    </div>

                                </div>

                            </div>

                        </ng-container>

                        <ng-container *ngIf="user.getValue().society.networkOfferCreators.length">

                            <div class="row marges ligne_form">
                                <div class="col_12">

                                    <!-- Réseaux de créateurs d'offres -->

                                    <div class="form-group">
                                        <label>{{ 'society.networkOfferCreator.member.value' | translate }}</label>
                                        <br>
                                        <ul>
                                            <li *ngFor="let network of networkOfferCreatorNames">{{ network }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                        <div class="ligne_form">

                            <div class="form_part">

                                <p class="title_part">{{ 'offer.plural.mine.value' | translate }}</p>
                            </div>
                        </div>

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <span class="message-help"><mat-icon>info</mat-icon>{{ 'society.data.information.value' | translate }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="row marges ligne_form">

                            <div class="col_6">

                                <!-- Cibles clientèle -->

                                <div class="form-group">
                                    <mat-select multiple [compareWith]="compareById" formControlName="customerTypologies">
                                        <mat-option *ngFor="let customerTypology of customerTypologies" [value]="customerTypology">
                                            {{ translationService.getFallbackTranslation(customerTypology.translations).name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label>{{ 'customer.target.mine.plural.value' | translate }}</mat-label>
                                    <span class="message-help"><mat-icon>info</mat-icon>{{ 'customer.target.plural.help.value' | translate }}</span>
                                </div>

                            </div>

                        </div>

                        <div class="row marges ligne_form">

                            <div class="col_6">

                                <!-- Vos destinations -->

                                <div class="form-group">
                                    <mat-select multiple [compareWith]="compareById" formControlName="regions">
                                        <mat-option #allRegionsOption [value]="{ id: null }" (click)="handleRegion(allRegionsOption)"><span style="font-weight: bold">{{ 'france.all.value' | translate }}</span></mat-option>
                                        <mat-option #regionOption *ngFor="let region of regions" [value]="region" (click)="handleRegion(regionOption)">
                                            {{ translationService.getFallbackTranslation(region.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label>{{ 'destination.yours.plural.value' | translate }}</mat-label>
                                </div>

                            </div>

                        </div>

                        <div class="row marges ligne_form">

                            <div class="col_6">

                                <!-- Types d’offre -->

                                <div class="form-group">
                                    <mat-select multiple formControlName="durationTypes">
                                        <mat-option *ngFor="let type of durationTypes" [value]="type">
                                            {{ ('offer.type.' + type + '.value') | translate }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label>{{ 'offer.plural.type.plural.value' | translate }}</mat-label>
                                </div>

                            </div>

                        </div>

                    </ng-container>

                </ng-container>

            </ng-container>

        </ng-template>

        <ng-template #cardPreview>

            <div class="page_offer page_offer_creator" style="margin-top: -12px; padding: 0 12px 0 12px;">

                <app-core-offer-creator-item *ngIf="(cardSociety$ | async) as cardSociety" [item]="cardSociety"></app-core-offer-creator-item>
            </div>

        </ng-template>

    </div>

</ng-template>

<!-- API Ty-Win -->

<ng-template #tywinApi>

    <ng-container [formGroup]="form">

        <div class="title_tab">
            <div class="row marges h_full x_between">
                <div class="col_content y_items_center">
                    <span class="title">{{ 'api.tywin.value' | translate }}</span>
                </div>
                <div class="col_content y_items_center">
                    <button class="btn_cta btn_little" type="submit">
                        {{ 'form.submit.action.value'|translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="content_tab">

            <div class="row ligne_form">
                <p>
                    <span>{{ 'user.account.api.tywin.description.value' | translate }}</span>
                </p>
            </div>

            <div class="add_role">
                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <span><label>{{ 'user.account.api.version.current.value' | translate }} : <span class="modify">{{ tywinApiVersion }}</span></label></span>
                        </div>
                    </div>
                </div>
                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <span><label>{{ 'user.account.api.token.value' | translate }} : <span class="modify">{{ tywinApiToken }}</span></label></span>
                        </div>
                    </div>
                </div>
                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <span><label>{{ 'user.account.api.documentation.value' | translate }} : <a [href]="tywinApiDocumentationLink" target="_blank"><span class="modify">{{ tywinApiDocumentationLink }}</span></a></label></span>
                        </div>
                    </div>
                </div>
                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <span><label>{{ 'user.account.api.environment.production.beta.value' | translate }} : <a [href]="tywinApiProductionBetaEnvironment" target="_blank"><span class="modify">{{ tywinApiProductionBetaEnvironment }}</span></a></label></span>
                        </div>
                    </div>
                </div>
            </div>

            <br>

            <div class="ligne_form row marges">

                <div class="form_part col_12">

                    <p class="title_part">{{ 'ipAddress.plural.management.value' | translate }}</p>

                    <div class="row ligne_form">
                        <p>
                            <span>{{ 'user.account.api.address.description.value' | translate }}</span>
                        </p>
                    </div>

                    <div class="ligne_form row marges" *ngIf="ipsControl.errors && (ipsControl.dirty || ipsControl.touched)">

                        <div class="col_12">

                            <mat-error>
                                <div class="invalid-feedback">
                                    <div *ngIf="ipsControl.errors.invalidUnicity">{{ 'ipAddress.form.control.error.unicity.value' | translate }}</div>
                                </div>
                            </mat-error>

                        </div>
                    </div>

                    <div class="ligne_form row marges" *ngIf="ipControls.length">

                        <div class="col_12">

                            <ng-container formArrayName="ips">

                                <div *ngFor="let ipControl of ipControls; let i = index" class="row marges ligne_form">

                                    <ng-container [formGroupName]="indexAsString(i)">

                                        <!-- Adresse IP -->

                                        <div class="col_3">
                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="ip-address-{{i}}">
                                                <label for="ip-address-{{i}}">{{ 'ipAddress.value' | translate }}</label>
                                                <mat-error *ngIf="ipControl.get('address').errors">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="ipControl.get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        <div *ngIf="ipControl.get('address').errors.isInvalid">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col_3 remove_ip" *ngIf="ipControl.get('isDeletable').value">
                                            <div class="form-group">
                                                <button mat-button type="button" (click)="removeIpControl(i)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </div>

                                    </ng-container>

                                </div>

                            </ng-container>

                        </div>
                    </div>

                    <div class="ligne_form">
                        <button class="btn_cta btn_little" type="button" (click)="addIpControl()">{{ 'ipAddress.add.action.value' | translate }}</button>
                    </div>
                </div>

            </div>

        </div>

    </ng-container>

</ng-template>

<!-- Désactivation du compte -->

<ng-template #deactivateAccount>

    <div class="title_tab">
        <div class="row marges h_full x_between">
            <div class="col_content y_items_center">
                <span class="title">{{ 'user.account.deactivate.value' | translate }}</span>
            </div>
        </div>
    </div>

    <div class="content_tab desactivate_account">

        <ng-container [ngSwitch]="true">

            <!-- Rôle Créateur d'offre & Distributeur d'offre -->

            <div *ngSwitchCase="hasRole('ROLE_OFFER_CREATOR') && hasRole('ROLE_OFFER_DISTRIBUTOR')">
                <p>
                    <span>{{ 'user.account.deactivate.warn.role.offerCreatorDistributor.self.value' | translate }}</span>
                </p>
                <button type="button" class="btn_cta btn_little" (click)="desactivativationAccountDialog()" *ngIf="!societyDelete">
                    {{'user.account.deactivate.action.value'|translate }}
                </button>

                <div *ngIf="societyDelete && societyDeleteDate" class="info_desactivate ligne_form">

                    <div class="row">

                        <div class="col_6 y_items_center x_center">
                            <p>{{'subscription.deleteAccount.value' | translate : {date: societyDeleteDate | date : 'dd/MM/yyyy' : null : localeId}  }}</p>
                        </div>

                        <!-- Annulation de la demande de désactivation --->

                        <div class="col_6 y_items_center x_center">
                            <button type="button" class="btn_cta btn_little" (click)="cancelDeactivationDialog()">{{'subscription.deleteAccount.cancel.value' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Rôle Créateur d'offre -->

            <div *ngSwitchCase="hasRole('ROLE_OFFER_CREATOR') && !hasRole('ROLE_OFFER_DISTRIBUTOR')">
                <p>
                    <span>{{ 'user.account.deactivate.warn.role.offerCreator.self.value' | translate }}</span>
                </p>
                <button type="button" class="btn_cta btn_little" (click)="desactivativationAccountDialog()" *ngIf="!societyDelete">
                    {{'user.account.deactivate.action.value'|translate }}
                </button>

                <div *ngIf="societyDelete && societyDeleteDate" class="info_desactivate ligne_form">

                    <div class="row">

                        <div class="col_6 y_items_center x_center">
                            <p>{{'subscription.deleteAccount.value' | translate : {date: societyDeleteDate | date : 'dd/MM/yyyy' : null : localeId}  }}</p>
                        </div>

                        <!-- Annulation de la demande de désactivation --->

                        <div class="col_6 y_items_center x_center">
                            <button type="button" class="btn_cta btn_little" (click)="cancelDeactivationDialog()">{{'subscription.deleteAccount.cancel.value' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Rôle Distributeur d'offre -->

            <div *ngSwitchCase="hasRole('ROLE_OFFER_DISTRIBUTOR') && !hasRole('ROLE_OFFER_CREATOR')">
                <p>
                    <span>{{ 'user.account.deactivate.warn.role.offerDistributor.self.value' | translate }}</span>
                </p>
                <button type="button" class="btn_cta btn_little" (click)="desactivateAccountDialog()">
                    {{'user.account.deactivate.action.value'|translate }}
                </button>
            </div>

            <!-- Rôle Prestataire -->

            <div *ngSwitchCase="hasRole('ROLE_PROVIDER')">
                <p>
                    <span>{{ 'user.account.deactivate.warn.role.provider.self.value' | translate }}</span>
                </p>
                <button type="button" class="btn_cta btn_little" (click)="desactivateAccountDialog()">
                    {{'user.account.deactivate.action.value'|translate }}
                </button>
            </div>

            <!-- Rôle Institutionel -->

            <div *ngSwitchCase="hasRole('ROLE_INSTITUTIONAL')">
                <p>
                    <span>{{ 'user.account.deactivate.warn.role.provider.self.value' | translate }}</span>
                </p>
                <button type="button" class="btn_cta btn_little" (click)="desactivateAccountDialog()">
                    {{'user.account.deactivate.action.value'|translate }}
                </button>
            </div>

            <!-- Rôle Fédération -->

            <div *ngSwitchCase="hasRole('ROLE_FEDERATION')">
                <p>
                    <span>{{ 'user.account.deactivate.warn.role.provider.self.value' | translate }}</span>
                </p>
                <button type="button" class="btn_cta btn_little" (click)="desactivateAccountDialog()">
                    {{'user.account.deactivate.action.value'|translate }}
                </button>
            </div>

            <img src="assets/images/bg_desactivate.svg" alt="" title=""/>

        </ng-container>
    </div>

</ng-template>

<!-- Représentant légal -->

<ng-template #legalRepresentative>

    <div class="title_tab">
        <div class="row marges h_full x_between">
            <div class="col_content y_items_center">
                <span class="title">{{ 'society.legalRepresentative.value' | translate }}</span>
            </div>
            <div class="col_content y_items_center">
                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="content_tab">

        <!-- Représentant légal -->

        <ng-container [formGroup]="form">

            <ng-container formGroupName="society">

                <div formGroupName="legalRepresentative">

                    <div class="row marges ligne_form">
                        <div class="col_12">

                            <!-- Civilité -->

                            <div class="form-group">
                                <label>{{'form.user.fields.civility.title.value' | translate}}</label>
                                <div class="radio">
                                    <input type="radio" [value]="1" formControlName="civility" class="form-control" id="legalRepresentative-civility-mister">
                                    <label for="legalRepresentative-civility-mister">{{'form.user.fields.civility.mister.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" [value]="2" formControlName="civility" class="form-control" id="legalRepresentative-civility-madam">
                                    <label for="legalRepresentative-civility-madam">{{'form.user.fields.civility.madam.value' | translate}}</label>
                                </div>
                                <mat-error *ngIf="societyLegalRepresentativeControl.get('civility').errors && (societyLegalRepresentativeControl.get('civility').dirty || societyLegalRepresentativeControl.get('civility').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="societyLegalRepresentativeControl.get('civility').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_12 col_md_4">

                            <!-- Nom -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="lastName" id="legalRepresentativeLastName" required="required">
                                <label for="legalRepresentativeLastName">{{'form.user.fields.lastname.value' | translate}}</label>
                                <mat-error *ngIf="societyLegalRepresentativeControl.get('lastName').errors && (societyLegalRepresentativeControl.get('lastName').dirty || societyLegalRepresentativeControl.get('lastName').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="societyLegalRepresentativeControl.get('lastName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                        <div class="col_12 col_md_4">

                            <!-- Prénom -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="firstName" id="legalRepresentativeFirstName" required="required">
                                <label for="legalRepresentativeFirstName">{{'form.user.fields.firstname.value' | translate}}</label>
                                <mat-error *ngIf="societyLegalRepresentativeControl.get('firstName').errors && (societyLegalRepresentativeControl.get('firstName').dirty || societyLegalRepresentativeControl.get('firstName').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="societyLegalRepresentativeControl.get('firstName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                        <div class="col_12 col_md_4">

                            <!-- Téléphone -->

                            <div class="form-group">

                                <app-core-intl-tel-input [formGroup]="societyLegalRepresentativeControl" [controlName]="'phone'" customPlaceholder="{{'form.user.fields.legalRepresentative.phone.value' | translate}}"></app-core-intl-tel-input>

                                <mat-error *ngIf="societyLegalRepresentativeControl.get('phone').errors && (societyLegalRepresentativeControl.get('phone').dirty || societyLegalRepresentativeControl.get('phone').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="societyLegalRepresentativeControl.get('phone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        <div *ngIf="societyLegalRepresentativeControl.get('phone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_4">

                            <!-- Nationalité -->

                            <div class="form-group">
                                <select class="form-control" id="legalRepresentativeNationality" formControlName="nationality" required="required">
                                    <option *ngFor="let country of countries$ | async" [value]="country.code">
                                        {{ translationService.getFallbackTranslation(country.translations).name }}
                                    </option>
                                </select>
                                <label class="required" for="legalRepresentativeNationality">{{ 'form.user.fields.nationality.value' | translate }}</label>
                                <mat-error
                                    *ngIf="societyLegalRepresentativeControl.get('nationality').invalid && (societyLegalRepresentativeControl.get('nationality').dirty || societyLegalRepresentativeControl.get('nationality').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="societyLegalRepresentativeControl.get('nationality').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                        <div class="col_12 col_md_4">

                            <!-- Date de naissance -->

                            <app-input-date [form]="societyLegalRepresentativeControl" [config]="birthDayConfig"></app-input-date>
                        </div>
                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_6 y_items_center">
                            <app-core-google-places-location [fields]="getAddressLocationFields(societyLegalRepresentativeControl)"></app-core-google-places-location>
                        </div>

                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_12 col_md_6">

                            <!-- Adresse -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="legalRepresentativeAddress" required="required">
                                <label for="legalRepresentativeAddress">{{'form.user.fields.address.value' | translate}}</label>
                                <mat-error
                                    *ngIf="societyLegalRepresentativeControl.get('address').errors && (societyLegalRepresentativeControl.get('address').dirty || societyLegalRepresentativeControl.get('address').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="societyLegalRepresentativeControl.get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                        <div class="col_12 col_md_6">

                            <!-- Complément d'adresse -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalAddress" id="legalRepresentativeAdditionalAddress">
                                <label for="legalRepresentativeAdditionalAddress">{{'form.user.fields.additionalAddress.value' | translate}}</label>
                                <mat-error
                                        *ngIf="societyLegalRepresentativeControl.get('additionalAddress').errors && (societyLegalRepresentativeControl.get('additionalAddress').dirty || societyLegalRepresentativeControl.get('additionalAddress').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="societyLegalRepresentativeControl.get('additionalAddress').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_12 col_md_6">

                            <!-- Code postal -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="legalRepresentativeZipcode" required="required">
                                <label for="legalRepresentativeZipcode">{{'form.user.fields.zipcode.value' | translate}}</label>
                                <mat-error
                                    *ngIf="societyLegalRepresentativeControl.get('zipcode').errors && (societyLegalRepresentativeControl.get('zipcode').dirty || societyLegalRepresentativeControl.get('zipcode').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="societyLegalRepresentativeControl.get('zipcode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        <div *ngIf="societyLegalRepresentativeControl.get('zipcode').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                        <div class="col_12 col_md_6">

                            <!-- Ville -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="legalRepresentativeCity" required="required">
                                <label for="legalRepresentativeCity">{{'form.user.fields.city.value' | translate}}</label>
                                <mat-error
                                    *ngIf="societyLegalRepresentativeControl.get('city').errors && (societyLegalRepresentativeControl.get('city').dirty || societyLegalRepresentativeControl.get('city').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="societyLegalRepresentativeControl.get('city').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_12 col_md_6">

                            <!-- Région -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="region" id="legalRepresentativeRegion" required="required">
                                <label for="legalRepresentativeRegion">{{'form.user.fields.region.value' | translate}}</label>
                                <mat-error
                                        *ngIf="societyLegalRepresentativeControl.get('region').errors && (societyLegalRepresentativeControl.get('region').dirty || societyLegalRepresentativeControl.get('region').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="societyLegalRepresentativeControl.get('region').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                        <div class="col_12 col_md_6">

                            <!-- Pays -->

                            <div class="form-group">
                                <select class="form-control" id="legalRepresentativeCountry" formControlName="country" required="required">
                                    <option *ngFor="let country of countries$ | async" [value]="country.code">
                                        {{ translationService.getFallbackTranslation(country.translations).name }}
                                    </option>
                                </select>
                                <label class="required" for="legalRepresentativeCountry">{{ 'form.user.fields.country.value' | translate }}</label>
                                <mat-error
                                        *ngIf="societyLegalRepresentativeControl.get('country').invalid && (societyLegalRepresentativeControl.get('country').dirty || societyLegalRepresentativeControl.get('country').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="societyLegalRepresentativeControl.get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>
                <p>{{ 'services.form.file.infos.society.value' | translate}} </p>
            </ng-container>

        </ng-container>
    </div>

</ng-template>
