<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">

        <div class="row marges">

            <div class="col y_items_center">
                <h1 class="title">{{ 'tender.read.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour à la liste des appels d'offres -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ ('tender.list.back' + (userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']) ? '' : '.self') + '.value') | translate }}
                </button>

                <ng-container *ngIf="updateAllowed">

                    <!-- Soumission -->

                    <button class="btn_cta btn_little" type="submit">
                        {{  submitLabel }}
                    </button>

                </ng-container>

            </div>

        </div>

    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group #tabGroup>
        <mat-tab [label]="'generalData.value' | translate" data-tag="generalData">
            <ng-container *ngTemplateOutlet="generalData"></ng-container>
        </mat-tab>
        <mat-tab [label]="'tender.data.plural.value' | translate" data-tag="tenderData">
            <ng-container *ngTemplateOutlet="tenderData"></ng-container>
        </mat-tab>
        <ng-container *ngIf="(userService.hasRole(currentUser, 'ROLE_OFFER_CREATOR') && !isMine) && form.get('offerCreatorStatus').value === 'reply'">
            <mat-tab [label]="'offer.list.selection.value' | translate" data-tag="offersSelection">
                <ng-container *ngTemplateOutlet="offersSelection"></ng-container>
            </mat-tab>
        </ng-container>
        <ng-container *ngIf="isMine">
            <mat-tab [label]="'offerCreator.plural.selection.value' | translate" data-tag="offerCreatorsSelection">
                <ng-container *ngTemplateOutlet="offerCreatorsSelection"></ng-container>
            </mat-tab>
        </ng-container>
        <ng-container *ngIf="isMine || userService.hasOneOfThisRoles(currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])">
            <mat-tab [label]="'offerCreator.plural.response.plural.value' | translate" data-tag="offerCreatorsResponse">
                <ng-container *ngTemplateOutlet="offerCreatorsResponse"></ng-container>
            </mat-tab>
            <mat-tab [label]="'offers.plural.proposed.value' | translate" data-tag="proposedOffers">
                <ng-container *ngTemplateOutlet="proposedOffers"></ng-container>
            </mat-tab>
        </ng-container>
    </mat-tab-group>

</form>

<ng-template #generalData>

    <ng-container [formGroup]="form">

        <div class="form_part limited">

            <mat-accordion class="gradient">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ 'tender.follow.mine.value' | translate }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>

                        <ng-container *ngIf="userService.hasRole(currentUser, 'ROLE_OFFER_CREATOR') && !isMine">

                            <!-- Statut créateur d'offres -->

                            <div class="ligne_form row marges">

                                <div class="col_12 form-group">

                                    <div class="framed_green">

                                        <p class="read">
                                            <strong class="subtitle_green">{{ 'offerCreator.status.value' | translate }}</strong>
                                        </p>

                                        <br/>

                                        <div class="form-group">

                                            <div class="radio">
                                                <input type="radio" [value]="'reply'" formControlName="offerCreatorStatus" class="form-control" id="offerCreatorStatusReply">
                                                <label for="offerCreatorStatusReply">{{ 'status.reply.action.mine.value' | translate }}</label>
                                            </div>

                                            <div class="radio">
                                                <input type="radio" [value]="'no-reply'" formControlName="offerCreatorStatus" class="form-control" id="offerCreatorStatusNoReply">
                                                <label for="offerCreatorStatusNoReply">{{ 'status.no-reply.action.mine.value' | translate }}</label>
                                            </div>

                                            <ng-container *ngIf="form.get('offerCreatorStatus').value === 'reply'">
                                                <span class="message-help"><mat-icon>info</mat-icon>{{ 'tender.status.reply.help.value' | translate }}</span>
                                            </ng-container>

                                            <ng-container *ngIf="form.get('offerCreatorStatus').value === 'no-reply'">
                                                <span class="message-help"><mat-icon>info</mat-icon>{{ 'tender.status.no-reply.help.value' | translate }}</span>
                                            </ng-container>

                                        </div>

                                    </div>
                                </div>

                            </div>

                            <ng-container *ngIf="form.get('offerCreatorStatus').value === 'no-reply'">

                                <div class="ligne_form row marges">

                                    <div class="col_12">

                                        <div class="form-group">

                                            <div class="form-group">
                                                <textarea placeholder=" " class="form-control" formControlName="offerCreatorNoReplyReason" id="offerCreatorNoReplyReason"></textarea>
                                                <label for="offerCreatorNoReplyReason">{{ 'noReply.reason.value' | translate }}</label>
                                                <mat-error *ngIf="form.get('offerCreatorNoReplyReason').errors && (form.get('offerCreatorNoReplyReason').dirty || form.get('offerCreatorNoReplyReason').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="form.get('offerCreatorNoReplyReason').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </ng-container>

                            <!-- Collaborateur en charge de la demande -->

                            <div class="ligne_form row_marges">

                                <div class="col_12">

                                    <div class="form-group">

                                        <p class="read">
                                            <strong>{{ 'tender.offerCreator.responsibleUser.value' | translate }}</strong>
                                        </p>

                                        <div class="select_responsible">
                                            <app-core-select-search [configuration]="collaboratorSearchConfiguration" [sourceCallback]="collaboratorSearchSourceCallback" [formGroupReference]="form" [formControlNameReference]="'offerCreatorResponsibleUser'"></app-core-select-search>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="!!currentOfferCreatorResponsibleUser">

                                <div class="ligne_form row_marges">

                                    <div class="col_12">

                                        <div class="bloc_recap">

                                            <div class="form-group">

                                                <!-- Nom -->

                                                <ng-container *ngIf="currentOfferCreatorResponsibleUser?.firstName || currentOfferCreatorResponsibleUser?.lastName">

                                                    <p class="read">
                                                        <strong>{{ 'collaborator.name.value' | translate }} : </strong>
                                                        {{ currentOfferCreatorResponsibleUser?.firstName }} {{ currentOfferCreatorResponsibleUser?.lastName }}
                                                    </p>

                                                </ng-container>

                                                <!-- Service -->

                                                <ng-container *ngIf="currentOfferCreatorResponsibleUser?.service">

                                                    <p class="read">
                                                        <strong>{{ 'collaborator.service.value' | translate }} : </strong>
                                                        {{ currentOfferCreatorResponsibleUser?.service }}
                                                    </p>

                                                </ng-container>

                                                <!-- Email -->

                                                <ng-container *ngIf="currentOfferCreatorResponsibleUser?.email">

                                                    <p class="read">
                                                        <strong>{{ 'collaborator.email.value' | translate }} : </strong>
                                                        {{ currentOfferCreatorResponsibleUser?.email }}
                                                    </p>

                                                </ng-container>

                                                <!-- Téléphone -->

                                                <ng-container *ngIf="currentOfferCreatorResponsibleUser?.switchboardPhone">

                                                    <p class="read">
                                                        <strong>{{ 'collaborator.phone.value' | translate }} : </strong>
                                                        {{ currentOfferCreatorResponsibleUser?.switchboardPhone }}
                                                    </p>

                                                </ng-container>

                                                <!-- Adresse -->

                                                <ng-container *ngIf="currentOfferCreatorResponsibleUser?.address">

                                                    <p class="read">
                                                        <strong>{{ 'collaborator.address.value' | translate }} : </strong>
                                                        {{ currentOfferCreatorResponsibleUser?.address.address }}, {{ currentOfferCreatorResponsibleUser?.address.zipcode }} {{ currentOfferCreatorResponsibleUser?.address.city }}
                                                    </p>

                                                </ng-container>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </ng-container>

                        </ng-container>

                        <div class="row marges ligne_form">

                            <div class="col_12">

                                <div class="form-group">

                                    <!-- N° de dossier -->

                                    <p class="read">
                                        <strong>{{ 'folder.numero.tywin.value' | translate }} : </strong>
                                        <ng-container *ngIf="tender.reference; else uninformed">
                                            {{ tender.reference }}
                                        </ng-container>
                                    </p>

                                </div>
                            </div>
                        </div>

                        <div class="ligne_form row marges">

                            <div class="col_12">

                                <div class="form-group">

                                    <!-- Date de la demande -->

                                    <p class="read">
                                        <strong>{{ 'request.date.value' | translate }} : </strong>
                                        {{ moment(tender.createdAt).format('dddd D MMMM YYYY') | titlecase }}
                                    </p>

                                </div>
                            </div>
                        </div>

                        <div class="ligne_form row marges">

                            <div class="col_content y_items_center">
                                <div class="form-group">

                                    <!-- Date limite de réponse -->

                                    <p class="read">
                                        <strong>{{ 'response.date.limit.value' | translate }} : </strong>
                                        <span *ngIf="!closedAtUpdateRequested">{{ moment(tender.closedAt).format('dddd D MMMM YYYY') | titlecase }}</span>
                                    </p>
                                </div>

                            </div>

                            <ng-container *ngIf="updateClosedAtAllowed">

                                <div class="col_content y_items_center">

                                    <div class="form-group little_margin">

                                        <div class="form-group" *ngIf="!closedAtUpdateRequested">

                                            <!-- Prolonger la date limite -->

                                            <button class="btn_cta btn_little" type="button" (click)="closedAtUpdateRequested = true">
                                                {{ 'date.limit.extend.action.value'|translate }}
                                            </button>

                                        </div>

                                        <div class="form-group" *ngIf="closedAtUpdateRequested">

                                            <div class="form-group">
                                                <input type="text" class="datetimepicker" matInput readonly [matDatepicker]="closedAtPicker" [min]="minClosedAt" [max]="maxClosedAt" formControlName="closedAt" (click)="closedAtPicker.open()" id="closedAt" placeholder=" ">
                                                <label for="closedAt">{{ 'response.date.limit.value' | translate }}</label>
                                                <mat-datepicker #closedAtPicker></mat-datepicker>
                                                <mat-error *ngIf="form.get('closedAt').errors && (form.get('closedAt').dirty || form.get('closedAt').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="form.get('closedAt').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </ng-container>


                        </div>

                        <!-- Statut demande de création d'offres -->

                        <div class="ligne_form row marges">

                            <div class="col_12">

                                <div class="form-group">
                                    <p class="read">
                                        <strong>{{ 'tender.status.value' | translate }} : </strong>
                                        <ng-container *ngIf="!updateAllowed || !isMine">{{ ('status.' + tender.status + '.value') | translate }}</ng-container>
                                    </p>

                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="updateAllowed && isMine">

                            <div class="ligne_form row marges">

                                <div class="col_6">

                                    <div class="form-group">

                                        <div class="radio">
                                            <input type="radio" [value]="'in-progress'" formControlName="status" class="form-control" id="statusInProgress">
                                            <label for="statusInProgress">{{ 'status.in-progress.value' | translate }}</label>
                                        </div>

                                        <div class="radio">
                                            <input type="radio" [value]="'cancelled'" formControlName="status" class="form-control" id="statusCancelled">
                                            <label for="statusCancelled">{{ 'status.cancelled.value' | translate }}</label>
                                        </div>

                                        <div class="radio">
                                            <input type="radio" [value]="'stopped'" formControlName="status" class="form-control" id="statusStopped">
                                            <label for="statusStopped">{{ 'status.stopped.value' | translate }}</label>
                                        </div>

                                        <div class="radio">
                                            <input type="radio" [value]="'expired'" [checked]="tender.status === 'expired'" [disabled]="true" class="form-control" id="statusExpired">
                                            <label for="statusExpired">{{ 'status.expired.value' | translate }}</label>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div class="row marges ligne_form">
                                <div class="col_12">
                                    <div class="form-group">
                                        <span class="message-help"><mat-icon>info</mat-icon>{{ 'tender.status.help.value' | translate }}</span>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="form_part limited">

            <mat-accordion class="gradient">
                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ (isMine ? 'coordinate.plural.mine.value' : 'transmitter.coordinate.value') | translate }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>

                        <div class="ligne_form row_marges">

                            <div class="col_12">

                                <div class="bloc_recap">

                                    <div class="form-group">

                                        <ng-container *ngIf="userService.hasRole(currentUser, 'ROLE_OFFER_CREATOR') && !isMine">

                                            <!-- Nom de l'émetteur -->

                                            <p class="read">
                                                <strong>{{ 'transmitter.name.value' | translate }} : </strong>
                                                {{ tender.society.name }}
                                            </p>

                                            <ng-container *ngIf="(currentTenderOfferCreator.status === 'reply')">

                                                <!-- Email de l'émetteur -->

                                                <p class="read">
                                                    <strong>{{ 'transmitter.email.value' | translate }} : </strong>
                                                    {{ tender.society.email }}
                                                </p>

                                                <!-- Téléphone de l'émetteur -->

                                                <p class="read">
                                                    <strong>{{ 'transmitter.phone.value' | translate }} : </strong>
                                                    <ng-container *ngIf="tender.society.phone; else uninformed">
                                                        {{ tender.society.phone }}
                                                    </ng-container>
                                                </p>

                                            </ng-container>

                                            <br>

                                        </ng-container>

                                        <ng-container *ngIf="isMine || (userService.hasRole(currentUser, 'ROLE_OFFER_CREATOR') && !isMine && !!currentResponsibleUser) || userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])">

                                            <p class="read">
                                                <strong class="subtitle_green">{{ 'request.collaborator.transmitter' | translate }}</strong>
                                            </p>

                                            <ng-container *ngIf="updateAllowed && isMine">

                                                <div style="margin-top: 12px">
                                                    <app-core-select-search [configuration]="collaboratorSearchConfiguration" [sourceCallback]="collaboratorSearchSourceCallback" [formGroupReference]="form" [formControlNameReference]="'responsibleUser'"></app-core-select-search>
                                                </div>

                                                <mat-error *ngIf="form.get('responsibleUser').invalid && (form.get('responsibleUser').dirty || form.get('responsibleUser').touched)">

                                                    <div class="invalid-feedback">

                                                        <div *ngIf="form.get('responsibleUser').errors.required">{{ 'form.control.error.required.value' | translate }}</div>

                                                    </div>

                                                </mat-error>

                                            </ng-container>

                                        </ng-container>

                                    </div>

                                    <ng-container *ngIf="!!currentResponsibleUser">

                                        <div class="form-group">

                                            <!-- Nom -->

                                            <ng-container *ngIf="currentResponsibleUser?.firstName || currentResponsibleUser?.lastName">

                                                <p class="read">
                                                    <strong>{{ 'collaborator.name.value' | translate }} : </strong>
                                                    {{ currentResponsibleUser?.firstName }} {{ currentResponsibleUser?.lastName }}
                                                </p>

                                            </ng-container>

                                            <!-- Service -->

                                            <ng-container *ngIf="currentResponsibleUser?.service">

                                                <p class="read">
                                                    <strong>{{ 'collaborator.service.value' | translate }} : </strong>
                                                    {{ currentResponsibleUser?.service }}
                                                </p>

                                            </ng-container>

                                            <!-- Email -->

                                            <ng-container *ngIf="(isMine || (userService.hasRole(currentUser, 'ROLE_OFFER_CREATOR') && !isMine && (currentTenderOfferCreator.status === 'reply')) || userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])) && currentResponsibleUser?.email">

                                                <p class="read">
                                                    <strong>{{ 'collaborator.email.value' | translate }} : </strong>
                                                    {{ currentResponsibleUser?.email }}
                                                </p>

                                            </ng-container>

                                            <!-- Téléphone -->

                                            <ng-container *ngIf="(isMine || (userService.hasRole(currentUser, 'ROLE_OFFER_CREATOR') && !isMine && (currentTenderOfferCreator.status === 'reply')) || userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])) && currentResponsibleUser?.switchboardPhone">

                                                <p class="read">
                                                    <strong>{{ 'collaborator.phone.value' | translate }} : </strong>
                                                    {{ currentResponsibleUser?.switchboardPhone }}
                                                </p>

                                            </ng-container>

                                            <!-- Adresse -->

                                            <ng-container *ngIf="(isMine || (userService.hasRole(currentUser, 'ROLE_OFFER_CREATOR') && !isMine && (currentTenderOfferCreator.status === 'reply')) || userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])) && currentResponsibleUser?.address">

                                                <p class="read">
                                                    <strong>{{ 'collaborator.address.value' | translate }} : </strong>
                                                    {{ currentResponsibleUser?.address.address }}, {{ currentResponsibleUser?.address.zipcode }} {{ currentResponsibleUser?.address.city }}
                                                </p>

                                            </ng-container>

                                        </div>

                                    </ng-container>

                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

    </ng-container>

</ng-template>

<ng-template #tenderData>

    <ng-container [formGroup]="form">


        <!-- Créateurs d'offre sélectionnés -->

        <div class="form_part limited">

            <div class="page_title">
                <div class="row marges">
                    <div class="col_content y_items_center">
                        <button class="btn_cta btn_little" type="button" (click)="downloadTenderFile($event)">
                            {{ 'tender.download.value' | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="bloc_recap lightgreen">

            <div class="ck-content">

                <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">
                    <p class="titre_h3 highlight">{{ 'offerCreator.plural.selected.value' | translate}}</p>

                    <ul>
                        <li *ngFor="let offerCreator of tender.offerCreators">{{ offerCreator.offerCreator.name }}</li>
                    </ul>

                    <br>
                </ng-container>

                <p class="titre_h3 highlight">{{ (isMine ? 'purpose.request.mine.value' : 'purpose.request.value') | translate }}</p>

                <p>
                    <ng-container *ngIf="tender.purposeRequest; else uninformed">
                        {{ tender.purposeRequest }}
                    </ng-container>
                </p>
            </div>

        </div>

        </div>

        <div class="form_part limited">
            <mat-accordion class="gradient">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ 'generalData.value' | translate }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <div class="row marges ligne_form">

                            <!-- Cible de clientèle -->

                            <div class="col_12 col_md_6">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'customer.target.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.configuration.customerTypology; else uninformed">
                                                {{ translationService.getFallbackTranslation(tender.configuration.customerTypology.translations).name }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- Type de public -->

                            <div class="col_12 col_md_6">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'public.type.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.configuration.customerTypology; else uninformed">
                                                {{ parsedPublics }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row marges ligne_form">

                            <!-- Nombre de participants -->

                            <div class="col_12" [ngClass]="{ 'col_md_6': tender.composition.publics.includes('child') }">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'participant.plural.count.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.composition.publics.length; else uninformed">
                                                {{ parsedParticipants }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- Age des enfants -->

                            <ng-container *ngIf="tender.composition.publics.includes('child')">

                                <div class="col_12 col_md_6">
                                    <div class="bloc_recap">
                                        <div class="ck-content">
                                            <p class="titre_h4 highlight">{{ 'child.plural.age.value' | translate }}</p>
                                            <p>
                                                <ng-container *ngIf="tender.composition.publics.includes('child'); else uninformed">
                                                    {{ 'children.age.interval.value' | translate: { min: tender.composition.minChildrenAge, max: tender.composition.maxChildrenAge } }}
                                                </ng-container>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>

                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="form_part limited">

            <mat-accordion class="gradient">
                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ 'offer.content.value' | translate }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>

                        <!-- Durée du séjour -->

                        <div class="row marges ligne_form">

                            <div class="col_12">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'stay.duration.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.composition.minDuration && tender.composition.maxDuration; else uninformed">
                                                {{ 'stay.duration.interval.value' | translate:{ min: translationService.getFallbackTranslation(tender.composition.minDuration.translations).label, max: translationService.getFallbackTranslation(tender.composition.maxDuration.translations).label } }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row marges ligne_form">

                            <!-- Destinations -->

                            <div class="col_12 col_md_6">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'destination.plural.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.configuration.regions.length; else allRegions">
                                                {{ parseAttributes(tender.configuration.regions) }}
                                            </ng-container>
                                            <ng-template #allRegions>
                                                {{ 'france.all.value' | translate }}
                                            </ng-template>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- Commentaire destination -->

                            <div class="col_12 col_md_6">

                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'tender.destination.comment.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.configuration.destinationsComment; else uninformed">
                                                {{ tender.configuration.destinationsComment }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <!-- Types de séjour -->

                        <div class="row marges ligne_form">

                            <div class="col_12">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'stay.plural.type.plural.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.configuration.types.length; else uninformed">
                                                {{ parseAttributes(tender.configuration.types) }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- Thématiques -->

                        <div class="row marges ligne_form">

                            <div class="col_12">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'offer.list.filter.themes.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.configuration.themes.length; else uninformed">
                                                {{ parseAttributes(tender.configuration.themes) }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row marges ligne_form">

                            <!-- Hébergements -->

                            <div class="col_12 col_md_6">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'accommodation.type.plural.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.configuration.accommodations.length; else withoutPreference">
                                                {{ parseAttributes(tender.configuration.accommodations) }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- Commentaire hébergement (type de chambre, niveau de confort, situation…) -->

                            <div class="col_12 col_md_6">

                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'tender.accommodation.comment.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.configuration.accommodationsComment; else uninformed">
                                                {{ tender.configuration.accommodationsComment }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="row marges ligne_form">

                            <!-- Restauration -->

                            <div class="col_12 col_md_6">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'restoration.type.plural.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.configuration.restorations.length; else withoutPreference">
                                                {{ parseAttributes(tender.configuration.restorations) }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- Commentaire restauration -->

                            <div class="col_12 col_md_6">

                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'tender.restoration.comment.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.configuration.restorationsComment; else uninformed">
                                                {{ tender.configuration.restorationsComment }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <!-- Activités payantes souhaitées -->

                        <div class="row marges ligne_form">

                            <div class="col_12">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'activity.plural.payable.wish.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.configuration.activities.length; else uninformed">
                                                {{ parseAttributes(tender.configuration.activities) }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- Langues de l'offre -->

                        <div class="row marges ligne_form">

                            <div class="col_12">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'offer.language.plural.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.configuration.locales.length; else uninformed">
                                                {{ parsedLocales }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Commentaire sur le séjour -->

                        <div class="row marges ligne_form">

                            <div class="col_12">

                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'tender.stay.comment.value' | translate }} {{ 'tender.offer.content.comment.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.configuration.contentOfferComment; else uninformed">
                                                {{ tender.configuration.contentOfferComment }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="form_part limited">

            <mat-accordion class="gradient">
                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ 'marketing.param.plural.value' | translate }}</mat-panel-title>
                    </mat-expansion-panel-header>

                    <div>

                        <div class="row marges ligne_form">

                            <!-- Modes de commercialisation -->

                            <div class="col_md_6">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'tender.marketing.mode.plural.value' | translate }}</p>
                                        <p>{{ parsedMarketingModes }}</p>
                                    </div>
                                </div>
                            </div>

                            <!-- Devise -->

                            <div class="col_12 col_md_6">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'currency.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.pricing.currency; else uninformed">
                                                {{ translationService.getFallbackTranslation(tender.pricing.currency.translations).label }} ({{ tender.pricing.currency.symbol }})
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- Commentaire commercialisation -->

                        <div class="row marges ligne_form">

                            <div class="col_12">

                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'tender.marketing.comment.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.pricing.comment; else uninformed">
                                                {{ tender.pricing.comment }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="row marges ligne_form">

                            <!-- Type de tarif -->

                            <div *ngIf="tender.pricing.allowOnlineSale || tender.pricing.allowBooking" class="col_12" [ngClass]="{ 'col_md_4': tender.pricing.allowGiftVoucher, 'col_md_6': !tender.pricing.allowGiftVoucher }">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'price.type.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.pricing.priceType; else uninformed">
                                                {{ ('price.' + tender.pricing.priceType.toLowerCase() + '.value') | translate }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- Tarif -->

                            <div *ngIf="tender.pricing.allowOnlineSale || tender.pricing.allowBooking" class="col_12" [ngClass]="{ 'col_md_4': tender.pricing.allowGiftVoucher, 'col_md_6': !tender.pricing.allowGiftVoucher }">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <ng-container *ngIf="userService.hasRole(currentUser, 'ROLE_OFFER_DISTRIBUTOR')">
                                            <p class="titre_h4 highlight">{{ 'price.plural.net.desired.value' | translate }}</p>
                                        </ng-container>
                                        <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">
                                            <p class="titre_h4 highlight">{{ 'price.plural.public.desired.value' | translate }}</p>
                                        </ng-container>
                                        <ng-container *ngIf="userService.hasRole(currentUser, 'ROLE_OFFER_CREATOR') && !isMine">
                                            <p class="titre_h4 highlight">{{ 'price.value' | translate }}</p>
                                        </ng-container>
                                        <p>
                                            <ng-container *ngIf="tender.pricing.currency; else uninformed">
                                                {{ parsedPrices }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- Tarif bon cadeau -->

                            <div *ngIf="tender.pricing.allowGiftVoucher" class="col_12 col_md_4">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'giftVoucher.price.plural.ttc.desired.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.pricing.currency; else uninformed">
                                                {{ parsedGifVoucherPrices }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'stay.interval.plural.value' | translate }}</p>
                                        <ng-container *ngIf="tender.composition.dates.length; else uninformed">
                                            <p *ngFor="let item of tender.composition.dates">
                                                <ng-container *ngIf="item.dateEnd; else noPeriod">
                                                    {{ 'date.interval.value' | translate:{ dateStart: moment(item.dateStart).format('DD/MM/YYYY'), dateEnd: moment(item.dateEnd).format('DD/MM/YYYY') } }}
                                                </ng-container>
                                                <ng-template #noPeriod>
                                                    {{ moment(item.dateStart).format('DD/MM/YYYY') }}
                                                </ng-template>
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="form_part limited">

            <mat-accordion class="gradient">
                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ 'assurance.value' | translate }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>

                        <div class="row marges ligne_form">

                            <!-- Assurance -->

                            <div class="col_12" [ngClass]="{ 'col_md_6': tender.configuration.hasInsurance }">

                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'tender.insurance.travel.value' | translate }}</p>
                                        <p>{{ (tender.configuration.hasInsurance ? 'yes.value' : 'no.value') | translate }}</p>
                                    </div>
                                </div>

                            </div>

                            <!-- Type assurance -->

                            <ng-container *ngIf="tender.configuration.hasInsurance">

                                <div class="col_12 col_md_6">

                                    <div class="bloc_recap">
                                        <div class="ck-content">
                                            <p class="titre_h4 highlight">{{ 'insurance.type.value' | translate }}</p>
                                            <p>
                                                <ng-container *ngIf="tender.configuration.hasInsurance; else uninformed">
                                                    {{ tender.configuration.insuranceComment }}
                                                </ng-container>
                                            </p>
                                        </div>
                                    </div>

                                </div>

                            </ng-container>

                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="form_part limited">

            <mat-accordion class="gradient">
                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ 'tender.mine.param.plural.value' | translate }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>

                        <div class="row marges ligne_form">

                            <!-- Référence interne -->

                            <div class="col_12">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'reference.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.ownerReference; else uninformed">
                                                {{ tender.ownerReference }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row marges ligne_form">

                            <!-- Date limite de réponse -->

                            <div class="col_12 col_md_6">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'response.date.limit.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.closedAt; else uninformed">
                                                {{ moment(tender.closedAt).format('DD/MM/YYYY') }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- Date de début de commercialisation de l'offre -->

                            <div class="col_12 col_md_6">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'tender.marketing.date.start.value' | translate }}</p>
                                        <p>
                                            <ng-container *ngIf="tender.marketingDateStart; else uninformed">
                                                {{ moment(tender.marketingDateStart).format('DD/MM/YYYY') }}
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row marges ligne_form">

                            <!-- Documents à joindre -->

                            <div class="col_12">
                                <div class="bloc_recap">
                                    <div class="ck-content">
                                        <p class="titre_h4 highlight">{{ 'document.plural.attachment.value' | translate }}</p>
                                        <ng-container *ngIf="tender.documents.length; else uninformed">
                                            <p class="cliquable_file" *ngFor="let document of tender.documents" (click)="downloadDocument(document)">
                                                {{ document.file?.name }}
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row marges ligne_form">

                            <div class="col_12">

                                <div class="bloc_recap">

                                    <div class="row marges ligne_form">
                                        <div class="col_12">

                                            <!-- Commentaire pour le créateur d'offres -->

                                            <div class="ck-content">
                                                <p class="titre_h4 highlight">{{ 'tender.offerCreator.comment.value' | translate }}</p>
                                                <p>
                                                    <ng-container *ngIf="!offerCreatorCommentUpdateRequested">
                                                        <ng-container *ngIf="tender.comment; else uninformed">
                                                            <span *ngIf="!offerCreatorCommentUpdateRequested">{{ tender.comment }}</span>
                                                        </ng-container>
                                                    </ng-container>
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                    <ng-container *ngIf="updateAllowed && isMine">


                                        <div class="row marges ligne_form little_margin">
                                            <div class="col_12">

                                                <div *ngIf="!offerCreatorCommentUpdateRequested">

                                                    <!-- Modification du champ commentaire pour le créateur d'offre -->

                                                    <button class="btn_cta btn_little" type="button" (click)="offerCreatorCommentUpdateRequested = true">
                                                        {{ 'comment.update.action.value'|translate }}
                                                    </button>

                                                </div>

                                                <div *ngIf="offerCreatorCommentUpdateRequested">

                                                    <div class="form-group">
                                                        <textarea placeholder=" " class="form-control" formControlName="comment" id="comment"></textarea>
                                                        <label for="comment">{{ 'tender.offerCreator.comment.value' | translate }}</label>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </ng-container>

                                </div>

                            </div>

                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

    </ng-container>

</ng-template>

<ng-template #offersSelection>

    <ng-container [formGroup]="form">

        <div class="row marges ligne_form" style="margin-bottom: 24px;">
            <div class="col_12">
                <div class="form-group">
                    <span class="message-help"><mat-icon>info</mat-icon>{{ 'tender.offer.selection.description.value' | translate:{ transmitter: tender.society.name } }}</span>
                </div>
            </div>
        </div>

        <div [formGroup]="form" class="tab_content_overflow">

            <div class="row marges">

                <div class="col y_items_center">

                    <div class="selected_offers" *ngIf="offerSearchService.selectedOffers.value.length">

                        <span>{{ 'offer.list.selected.value' | translate }} :</span>

                        <ul>
                            <li *ngFor="let offer of offerSearchService.selectedOffers.value">{{ translationService.getFallbackTranslation(offer.translations).name }}<mat-icon (click)="unselectOffer(offer)">close</mat-icon></li>
                        </ul>

                    </div>
                </div>

                <div class="col_content y_items_center" *ngIf="offerSearchService.selectedOffers.value.length">
                    <button type="button" class="btn_cta btn_little" (click)="resetOfferSelection() ">{{ 'offer.list.selection.reset.action.value' | translate}}</button>
                </div>

            </div>

            <app-core-offer-search
                [user]="currentUser"
                [society]="currentUser.society"
                [mode]="'tender-personnal-offers'"
                [loadItemsSourceCallback]="loadOffersSourceCallback"
            ></app-core-offer-search>

        </div>

    </ng-container>

</ng-template>

<ng-template #offerCreatorsSelection>

    <ng-container [formGroup]="form">

        <div class="tab_content_overflow">

            <!-- Créateurs d'offres sélectionnés -->

            <ng-container *ngIf="offerCreatorSearchService.selectedOfferCreators$.value.length">

                <div class="row marges">

                    <div class="col y_items_center">

                        <div class="selected_offers">

                            <span>{{ 'offerCreator.list.selected.value' | translate }} :</span>

                            <ul>
                                <li *ngFor="let offerCreator of offerCreatorSearchService.selectedOfferCreators$.value">{{ offerCreator.name }}<mat-icon *ngIf="!isTenderAlreadyReceivedByOfferCreator(offerCreator)" (click)="offerCreatorSearchService.unselectOfferCreator(offerCreator)">close</mat-icon></li>
                            </ul>
                        </div>

                    </div>

                </div>

            </ng-container>

            <!-- Liste des créateurs d'offres -->

            <app-core-offer-creator-search *ngIf="isCurrentTabTag('offerCreatorsSelection')"
                [mode]="'tender'"
                [loadItemsSourceCallback]="loadItemsSourceCallback('offerCreatorsSelection')"
            ></app-core-offer-creator-search>

        </div>

    </ng-container>

</ng-template>

<ng-template #offerCreatorsResponse>

    <ng-container [formGroup]="form">

        <div class="tab_content_overflow">

            <!-- Liste des créateurs d'offres -->

            <app-core-offer-creator-search *ngIf="isCurrentTabTag('offerCreatorsResponse')"
                [mode]="'tender'"
                [loadItemsSourceCallback]="loadItemsSourceCallback('offerCreatorsResponse')"
            ></app-core-offer-creator-search>

        </div>

    </ng-container>

</ng-template>

<ng-template #proposedOffers>

    <ng-container [formGroup]="form">

        <div class="tab_content_overflow">

            <!-- Gestion de la vue active -->

            <ng-container [ngSwitch]="true">

                <!-- Moteur de recherche -->

                <ng-container *ngSwitchCase="isCurrentProposalOfferView('search-engine')">
                    <ng-container *ngTemplateOutlet="proposalOfferSearchEngine"></ng-container>
                </ng-container>

                <!-- Visualisation d'une offre -->

                <ng-container *ngSwitchCase="isCurrentProposalOfferView('offer-item')">
                    <ng-container *ngTemplateOutlet="proposalOfferItem"></ng-container>
                </ng-container>

            </ng-container>

        </div>

        <ng-template #proposalOfferSearchEngine>

            <app-core-offer-search
                [user]="currentUser"
                [society]="currentUser.society"
                [mode]="'tender-proposed-offers'"
                [loadItemsSourceCallback]="loadProposedOffersSourceCallback"
            ></app-core-offer-search>

        </ng-template>

        <ng-template #proposalOfferItem>

            <ng-container *ngIf="(currentProposedOffer$ | async) as currentProposedOffer">

                <ng-container *ngIf="currentProposedOffer">

                    <div class="page_offer">

                        <!-- Actions -->

                        <div class="back_to_list page_title">

                            <div class="col_12 y_items_center">

                                <!-- Retour à la liste -->

                                <div class="col_6">
                                    <span class="ty-chevron" (click)="setCurrentProposalOfferView('search-engine')">{{ 'tender.offer.list.back.value' | translate }}</span>
                                </div>

                                <ng-container *ngIf="!offerCatalogService.getSelfItemByOffer(currentProposedOffer)">

                                    <div class="col_6 x_end">

                                        <!-- Ajouter à mon catalogue -->

                                        <button class="btn_cta btn_small btn_add" type="button" (click)="openAddOfferToCatalogDialog(currentProposedOffer)">
                                            {{ 'offer.list.item.action.catalog.add.value' | translate }}
                                        </button>

                                        <!-- Refuser l'offre -->

                                        <button class="btn_cta btn_small btn_lines margin" type="button" (click)="openRefuseOfferDialog(currentProposedOffer)">
                                            {{ 'tender.offer.refused.value' | translate }}
                                        </button>
                                    </div>

                                </ng-container>

                            </div>
                        </div>

                        <!-- Commentaire du créateur d'offres -->

                        <mat-accordion class="gradient" *ngIf="getTenderOfferCreatorOfferFromOffer(currentProposedOffer).comment">
                            <mat-expansion-panel [expanded]="false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>{{ 'tender.comment.fromOfferCreator.value' | translate }}</mat-panel-title>
                                </mat-expansion-panel-header>
                                    <div class="col_12 txt_offer">
                                        <div class="description_offer ck-content">
                                            <p>{{ getTenderOfferCreatorOfferFromOffer(currentProposedOffer).comment }}</p>
                                        </div>
                                    </div>
                            </mat-expansion-panel>
                        </mat-accordion>

                        <!-- Caractéristiques de l'offre -->

                        <mat-accordion class="gradient">
                            <mat-expansion-panel [expanded]="false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>{{ 'tender.offer.item.details.value' | translate }}</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div>
                                    <app-core-offer-item-detail [item]="currentProposedOffer"></app-core-offer-item-detail>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>

                        <!-- Contenu de l'offre -->

                        <mat-accordion class="gradient">
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>{{ 'tender.offer.item.value' | translate }}</mat-panel-title>
                                </mat-expansion-panel-header>

                                <div>
                                    <app-core-offer-item [isMine]="isMine" [roles]= "currentUser.roles" [item]="currentProposedOffer" [configuration]="offerDetailConfiguration">

                                        <!-- Projection de contenu pour la composition -->

                                        <div compositionContent>
                                            <ng-container *ngTemplateOutlet="dateEngineTemplate"></ng-container>
                                        </div>

                                    </app-core-offer-item>
                                </div>

                            </mat-expansion-panel>
                        </mat-accordion>

                    </div>

                </ng-container>

            </ng-container>

        </ng-template>

    </ng-container>

</ng-template>

<ng-template #uninformed>{{ 'uninformed.value' | translate }}</ng-template>

<ng-template #withoutPreference>{{ 'withoutPreference.value' | translate }}</ng-template>

<ng-template #dateEngineTemplate>

    <ng-container *ngIf="(currentProposedOffer$ | async) as currentProposedOffer">

        <ng-container *ngIf="currentProposedOffer">

            <div class="choice_date">

                <!-- Moteur de date -->

                <div class="row marges">

                    <div class="col_12">
                        <app-core-offer-date-engine [childDatesAllowed]="childDatesOffer(currentProposedOffer)" [currentUser]="currentUser" [offer]="currentProposedOffer" [configuration]="offerDateEngineConfiguration" [loadItemsSourceCallback]="loadOfferAvailabilityItemsSourceCallback" [loadFirstAvailabilityItemSourceCallback]="loadOfferFirstAvailabilityItemSourceCallback"></app-core-offer-date-engine>
                    </div>

                </div>

                <div class="row x_end validate_date">

                    <ng-container *ngIf="hasGiftVoucherEnable(currentProposedOffer)">

                        <!-- Bon cadeau -->

                        <button type="button" class="btn_cta btn_lines btn_little" [disabled]="true">
                            {{ 'offer.gift.value' | translate }}
                        </button>

                    </ng-container>

                    <!-- Demande de réservation -->

                    <button type="button" class="btn_cta btn_little btn_booking" [disabled]="true">
                        {{ bookingRequestLabel }}
                    </button>

                    <ng-container *ngIf="currentProposedOffer.isAllowBookingRequest">

                        <!-- Demande de devis -->

                        <button type="button" class="btn_cta btn_little btn_booking" [disabled]="true">
                            {{ quotationRequestLabel }}
                        </button>

                    </ng-container>

                </div>

            </div>

        </ng-container>

    </ng-container>

</ng-template>
