<h1 mat-dialog-title>{{ 'markup.choice.value' | translate }}</h1>
<mat-dialog-content>
    <app-core-offer-access-request-create [configuration]="configuration" [society]="society" [offer]="offer" (create)="create.emit($event)" [createItemSourceCallback]="createItemSourceCallback"></app-core-offer-access-request-create>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="row marges x_center">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="close()">{{ 'cancel.action.value' | translate }}</button>
        </div>
        <div class="col_content">
            <button class="btn_cta btn_little" [disabled]="submitDisabled" (click)="submit()">{{ 'submit.action.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>
