<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'form.user.title.main.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'user.list.back.value' | translate }}
                </button>

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>

    <!-- Choix du rôle -->

    <ng-container *ngTemplateOutlet="selectRole"></ng-container>

    <!-- Gestion des onglets (une fois le rôle choisi) -->

    <mat-tab-group #tabGroup *ngIf="role">

        <ng-container *ngFor="let tabItem of tabItems">

            <mat-tab>
                <ng-template mat-tab-label>
                    <span>{{ tabItem.label | translate }}</span>
                    <span [ngClass]="offerFormTabValidationService.getTabLabelClasses(tabItem.tag)"></span>
                </ng-template>
                <ng-container *ngTemplateOutlet="tabItem.template"></ng-container>
            </mat-tab>

        </ng-container>

    </mat-tab-group>

</form>

<!-- Saisie du rôle -->

<ng-template #selectRole>

    <div class="row marges select_role">
        <div class="col_12 col_md_6">
            <div class="form-group">
                <select class="form-control" id="role" [(ngModel)]="role" [ngModelOptions]="{standalone: true}" required="required" (change)="registrationValidator()">
                    <option value="" disabled selected>{{'form.user.title.roleType.value' | translate}}</option>
                    <option value="ROLE_PROVIDER">{{'role.provider.value' | translate}}</option>
                    <option value="ROLE_OFFER_DISTRIBUTOR">{{'role.offerDistributor.value' | translate}}</option>
                </select>
                <label class="required" for="role">{{'form.user.title.roleType.value' | translate}}</label>
            </div>
        </div>
    </div>

</ng-template>

<!-- Saisie des informations entreprise associées au prestataire -->

<!-- Administrateur du compte -->

<ng-template #societyInformations>

    <ng-container [formGroup]="form">

        <div formGroupName="society">

            <div class="row marges ligne_form">
                <div class="col_12 col_md_6">
                    <!-- Nom société -->
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="name" id="nameSociety" required="required">
                        <label for="nameSociety">{{'form.user.fields.name.value' | translate}}</label>
                        <mat-error
                            *ngIf="societyForm.get('name').errors && (societyForm.get('name').dirty || societyForm.get('name').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
                <div class="col_12 col_md_6">
                    <!-- Site web -->
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="mainWebsite" id="mainWebsite" required="required">
                        <label for="mainWebsite">{{'form.user.fields.website.value' | translate}}</label>
                        <mat-error
                            *ngIf="societyForm.get('mainWebsite').errors && (societyForm.get('mainWebsite').dirty || societyForm.get('mainWebsite').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('mainWebsite').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="societyForm.get('mainWebsite').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">

                <div class="col_12 col_md_6">

                    <!-- Locale -->

                    <div class="form-group">
                        <select class="form-control" id="locale" formControlName="locale" required="required">
                            <option value=""></option>
                            <option *ngFor="let locale of locales$ | async" [value]="locale.id">
                                {{ locale.label | translate }}
                            </option>
                        </select>
                        <label class="required" for="locale">{{ 'form.user.fields.society.locale.value' | translate }}</label>
                        <mat-error *ngIf="societyForm.get('locale').errors && (societyForm.get('locale').dirty || societyForm.get('locale').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('locale').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

                <div class="col_12 col_md_6">

                    <!-- Type -->

                    <div class="form-group">
                        <select class="form-control" id="type" formControlName="type" required="required">
                            <option value=""></option>
                            <option *ngFor="let type of types$ | async" [value]="type.id">
                                {{ type.label | translate }}
                            </option>
                        </select>
                        <label class="required" for="type">{{ 'form.user.fields.society.type.value' | translate }}</label>
                        <mat-error *ngIf="societyForm.get('type').errors && (societyForm.get('type').dirty || societyForm.get('type').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('type').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12 col_md_6">

                    <!-- Raison sociale -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="corporateName" id="corporateName" required="required">
                        <label for="corporateName">{{'form.user.fields.corporateName.value' | translate}}</label>
                        <mat-error
                            *ngIf="societyForm.get('corporateName').errors && (societyForm.get('corporateName').dirty || societyForm.get('corporateName').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('corporateName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

                <div class="col_12 col_md_6">
                    <!-- Immatriculation -->
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="registration" id="immatriculation" [required]="hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])">
                        <label for="immatriculation">{{'form.user.fields.immatriculation.value' | translate}}</label>
                        <mat-error
                                *ngIf="societyForm.get('registration').errors && (societyForm.get('registration').dirty || societyForm.get('registration').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('registration').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                        <span *ngIf="hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])" class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon> {{ 'form.user.fields.immatriculation.help.value' | translate }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12 col_md_6">

                    <!-- Code APE -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="businessActivityCode" id="businessActivityCode" >
                        <label for="businessActivityCode">{{'form.user.fields.businessActivityCode.value' | translate}}</label>
                        <mat-error
                            *ngIf="societyForm.get('businessActivityCode').errors && (societyForm.get('businessActivityCode').dirty || societyForm.get('businessActivityCode').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('businessActivityCode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

                <div class="col_12 col_md_6">

                    <!-- Capital -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="capital" id="capital">
                        <label for="capital">{{'form.user.fields.capital.value' | translate}}</label>
                        <mat-error
                                *ngIf="societyForm.get('capital').errors && (societyForm.get('capital').dirty || societyForm.get('capital').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('capital').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12 col_md_6">
                    <!-- Siren -->
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="identificationNumber" id="siren" required="required">
                        <label for="siren">{{'form.user.fields.siren.value' | translate}}</label>
                        <mat-error
                            *ngIf="societyForm.get('identificationNumber').errors && (societyForm.get('identificationNumber').dirty || societyForm.get('identificationNumber').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('identificationNumber').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

                <div class="col_12 col_md_6">

                    <!-- immatriculation RCS  -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="rcsRegistration" id="rcsRegistration">
                        <label for="rcsRegistration">{{'form.user.fields.rcsRegistration.value' | translate}}</label>
                        <mat-error
                                *ngIf="societyForm.get('rcsRegistration').errors && (societyForm.get('rcsRegistration').dirty || societyForm.get('rcsRegistration').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('rcsRegistration').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form" *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_OFFER_CREATOR'])">
                <div class="col_12 col_md_6">
                    <!-- Garanties financière -->
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="financialGuarantee" id="financialGuarantee" required="required">
                        <label for="financialGuarantee">{{'form.user.fields.financialGuarantee.value' | translate}}</label>
                        <mat-error
                                *ngIf="societyForm.get('financialGuarantee').errors && (societyForm.get('financialGuarantee').dirty || societyForm.get('financialGuarantee').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('financialGuarantee').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon> {{ 'form.user.fields.financialGuarantee.help.value' | translate }}
                        </span>
                    </div>
                </div>

                <div class="col_12 col_md_6">

                    <!-- Assurance responsabilité civile professionnelle -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="insurancePolicy" id="insurancePolicy" required="required">
                        <label for="insurancePolicy">{{'form.user.fields.insurancePolicy.value' | translate}}</label>
                        <mat-error
                                *ngIf="societyForm.get('insurancePolicy').errors && (societyForm.get('insurancePolicy').dirty || societyForm.get('insurancePolicy').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('insurancePolicy').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon> {{ 'form.user.fields.insurancePolicy.help.value' | translate }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12 col_md_6">
                    <!-- Numéro de TVA -->
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="vatNumber" id="vatNumber">
                        <label for="vatNumber" class="required">{{'form.user.fields.vatNumber.value' | translate}}</label>
                        <mat-error
                                *ngIf="societyForm.get('vatNumber').errors && (societyForm.get('vatNumber').dirty || societyForm.get('vatNumber').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('vatNumber').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="societyForm.get('vatNumber').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon>
                            {{ 'form.user.fields.vatNumber.help.value' | translate }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_6">
                    <!-- Logo -->
                    <div class="form-group">
                        <label class="required">{{ 'file.add.action.logo.value' | translate }}</label>
                        <form-field-file [config]="societyLogoConfig" [form]="societyForm"></form-field-file>
                        <mat-error *ngIf="societyForm.get('logo').invalid">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('logo').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
                <div class="col_6">
                    <!-- Logo PIM -->
                    <div class="form-group">
                        <label class="required">{{ 'file.add.action.logo.administration.value' | translate }}</label>
                        <form-field-file [config]="societyAdministrationLogoConfig" [form]="societyForm"></form-field-file>
                        <mat-error *ngIf="societyForm.get('administrationLogo').invalid">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('administrationLogo').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <!-- Adresses -->

            <ng-container formArrayName="addresses">

                <div class="row marges ligne_form" *ngIf="isAddressToDisplay('mailing')">

                    <ng-container *ngTemplateOutlet="formAddress;context:{controlIndex: getAddressControlIndexByType('mailing')}"></ng-container>

                </div>

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" id="differentBillingAddress" [(ngModel)]="differentBillingAddress" [ngModelOptions]="{standalone: true}" (ngModelChange)="handleBillingAddress()">
                                    <label for="differentBillingAddress">{{ 'bill.address.different.question.value' | translate }}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                <div class="row marges ligne_form" *ngIf="isAddressToDisplay('billing')">

                    <ng-container *ngTemplateOutlet="formAddress;context:{controlIndex: getAddressControlIndexByType('billing')}"></ng-container>

                </div>

            </ng-container>


            <div class="row marges ligne_form">
                <div class="col_12 col_md_6">
                    <!-- Email générique (pour les échanges clients) -->
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="email"
                               id="email" required="required">
                        <label for="email">{{'form.user.fields.email.society.value' | translate}}</label>
                        <mat-error
                            *ngIf="societyForm.get('email').errors && (societyForm.get('email').dirty || societyForm.get('email').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="societyForm.get('email').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
                <div class="col_12 col_md_6">
                    <!-- Téléphone générique (pour les échanges clients) -->
                    <div class="form-group">

                        <app-core-intl-tel-input [formGroup]="societyForm" [controlName]="'phone'" customPlaceholder="{{'form.user.fields.phone.value' | translate}}"></app-core-intl-tel-input>

                        <mat-error
                            *ngIf="societyForm.get('phone').errors && (societyForm.get('phone').dirty || societyForm.get('phone').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="societyForm.get('phone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="societyForm.get('phone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <!-- Email de facturation différent -->

            <ng-container >

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" id="differentBillingEmail" [(ngModel)]="differentBillingEmail" [ngModelOptions]="{standalone: true}" (ngModelChange)="societyForm.get('billingEmail').patchValue(differentBillingEmail ? '' : societyForm.get('email').value)">
                                <label for="differentBillingEmail">{{ 'form.user.fields.emailFacturation.title.value' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="differentBillingEmail">

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Email de facturation -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="billingEmail" id="billingEmail" >
                            <label for="billingEmail">{{'form.user.fields.emailFacturation.value' | translate}}</label>
                            <mat-error *ngIf="societyForm.get('billingEmail').errors && (societyForm.get('billingEmail').dirty || societyForm.get('billingEmail').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyForm.get('billingEmail').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="societyForm.get('billingEmail').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

            </ng-container>

            <ng-template #formAddress let-i="controlIndex">

                <ng-container [formGroup]="form">

                    <ng-container formGroupName="society">

                        <ng-container formArrayName="addresses">

                            <ng-container [formGroupName]="indexAsString(i)">

                                <div class="col_12">

                                    <div class="row marges ligne_form">
                                        <div class="col_12">
                                            <div class="form-group">
                                                <label>{{ getAddressTypeLabel(getAddressControl(i).get('type').value) }}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row marges ligne_form">
                                        <div class="col_6 y_items_center">
                                            <app-core-google-places-location [fields]="getAddressLocationFields(getAddressControl(i))" (onSelection)="selectAddress(i)"></app-core-google-places-location>
                                        </div>
                                    </div>

                                    <div class="row marges ligne_form">
                                        <div class="col_12 col_md_6">

                                            <!-- Adresse -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="address" required="required">
                                                <label for="address">{{'form.user.fields.address.value' | translate}}</label>
                                                <mat-error
                                                        *ngIf="getAddressControl(i).get('address').errors && (getAddressControl(i).get('address').dirty || getAddressControl(i).get('address').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="getAddressControl(i).get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div class="col_12 col_md_6">

                                            <!-- Complément d'adresse -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalAddress" id="additionalAddress">
                                                <label for="additionalAddress">{{'form.user.fields.additionalAddress.value' | translate}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row marges ligne_form">
                                        <div class="col_12 col_md_6">

                                            <!-- Code postal -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="zipcode" required="required">
                                                <label for="zipcode">{{'form.user.fields.zipcode.value' | translate}}</label>
                                                <mat-error
                                                        *ngIf="getAddressControl(i).get('zipcode').errors && (getAddressControl(i).get('zipcode').dirty || getAddressControl(i).get('zipcode').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="getAddressControl(i).get('zipcode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        <div *ngIf="getAddressControl(i).get('zipcode').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col_12 col_md_6">

                                            <!-- Ville -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="city" required="required">
                                                <label for="city">{{'form.user.fields.city.value' | translate}}</label>
                                                <mat-error
                                                        *ngIf="getAddressControl(i).get('city').errors && (getAddressControl(i).get('city').dirty || getAddressControl(i).get('city').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="getAddressControl(i).get('city').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row marges ligne_form">
                                        <div class="col_12 col_md_6">

                                            <!-- Région -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="region" id="region" required="required">
                                                <label for="region">{{'form.user.fields.region.value' | translate}}</label>
                                                <mat-error
                                                        *ngIf="getAddressControl(i).get('region').errors && (getAddressControl(i).get('region').dirty || getAddressControl(i).get('region').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="getAddressControl(i).get('region').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div class="col_12 col_md_6">

                                            <!-- Pays -->

                                            <div class="form-group">
                                                <select class="form-control" id="country" formControlName="country" required="required">
                                                    <option *ngFor="let country of countries$ | async" [value]="country.code">{{ translationService.getFallbackTranslation(country.translations).name }}</option>
                                                </select>
                                                <label class="required"
                                                       for="country">{{ 'form.user.fields.country.value' | translate }}</label>
                                                <mat-error
                                                        *ngIf="getAddressControl(i).get('country').invalid && (getAddressControl(i).get('country').dirty || getAddressControl(i).get('country').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="getAddressControl(i).get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </ng-container>

                        </ng-container>

                    </ng-container>

                </ng-container>

            </ng-template>

            <!-- Documents CGV -->
            <ng-container *ngIf="hasOneOfThisRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])">
                <ng-container *ngTemplateOutlet="formTermsAndConditions"></ng-container>
            </ng-container>
        </div>

    </ng-container>

</ng-template>

<!-- Saisie des informations liées au contact principal -->

<ng-template #personnalInformations>

    <ng-container [formGroup]="form">

        <div formGroupName="society">

            <div formGroupName="admin">

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <!-- Civilité -->
                        <div class="form-group">
                            <label class="required">{{'form.user.fields.civility.title.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" value="1" formControlName="civility" class="form-control" id="civility-mister">
                                <label for="civility-mister">{{'form.user.fields.civility.mister.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" value="2" formControlName="civility" class="form-control" id="civility-madam">
                                <label for="civility-madam">{{'form.user.fields.civility.madam.value' | translate}}</label>
                            </div>

                            <mat-error
                                    *ngIf="adminForm.get('civility').errors && (adminForm.get('civility').dirty || adminForm.get('civility').touched)">
                                <div class="invalid-feedback">
                                    <div
                                            *ngIf="adminForm.get('civility').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row marges ligne_form">
                    <div class="col_12 col_md_4">
                        <!-- Nom -->
                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="lastName" id="lastName" required="required">
                            <label for="lastName">{{'form.user.fields.lastname.value' | translate}}</label>
                            <mat-error
                                *ngIf="adminForm.get('lastName').errors && (adminForm.get('lastName').dirty || adminForm.get('lastName').touched)">
                                <div class="invalid-feedback">
                                    <div
                                        *ngIf="adminForm.get('lastName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_4">
                        <!-- Prénom -->
                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="firstName" id="firstName" required="required">
                            <label for="firstName">{{'form.user.fields.firstname.value' | translate}}</label>
                            <mat-error
                                *ngIf="adminForm.get('firstName').errors && (adminForm.get('firstName').dirty || adminForm.get('firstName').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('firstName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_4">
                        <!-- Email -->
                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="email" id="emailContact" required="required">
                            <label for="emailContact">{{'form.user.fields.email.contact.value' | translate}}</label>
                            <mat-error
                                *ngIf="adminForm.get('email').errors && (adminForm.get('email').dirty || adminForm.get('email').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="adminForm.get('email').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row marges ligne_form">
                    <div class="col_12 col_md_4">
                        <!-- Téléphone fixe -->
                        <div class="form-group">

                            <app-core-intl-tel-input [formGroup]="adminForm" [controlName]="'switchboardPhone'" customPlaceholder="{{'form.user.fields.switchboardPhone.value' | translate}}" [required]="true"></app-core-intl-tel-input>

                            <mat-error *ngIf="adminForm.get('switchboardPhone').errors && (adminForm.get('switchboardPhone').dirty || adminForm.get('switchboardPhone').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('switchboardPhone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="adminForm.get('switchboardPhone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_4">
                        <!-- Ligne directe -->
                        <div class="form-group">

                            <app-core-intl-tel-input [formGroup]="adminForm" [controlName]="'directPhone'" customPlaceholder="{{'form.user.fields.directPhone.value' | translate}}"></app-core-intl-tel-input>

                            <mat-error *ngIf="adminForm.get('directPhone').errors && (adminForm.get('directPhone').dirty || adminForm.get('directPhone').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('directPhone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="adminForm.get('directPhone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_4">
                        <!-- Portable -->
                        <div class="form-group">

                            <app-core-intl-tel-input [formGroup]="adminForm" [controlName]="'cellphone'" customPlaceholder="{{'form.user.fields.cellphone.value' | translate}}"></app-core-intl-tel-input>

                            <mat-error *ngIf="adminForm.get('cellphone').errors && (adminForm.get('cellphone').dirty || adminForm.get('cellphone').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('cellphone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="adminForm.get('cellphone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row marges ligne_form">
                    <div class="col_12 col_md_4">
                        <div class="form-group">
                            <app-core-timezone-select [form]="adminForm" [required]="true"></app-core-timezone-select>
                        </div>
                    </div>
                    <div class="col_12 col_md_4">
                        <!-- Service -->
                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="service" id="service">
                            <label for="service">{{'form.user.fields.service.value' | translate}}</label>
                            <mat-error *ngIf="adminForm.get('service').errors &&(adminForm.get('service').dirty || adminForm.get('service').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('service').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_4">
                        <!-- Locale -->

                        <div class="form-group">
                            <select class="form-control" id="adminLocale" formControlName="locale" required="required">
                                <option value=""></option>
                                <option *ngFor="let locale of locales$ | async" [value]="locale.id">
                                    {{ locale.label | translate }}
                                </option>
                            </select>
                            <label class="required" for="adminLocale">{{ 'form.user.fields.locale.value' | translate }}</label>
                            <mat-error *ngIf="adminForm.get('locale').errors && (adminForm.get('locale').dirty || adminForm.get('locale').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('locale').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <!-- Commentaire -->
                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="comment" id="comment">
                            <label for="comment">{{'form.user.fields.comment.value' | translate}}</label>
                            <mat-error
                                *ngIf="adminForm.get('comment').errors &&(adminForm.get('comment').dirty || adminForm.get('comment').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="adminForm.get('comment').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </ng-container>

</ng-template>

<ng-template #formTermsAndConditions>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="form-group">
                <label>{{ 'form.user.fields.cgv.title.value' | translate }}</label>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <app-terms-and-conditions [parentTermsAndConditions]="form"></app-terms-and-conditions>
        </div>
    </div>

</ng-template>

<!-- Représentant légal -->

<ng-template #legalRepresentative>

    <ng-container [formGroup]="form">

        <ng-container formGroupName="society">

            <div formGroupName="legalRepresentative">

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <!-- Civilité -->
                        <div class="form-group">
                            <label class="required">{{'form.user.fields.civility.title.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" value="1" formControlName="civility" class="form-control" id="legalRepresentative-civility-mister">
                                <label for="legalRepresentative-civility-mister">{{'form.user.fields.civility.mister.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" value="2" formControlName="civility" class="form-control" id="legalRepresentative-civility-madam">
                                <label for="legalRepresentative-civility-madam">{{'form.user.fields.civility.madam.value' | translate}}</label>
                            </div>

                            <mat-error
                                    *ngIf="societyLegalRepresentativeControl.get('civility').errors && (societyLegalRepresentativeControl.get('civility').dirty || societyLegalRepresentativeControl.get('civility').touched)">
                                <div class="invalid-feedback">
                                    <div
                                            *ngIf="societyLegalRepresentativeControl.get('civility').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_4">

                        <!-- Nom -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="lastName" id="legalRepresentativeLastName" required="required">
                            <label for="legalRepresentativeLastName">{{'form.user.fields.lastname.value' | translate}}</label>
                            <mat-error *ngIf="societyLegalRepresentativeControl.get('lastName').errors && (societyLegalRepresentativeControl.get('lastName').dirty || societyLegalRepresentativeControl.get('lastName').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('lastName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_4">

                        <!-- Prénom -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="firstName" id="legalRepresentativeFirstName" required="required">
                            <label for="legalRepresentativeFirstName">{{'form.user.fields.firstname.value' | translate}}</label>
                            <mat-error *ngIf="societyLegalRepresentativeControl.get('firstName').errors && (societyLegalRepresentativeControl.get('firstName').dirty || societyLegalRepresentativeControl.get('firstName').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('firstName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_4">
                        <!-- Téléphone -->
                        <div class="form-group">

                            <app-core-intl-tel-input [formGroup]="societyLegalRepresentativeControl" [controlName]="'phone'" customPlaceholder="{{'form.user.fields.legalRepresentative.phone.value' | translate}}"></app-core-intl-tel-input>

                            <mat-error
                                    *ngIf="societyLegalRepresentativeControl.get('phone').errors && (societyLegalRepresentativeControl.get('phone').dirty || societyLegalRepresentativeControl.get('phone').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('phone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">

                    <div class="col_12 col_md_4">

                        <!-- Nationalité -->

                        <div class="form-group">
                            <select  class="form-control" id="legalRepresentativeNationality" formControlName="nationality" required="required">
                                <option *ngFor="let country of countries$ | async" [value]="country.code">
                                    {{ translationService.getFallbackTranslation(country.translations).name }}
                                </option>
                            </select>
                            <label class="required" for="legalRepresentativeNationality">{{ 'form.user.fields.nationality.value' | translate }}</label>
                            <mat-error
                                *ngIf="societyLegalRepresentativeControl.get('nationality').invalid && (societyLegalRepresentativeControl.get('nationality').dirty || societyLegalRepresentativeControl.get('nationality').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('nationality').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_4">

                        <!-- Date de naissance -->

                        <app-input-date [form]="societyLegalRepresentativeControl" [config]="birthDayConfig"></app-input-date>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_6 y_items_center">
                        <app-core-google-places-location [fields]="getAddressLocationFields(societyLegalRepresentativeControl)"></app-core-google-places-location>
                    </div>

                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Adresse -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="legalRepresentativeAddress" required="required">
                            <label for="legalRepresentativeAddress">{{'form.user.fields.address.value' | translate}}</label>
                            <mat-error
                                *ngIf="societyLegalRepresentativeControl.get('address').errors && (societyLegalRepresentativeControl.get('address').dirty || societyLegalRepresentativeControl.get('address').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Complément d'adresse -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalAddress" id="legalRepresentativeAdditionalAddress">
                            <label for="legalRepresentativeAdditionalAddress">{{'form.user.fields.additionalAddress.value' | translate}}</label>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Code postal -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="legalRepresentativeZipcode" required="required">
                            <label for="legalRepresentativeZipcode">{{'form.user.fields.zipcode.value' | translate}}</label>
                            <mat-error
                                *ngIf="societyLegalRepresentativeControl.get('zipcode').errors && (societyLegalRepresentativeControl.get('zipcode').dirty || societyLegalRepresentativeControl.get('zipcode').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('zipcode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="societyLegalRepresentativeControl.get('zipcode').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_12 col_md_6">

                        <!-- Ville -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="legalRepresentativeCity" required="required">
                            <label for="legalRepresentativeCity">{{'form.user.fields.city.value' | translate}}</label>
                            <mat-error
                                *ngIf="societyLegalRepresentativeControl.get('city').errors && (societyLegalRepresentativeControl.get('city').dirty || societyLegalRepresentativeControl.get('city').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('city').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">

                    <div class="col_12 col_md_6">

                        <!-- Région -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="region" id="legalRepresentativeRegion" required="required">
                            <label for="legalRepresentativeRegion">{{'form.user.fields.region.value' | translate}}</label>
                            <mat-error
                                    *ngIf="societyLegalRepresentativeControl.get('region').errors && (societyLegalRepresentativeControl.get('region').dirty || societyLegalRepresentativeControl.get('region').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('region').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Pays -->

                        <div class="form-group">
                            <select  class="form-control" id="legalRepresentativeCountry" formControlName="country" required="required">
                                <option *ngFor="let country of countries$ | async" [value]="country.code">
                                    {{ translationService.getFallbackTranslation(country.translations).name }}
                                </option>
                            </select>
                            <label class="required" for="legalRepresentativeCountry">{{ 'form.user.fields.country.value' | translate }}</label>
                            <mat-error
                                *ngIf="societyLegalRepresentativeControl.get('country').invalid && (societyLegalRepresentativeControl.get('country').dirty || societyLegalRepresentativeControl.get('country').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="societyLegalRepresentativeControl.get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </ng-container>

</ng-template>

