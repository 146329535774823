import {Component, OnInit} from '@angular/core';
import {OfferCreatorSearchService} from "@core/shared/services/offer-creator/offer-creator-search.service";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {TranslateService} from "@ngx-translate/core";
import {Role} from "@core/shared/models/role";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {Router} from "@angular/router";
import {Access} from "@core/shared/models/access";
import {OfferCreatorFilterKey} from "@core/components/offer-creator/offer-creator-filter/offer-creator-filter.component";
import {Observable} from "rxjs";
import {Pagination} from "@core/shared/models/pagination";
import {Society} from "@core/shared/models/society";
import {OfferCreatorService} from "@core/shared/services/offer-creator.service";
import {OfferCreatorCardService} from "@core/shared/services/offer-creator/offer-creator-card.service";

@Component({
    selector: 'app-core-page-offer-creator-list',
    templateUrl: './page-offer-creator-list.component.html',
    styleUrls: ['./page-offer-creator-list.component.scss'],
    providers: [
        OfferCreatorSearchService,
        OfferCreatorCardService
    ]
})
export class PageOfferCreatorListComponent implements OnInit {

    public currentUser: User = null;

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _offerCreatorService: OfferCreatorService,
        private _offerCreatorSearchService: OfferCreatorSearchService,
        public userService: UserService,
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.value;

        this._configureSearchService();
    }

    private _configureSearchService(): void {

        this._offerCreatorSearchService.sessionEnabled = true;

        Object.assign(this._offerCreatorSearchService.enabledFilters, {
            [OfferCreatorFilterKey.Destination]: true,
            [OfferCreatorFilterKey.Target]: true,
            [OfferCreatorFilterKey.NetworkOfferCreator]: true,
            [OfferCreatorFilterKey.OfferType]: true,
            [OfferCreatorFilterKey.Name]: true,
            [OfferCreatorFilterKey.InCatalog]: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']),
            [OfferCreatorFilterKey.HasRseLabel]: true,
            [OfferCreatorFilterKey.HasSubscription]: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])
        } as { [p in OfferCreatorFilterKey]: boolean });
    };

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return this.userService.hasOneOfThisRoles(this.currentUser, roles);
    }

    public openCreateQuotationDialog(): void {

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: this._translateService.instant('quotation.value'),
                content: `<p>${this._translateService.instant('quotation.question.description.value')}</p>`
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._router.navigate(['account/quotation/create'], { queryParams: { origin: 'offerCreatorList' } });
        });
    }

    public openCreateTenderDialog(): void {

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: this._translateService.instant('tender.question.title.value'),
                content: `<p>${this._translateService.instant('tender.question.description.value')}</p>`
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._router.navigate(['account/tender/create'], { queryParams: { origin: 'offerCreatorList' } });
        });
    }

    get isQuotationCreationAllowed(): boolean {

        const conditions: boolean[] = [
            //this.hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']),
            this.hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR']),
            this.currentUser.accesses.some((access: Access): boolean => {

                return access.tag === 'OFFER_CREATOR_LIST';
            }),
            this.currentUser.accesses.some((access: Access): boolean => {

                return access.tag === 'QUOTATION_CREATE';
            })
        ];

        return conditions.every((value: boolean) => {

            return value === true;
        });
    }

    get isTenderCreationAllowed(): boolean {

        return this.userService.hasAllOfTheseAccesses(this.currentUser, ['OFFER_CREATOR_LIST', 'TENDER_CREATE']);
    }

    get totalItems(): number {

        return this._offerCreatorSearchService.totalOfferCreators;
    }

    get loadItemsSourceCallback(): (params?: string[]) => Observable<Pagination<Society>> {

        return (params?: string[]): Observable<Pagination<Society>> => {

            return this._offerCreatorService.getPaginationItemsAPI(params);
        }
    }
}
