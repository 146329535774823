<div>
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title" *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_FEDERATION', 'ROLE_FEDERATION'])">{{ 'offerCreator.list.management.value' | translate }}</h1>
                <h1 class="title" *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">{{ 'offerCreator.list.admin.management.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">

                <ng-container *ngIf="isQuotationCreationAllowed">

                    <!-- Demande de devis -->

                    <button (click)="openCreateQuotationDialog()" class="btn_cta btn_little btn_add">
                        {{ 'quotation.create' | translate }}
                    </button>

                </ng-container>

                <ng-container *ngIf="isTenderCreationAllowed">

                    <!-- Demande de création d’offres -->

                    <!--<button (click)="openCreateTenderDialog()" class="btn_cta btn_little btn_add">
                        {{ 'tender.value' | translate }}
                    </button>-->

                </ng-container>

            </div>
        </div>
    </div>

    <app-core-offer-creator-search
        [mode]="'catalog'"
        [loadItemsSourceCallback]="loadItemsSourceCallback"
    ></app-core-offer-creator-search>

</div>
