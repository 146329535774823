<div>
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'tender.plural.value' | translate }}</h1>
            </div>
            <div *ngIf="hasAccess('TENDER_LIST')" class="col_content y_items_center">
                <button (click)="exportTenders()" class="btn_cta btn_little">{{ 'tender.list.export.value' | translate }}</button>
            </div>
            <div *ngIf="userService.hasAllOfTheseAccesses(currentUser, ['OFFER_CREATOR_LIST', 'TENDER_CREATE'])" class="col_content y_items_center">
                <!-- Demande de création d’offres -->
                <button (click)="openCreateTenderDialog()" class="btn_cta btn_little btn_add">
                    {{ 'tender.value' | translate }}
                </button>
            </div>
        </div>
        <ng-container *ngIf="currentUser.society?.countAllowedPublishedOffers === 0">
            <div class="subscription_information">
                <p [innerHTML]="'tender.no_licence.description.value' | translate"></p>
            </div>
        </ng-container>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Nom de l'émetteur -->

                <ng-container [matColumnDef]="getFilterColumnDef('society')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'transmitter.name.value' | translate" [key]="'society.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom du collaborateur émetteur -->

                <ng-container [matColumnDef]="getFilterColumnDef('responsibleUserLastName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'collaborator.transmitter.lastName.value' | translate" [key]="'responsibleUser.lastName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Prénom du collaborateur émetteur -->

                <ng-container [matColumnDef]="getFilterColumnDef('responsibleUserFirstName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'collaborator.transmitter.firstName.value' | translate" [key]="'responsibleUser.firstName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Date de la demande -->

                <ng-container [matColumnDef]="getFilterColumnDef('createdAt')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'createdAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <!-- Référence -->

                <ng-container [matColumnDef]="getFilterColumnDef('ownerReference')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'reference.value' | translate" [key]="'ownerReference'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Numéro de dossier ty-Win -->

                <ng-container [matColumnDef]="getFilterColumnDef('reference')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'folder.numero.tywin.value' | translate" [key]="'reference'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container *ngIf="userService.hasRole(currentUser, 'ROLE_OFFER_CREATOR')">

                    <!-- Statut créateur d'offres -->

                    <ng-container [matColumnDef]="getFilterColumnDef('offerCreatorStatus')">
                        <th mat-header-cell *matHeaderCellDef>
                            <app-core-select-filter #filter [builder]="filterBuilder" [label]="'offerCreator.status.value' | translate" [key]="'offerCreators.status'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="offerCreatorStatus"></app-core-select-filter>
                        </th>
                    </ng-container>

                </ng-container>

                <!-- Statut -->

                <ng-container [matColumnDef]="getFilterColumnDef('status')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'tender.status.value' | translate" [key]="'status'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="status"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Date limite de réponse -->

                <ng-container [matColumnDef]="getFilterColumnDef('closedAt')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'closedAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <!-- Cible de clientèe -->

                <ng-container [matColumnDef]="getFilterColumnDef('customerTypology')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'customer.target.value' | translate" [key]="'configuration.customerTypology.id'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="customerTypologies"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Destinations -->

                <ng-container [matColumnDef]="getFilterColumnDef('regions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'destination.value' | translate" [key]="'configuration.regions.translations.label'" [operator]="'lk'" [itemValueProperty]="'label'" [itemLabelProperty]="'label'" [items]="regionFilterChoices"></app-core-select-filter>
                    </th>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])">

                    <!-- Nombre de créateurs d'offres sélectionnés -->

                    <ng-container [matColumnDef]="getFilterColumnDef('countOfferCreators')">
                        <th mat-header-cell *matHeaderCellDef></th>
                    </ng-container>

                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION', 'ROLE_OFFER_CREATOR'])">

                    <!-- Nombre d'offres soumises -->

                    <ng-container [matColumnDef]="getFilterColumnDef('countOffers')">
                        <th mat-header-cell *matHeaderCellDef></th>
                    </ng-container>

                </ng-container>

            </ng-container>

            <!-- Liste des données -->

            <ng-container>

                <!--Actions-->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">

                        <button mat-button *ngIf="['read', 'write'].includes(getModel(element).hasAccess)" (click)="view(element.id)">
                            <mat-icon>{{ getModel(element).hasAccess === 'write' ? 'edit' : 'visibility' }}</mat-icon>
                        </button>

                        <button mat-button (click)="openDeleteItemDialog(element.id)" *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Nom de l'émetteur -->

                <ng-container matColumnDef="society">
                    <th mat-header-cell *matHeaderCellDef>{{ 'transmitter.name.value' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).society.name }}</td>
                </ng-container>

                <!-- Nom du collaborateur émetteur  -->

                <ng-container matColumnDef="responsibleUserLastName">
                    <th mat-header-cell *matHeaderCellDef>{{ 'collaborator.transmitter.lastName.value' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).responsibleUser.lastName }}</td>
                </ng-container>

                <!-- Prénom du collaborateur émetteur  -->

                <ng-container matColumnDef="responsibleUserFirstName">
                    <th mat-header-cell *matHeaderCellDef>{{ 'collaborator.transmitter.firstName.value' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).responsibleUser.firstName }}</td>
                </ng-container>

                <!-- Date de la demande -->

                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef>{{ 'request.date.value' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).createdAt | date : 'dd/MM/yyyy' : null : localeId }}</td>
                </ng-container>

                <!-- Référence -->

                <ng-container matColumnDef="ownerReference">
                    <th mat-header-cell *matHeaderCellDef>{{ 'reference.value' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).ownerReference }}</td>
                </ng-container>

                <!-- Numéro de dossier Ty-Win -->

                <ng-container matColumnDef="reference">
                    <th mat-header-cell *matHeaderCellDef>{{ 'folder.numero.tywin.value' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).reference }}</td>
                </ng-container>

                <ng-container *ngIf="userService.hasRole(currentUser, 'ROLE_OFFER_CREATOR')">

                    <!-- Statut créateur d'offres -->

                    <ng-container matColumnDef="offerCreatorStatus">
                        <th mat-header-cell *matHeaderCellDef>{{'offerCreator.status.value' | translate}}</th>
                        <td mat-cell *matCellDef="let element">{{ !isMine(element) ? ('status.' + getItemOfferCreator(element).status + '.value' | translate) : '' }}</td>
                    </ng-container>

                </ng-container>

                <!-- Statut -->

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{'tender.status.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ ('status.' + getModel(element).status + '.value' | translate) }}</td>
                </ng-container>

                <!-- Date limite de réponse -->

                <ng-container matColumnDef="closedAt">
                    <th mat-header-cell *matHeaderCellDef>{{'response.date.limit.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).closedAt | date : 'dd/MM/yyyy' : null : localeId }}</td>
                </ng-container>

                <!-- Cible de clientèle -->

                <ng-container matColumnDef="customerTypology">
                    <th mat-header-cell *matHeaderCellDef>{{'customer.target.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ translationService.getFallbackTranslation(getModel(element).configuration.customerTypology.translations).name }}</td>
                </ng-container>

                <!-- Destinations -->

                <ng-container matColumnDef="regions">
                    <th mat-header-cell *matHeaderCellDef>{{'destination.plural.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ parsedItemRegions(getModel(element)) }}</td>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])">

                    <!-- Nombre de créateurs d'offres sélectionnés -->

                    <ng-container matColumnDef="countOfferCreators">
                        <th mat-header-cell *matHeaderCellDef>{{'tender.countOfferCreators.value' | translate}}</th>
                        <td mat-cell *matCellDef="let element">{{ userService.hasOneOfThisRoles(currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) || isMine(getModel(element))  ? getModel(element).countOfferCreators : '' }}</td>
                    </ng-container>

                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION', 'ROLE_OFFER_CREATOR'])">

                    <!-- Nombre d'offres soumises -->

                    <ng-container matColumnDef="countOffers">
                        <th mat-header-cell *matHeaderCellDef>{{'tender.countOffers.value' | translate}}</th>
                        <td mat-cell *matCellDef="let element">{{ isMine(getModel(element)) ? getModel(element).countOffers : (userService.hasRole(currentUser, 'ROLE_OFFER_CREATOR') ? getItemOfferCreator(element).countOffers : '') }}</td>
                    </ng-container>

                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>

</div>
